import React, { useEffect, useState, useRef } from "react";
import "./VisitStyle.css";
import TableTest from "./ListTest";
import DefrentTest from "./DefrentTest";
import { useDispatch, useSelector } from "react-redux";
import {
  addInvoice,
  addPatient,
  getPatients,
  searchForPatients,
} from "../../../store/reducer/PatientReducer";
import AsyncSelect from "react-select/async";
import Select from "react-select";

import {
  EDIT_DISCOUNT,
  EDIT_DISCOUNT_PRECENTAGE,
  SELECTED_PATIENT,
} from "../../../store/actions/action_types";
import { getDoctors } from "../../../store/reducer/doctorsReducer";
import AddTestRow from "./AddTestRow";
import { updatePatinet } from "../../../store/reducer/invoicesReducer";

function CreateVisit() {
  let options = Array();
  const { patients, listOfTest, totalPrice, finalPrice } = useSelector(
    (state) => state.patientReducer
  );

  const dispatch = useDispatch();
  const customStyles = {
    option: (provided) => ({
      ...provided,
      color: "black",
    }),
    control: (provided) => ({
      ...provided,
      color: "black",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "black",
    }),
  };
  // useEffect(() => {
  //   if (!patients || patients == "" || patients.length == 0) {
  //     getPatients();
  //   }
  // }, []);
  let [discount, setDIscount] = useState("");
  let [discountPrecentage, setDIscountPrecentage] = useState("");
  let [fullname, setName] = useState("");

  let [phone, setPhone] = useState("");
  let [dateOfBirth, setBirth] = useState("");
  let [gender, setGender] = useState("g");
  let [patientId, setPatinetId] = useState("");
  let [age, setAge] = useState("");

  function getAge(dateString) {
    var nowDate = new Date(new Date().setHours(0, 0, 0, 0));
    // Example date of birth.
    var dobDate = new Date(dateString);

    var years = nowDate.getFullYear() - dobDate.getFullYear();
    var months = nowDate.getMonth() - dobDate.getMonth();
    var days = nowDate.getDate() - dobDate.getDate();

    // Work out the difference in months.
    months += years * 12;
    if (days < 0) {
      months -= 1;
    }
    // Now add those months to the date of birth.
    dobDate.setMonth(dobDate.getMonth() + months);
    // Calculate the difference in milliseconds.
    let diff = nowDate - dobDate;
    // Divide that by 86400 to get the number of days.
    var days = Math.round(diff / 86400 / 1000);
    // Now convert months back to years and months.
    years = parseInt(months / 12);
    months -= years * 12;

    let text = "";
    if (years) {
      text = years + (years > 1 ? " years" : " year");
    }
    if (months) {
      if (text.length) {
        text = text + ", ";
      }
      text = text + months + (months > 1 ? " months" : " month");
    }
    if (days) {
      if (text.length) {
        text = text + ", ";
      }
      text = text + days + (days > 1 ? " days" : " day");
    }
    if (nowDate === dobDate) {
      text = "Newborn";
    }
    if (
      text === "" ||
      text === null ||
      text === undefined ||
      days < 0 ||
      months < 0 ||
      years < 0
    ) {
      text = "age can not be calculated";
    }

    setAge(text);
  }

  let [fullnameError, setNameError] = useState("");

  let [phoneError, setPhoneError] = useState("");
  let [dateOfBirthError, setBirthError] = useState("");
  let [genderError, setGenderError] = useState("");
  let [doctorError, setDoctorError] = useState("");
  let today = new Date();
  let dd = String(today.getDate()).padStart(2, "0");
  let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  let yyyy = today.getFullYear();

  today = yyyy + "-" + mm + "-" + dd;
  //
  const setTodefualt = () => {
    setAge("");
    setName("");
    setPhone("");
    setBirth("");
    setDoctor("");
    setGender("g");
    setDoctorError("");
    setPatinetId(null);
    setNameError("");
    setPhoneError("");
    setBirthError("");
    setGenderError("");
  };

  const handlePatinetChange = (e) => {
    if (e === undefined || e === null) {
      setAge("");
      setName("");
      setPhone("");
      setBirth("");
      setGender("g");
      setDoctorError("");
      setPatinetId(undefined);
      return;
    }
    let patient = e.value;
    //
    dispatch({ type: SELECTED_PATIENT, payload: patient });
    setPatinetId(e);
    setNameError("");
    setPhone(patient.phone);
    setBirth(patient.birthdate);
    setGender(patient.gender);
    // set
    getAge(patient.birthdate);
    setName(patient.fullname);
    setPhoneError("");
    setBirthError("");
    setGenderError("");
  };
  options = [];
  patients?.map((d, index) =>
    options.push({
      key: index,
      value: d,
      label: d.fullname + " " + d.phone,
    })
  );

  const { doctors } = useSelector((state) => state.doctorsReducer);

  useEffect(() => {
    if (!doctors || doctors == "" || doctors.length == 0) {
      getDoctors();
    }
  }, []);
  let doctorsList = Array();
  doctors.map((d) => {
    doctorsList.push({
      value: d.id,
      label: d.name,
      key: Math.floor(Math.random() * 400),
    });
  });
  const [doctorId, setDoctor] = useState();
  const [disable, setDisable] = useState(false);
  const handleSubmit = async (e) => {
    e.preventDefault();

    //
    let testsList = Array();
    let resultsList = Array();
    listOfTest.forEach((e) => {
      if (e.test != undefined) {
        if (e.result === "" || e.result === undefined) {
          resultsList.push(0);
        } else {
          resultsList.push(e.result);
        }
        testsList.push(e.test.id);
      }
    });

    if (
      fullname !== "" &&
      fullname !== undefined &&
      doctorError === "" &&
      dateOfBirth !== "" &&
      dateOfBirth &&
      gender !== "g" &&
      gender !== "" &&
      gender &&
      fullnameError === "" &&
      phoneError == "" &&
      dateOfBirthError === "" &&
      doctorId !== undefined &&
      doctorId !== ""
    ) {
      if (patientId == undefined || patientId == "" || patientId == null) {
        setDisable(true);
        await addPatient(
          fullname,
          phone,
          "A+",
          dateOfBirth,
          gender,
          testsList,
          resultsList,
          totalPrice,
          discount,
          discountPrecentage,
          doctorId.value,
          setTodefualt
        );
        setDisable(false);
      } else {
        setDisable(true);

        await addInvoice(
          patientId.value.id,
          testsList,
          resultsList,
          totalPrice,
          discount == "" || discount == undefined ? 0 : discount,
          discountPrecentage == undefined || discountPrecentage == ""
            ? 0
            : discountPrecentage,
          doctorId.value,
          setTodefualt
        );

        await updatePatinet(
          {
            fullname: fullname,
            phone: phone,
            birthdate: dateOfBirth,
            gender: gender,
            blood_type: "A+",
          },
          patientId.value.id
        );
        setDisable(false);
      }
    } else {
      //
      if (dateOfBirth == "" && !dateOfBirth) {
        setBirthError("please select birth day");
      }

      if (fullname.length < 3) {
        setNameError("name length must be more than 3 letters");
      }
      if (phone.length != 11) {
        setPhoneError("phone must be 11 number");
      }
      if (doctorId == "" || doctorId == undefined || doctorId === null) {
        setDoctorError("please select a doctor");
      }
      if (gender == "" || gender == undefined || gender === "g") {
        setGenderError("please select gender");
      }
    }
  };
  let handleName = (e) => {
    setName(e.target.value);
    if (e.target.value.length < 3) {
      setNameError("name length must be more than 3 letters");
    } else {
      setNameError("");
    }
  };
  let handlePhone = (e) => {
    setPhone(e.target.value);
    if (e.target.value.length != 11) {
      setPhoneError("phone number must be 11 number");
    } else {
      setPhoneError("");
    }
  };
  let changeDiscount = (e) => {
    setPhone(e.target.value);
  };
  let index = 1;
  let listTest = Array();
  let urineFound = true;
  let stoolFound = true;
  let fluifFound = true;
  let urine = [];
  let tempTests = [];
  listOfTest.forEach((element) => {
    //
    if (element.test.category.id == 4) {
      urine.push(element);
    } else {
      tempTests.push(element);
    }
  });
  urine.sort((a, b) =>
    a.test.order_no > b.test.order_no
      ? 1
      : b.test.order_no > a.test.order_no
      ? -1
      : 0
  );
  //
  [...tempTests, ...urine].map((d) => {
    if (d.test.category.category_name === "Urine") {
      listTest.push(
        <div key={index}>
          <TableTest state={d} index={index++} showPrice={urineFound} />
        </div>
      );
      urineFound = false;
    } else if (d.test.category.category_name === "Stool") {
      listTest.push(
        <div key={index}>
          <TableTest state={d} index={index++} showPrice={stoolFound} />
        </div>
      );

      stoolFound = false;
    } else if (d.test.category.category_name === "Seminal Fluid") {
      listTest.push(
        <div key={index}>
          <TableTest state={d} index={index++} showPrice={fluifFound} />
        </div>
      );
      fluifFound = false;
    } else {
      listTest.push(
        <div key={index}>
          <TableTest state={d} index={index++} showPrice={true} />
        </div>
      );
    }
  });
  const promiseOptions = async (inputValue) => {
    const data = await searchForPatients(inputValue);

    return data;
  };

  const colourStyles = {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      // const color = chroma(data.color);

      return {
        ...styles,

        backgroundColor: isFocused ? "#999999" : null,
        color: "#333333",
      };
    },
  };
  return (
    <div className="content-wrapper mainClassInvoice">
      <div className="card card-info topForm">
        <div className="card-header">
          <div className="row">
            <div className="col">
              <h3 className="card-title">Patient Information </h3>
            </div>
            <div className="col ">
              <div className="searchTop card-title w-75">
                <AsyncSelect
                  defaultOptions
                  id="sdgfh"
                  className="card-title"
                  loadOptions={promiseOptions}
                  className="bg-red"
                  styles={customStyles}
                  // options={options}
                  value={patientId}
                  isClearable
                  onChange={handlePatinetChange}
                />
              </div>
            </div>
          </div>
        </div>
        {/* /.card-header */}
        {/* form start */}
        <form
          className="form-horizontal sartUserForm fromCreateTest"
          onSubmit={handleSubmit}
          id="fromCreateTest"
        >
          <div className="card-body">
            <div className="row">
              <div className="col-lg-6">
                <div className="form-group row">
                  <label
                    htmlFor="inputName"
                    className="col-sm-3 col-form-label"
                  >
                    Name
                  </label>
                  <div className="col-sm-9">
                    <input
                      pattern="[A-Za-z]{3}"
                      value={fullname}
                      type="text"
                      onChange={handleName}
                      className="form-control"
                      id="inputName"
                      placeholder="Name"
                    />
                    <div style={{ fontSize: 12, color: "red" }}>
                      {fullnameError}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group row">
                  <label
                    htmlFor="inputPhone"
                    className="col-sm-3 col-form-label"
                  >
                    Phone
                  </label>
                  <div className="col-sm-9">
                    <input
                      value={phone}
                      onChange={handlePhone}
                      type="text"
                      className="form-control"
                      id="inputPhone"
                      placeholder="Phone number"
                    />
                    <div style={{ fontSize: 12, color: "red" }}>
                      {phoneError}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-6">
                <div className="form-group row">
                  <label
                    htmlFor="inputbirthday"
                    className="col-sm-3 col-form-label"
                  >
                    Birthday
                  </label>

                  <div
                    className="input-group date col-sm-9"
                    id="inputbirthday"
                    data-target-input="nearest"
                  >
                    <div
                      className="input-group-append"
                      data-target="#reservationdate"
                      data-toggle="datetimepicker"
                    >
                      <div className="input-group-text">
                        <i className="fa fa-calendar" />
                      </div>
                    </div>

                    <input
                      type="date"
                      // max={Date()}
                      value={dateOfBirth}
                      onChange={(e) => {
                        setBirth(e.target.value);
                        getAge(e.target.value);
                        setBirthError("");
                      }}
                      name="bday"
                      max={today}
                      min="1900-01-01"
                      className="form-control"
                    />
                  </div>
                  <br />
                  <div
                    style={{ fontSize: 12, color: "red" }}
                    className="text text-center w-100"
                  >
                    {dateOfBirthError}
                  </div>
                </div>
              </div>

              <div className="col-lg-6">
                <div className="form-group row">
                  <label
                    htmlFor="inputGender"
                    className="col-sm-3 col-form-label"
                  >
                    Age
                  </label>
                  <div className="col-sm-9 text-center rounded  ">
                    <div className="mt-1 form-control">{age}</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <div className="form-group row">
                  <label
                    htmlFor="inputGender"
                    className="col-sm-3 col-form-label"
                  >
                    Gender
                  </label>
                  <div className="col-sm-9">
                    <select
                      placeholder={"select gender"}
                      className="form-control"
                      id="inputGender"
                      onChange={(e) => {
                        setGender(e.target.value);
                        setGenderError("");
                      }}
                      value={gender}
                    >
                      <option value={"g"} disabled={true}>
                        Select Gender
                      </option>
                      <option value={"Male"}>Male</option>
                      <option value={"Female"}>Female</option>
                      <option value={"child"}>child</option>
                    </select>
                    <div
                      style={{ fontSize: 12, color: "red" }}
                      className="text text-center w-100"
                    >
                      {genderError}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* /.card-body */}
        </form>
      </div>

      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="card card-info">
                <div className="card-header ">
                  <h3 className="card-title white">Add Tests</h3>
                </div>

                <div className="card-body">
                  {/* start  price and discount */}
                  <div className="row">
                    {/*total*/}
                    <div className="col-md-3 col-sm-6">
                      <div className="form-group">
                        <label>Total</label>
                        <input
                          type="text"
                          className="form-control"
                          value={totalPrice}
                          placeholder="Total"
                        />
                      </div>
                    </div>

                    {/*dicpunt*/}
                    <div className="col-md-3 col-sm-6">
                      <div className="form-group">
                        <label>Discount</label>
                        <input
                          value={discount}
                          type="text"
                          onChange={(event) => {
                            let des = 0;
                            if (
                              Number.isNaN(parseInt(event.target.value)) ==
                              false
                            ) {
                              des = event.target.value;
                            }
                            var posNum = des < 0 ? des * -1 : des;
                            dispatch({ type: EDIT_DISCOUNT, payload: posNum });
                            setDIscountPrecentage(0);
                            setDIscount(event.target.value);
                          }}
                          className="form-control"
                          placeholder="Discount"
                        />
                      </div>
                    </div>
                    {/*dsicpun %*/}
                    <div className="col-md-3 col-sm-6">
                      <div className="form-group">
                        <label>Discount %</label>
                        <input
                          type="text"
                          className="form-control"
                          value={discountPrecentage}
                          onChange={(event) => {
                            let des = 0;
                            if (
                              Number.isNaN(parseInt(event.target.value)) ==
                              false
                            ) {
                              des = event.target.value;
                            }
                            var posNum = des < 0 ? des * -1 : des;

                            dispatch({
                              type: EDIT_DISCOUNT_PRECENTAGE,
                              payload: posNum,
                            });
                            setDIscount(0);
                            setDIscountPrecentage(event.target.value);
                          }}
                          placeholder="Discount %"
                        />
                      </div>
                    </div>
                    {/*final */}
                    <div className="col-md-3 col-sm-6">
                      <div className="form-group ">
                        <label>Final Price</label>
                        <div className="badge badge-warning">
                          <input
                            type="text"
                            className="form-control"
                            value={finalPrice}
                            placeholder="Final Price"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-md-3 col-sm-6">
                      <div className="form-group">
                        <label>Referred by</label>
                        <div className="select2-purple">
                          <Select
                            className="basic-single"
                            classNamePrefix="select refreal doctor"
                            isDisabled={false}
                            isClearable={true}
                            isSearchable={true}
                            value={doctorId}
                            onChange={(e) => {
                              let docId = e;
                              if (docId == undefined || docId == "") {
                              } else {
                                setDoctorError("");
                              }
                              setDoctor(docId);
                            }}
                            options={doctorsList}
                          />

                          <div style={{ fontSize: 12, color: "red" }}>
                            {doctorError}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <hr></hr>

                  <DefrentTest />
                  <hr></hr>
                  <AddTestRow />

                  {listTest}

                  <div className="bottomSubmet">
                    <button
                      disabled={disable}
                      type="submit"
                      onClick={handleSubmit}
                      className="btn btn-success btn-block"
                    >
                      <i className="fas fa-check"></i> Submit
                      <i className="nav-icon fas fa-flask" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default CreateVisit;
