import React, { useEffect, useRef, useState } from "react";
import "./VisitStyle.css";
import { useSelector } from "react-redux";
import { getTests } from "../../../store/reducer/testReducer";
import Select from "react-select";
import swal from "sweetalert";

const AddTestRowForEditInvoice = (props) => {
  const { orginalTestList } = useSelector((state) => state.testReducer);
  const inputRef = useRef();

  useEffect(() => {
    

    if (orginalTestList.length == 0) {
      getTests();
    }
  }, []);
  let listItems = [];
  orginalTestList.map((d) => {
    if (
      d.category.category_name !== "Stool" &&
      d.category.category_name !== "Urine" &&
      d.category.category_name !== "Seminal Fluid"
    ) {
      listItems.push({ value: d.id, label: d.name });
    }
  });
  const [curretTest, setTest] = useState(null);
  const [selectedTestId, setSelectedTestId] = useState(null);
  const handleAdd = (e) => {
    if (e) {
      e.preventDefault();
    }
    if (curretTest !== null && curretTest !== undefined && curretTest !== "") {
      //
      let found = false;
      if (props.listOfTest !== undefined && props.listOfTest !== []) {
        //
        props.listOfTest.forEach((tes) => {
          //
          if (tes.id === curretTest?.id) {
            found = true;
          }
        });
      }
      if (found === false) {
        //
        if (result === undefined || result === "" || result === null) {
          props.addNewTestWithResult(curretTest, "0");
        } else {
          props.addNewTestWithResult(curretTest, result);
        }

        setTest(null);
        setSelectedTestId(null);
        setReult("0");
      } else {
        swal("Opps!", "Failed to add this test , already is added", "error");
      }
    }
  };
  const [result, setReult] = useState();
  return (
    <div className="wrapper">
      <div className="row">
        <div className="col-lg-10 col-md-10 col-7 ml-4">
          <div className="card">
            <div className="card-body row">
              <div className="col-lg-4 col-md-4 col-12 pt-2">
                <Select
                  className="basic-single"
                  classNamePrefix="select test"
                  value={selectedTestId}
                  isDisabled={false}
                  name="color"
                  isClearable={true}
                  isSearchable={true}
                  onChange={(e) => {
                    //
                    if (e == null || e.value == undefined || e.value == "") {
                      setTest(null);
                      setSelectedTestId(null);
                    } else {
                      inputRef.current.focus();
                      setSelectedTestId(e.value.id);
                      orginalTestList.forEach((etest) => {
                        if (etest.id == e.value) {
                          setTest(etest);
                        }
                      });
                    }
                  }}
                  options={listItems}
                />
              </div>
              <div className="col-lg-4 col-md-4 col-12 pt-2 ">
                <div className="row">
                  <div className="col-8">
                    <form onSubmit={handleAdd}>
                      <input
                        className="form-control"
                        id="inputName"
                        placeholder="Result"
                        min={"0"}
                        ref={inputRef}
                        defaultValue="0"
                        value={result}
                        onChange={(e) => {
                          if (e.target.value != undefined) {
                            setReult(e.target.value);
                          } else {
                            setReult("0");
                          }
                          //
                        }}
                      />
                    </form>
                  </div>
                  <div className="col-4">
                    <span>{curretTest?.unit}</span>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 col-12 pt-2">
                <input
                  type="text"
                  className="form-control"
                  id="inputName"
                  placeholder="Price"
                  defaultValue={curretTest?.price}
                  disabled={true}
                />
              </div>
            </div>
          </div>
        </div>

        <div
          onClick={(e) => {
            handleAdd();
          }}
          className="col-lg-1 col-md-1 col-2 center"
        >
          <div
            className="cirlceDiv"
            style={{
              backgroundColor: "green",
            }}
          >
            <i className="fas fa-plus"></i>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddTestRowForEditInvoice;
