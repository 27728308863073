import React, { useEffect } from "react";
import Navbar from "./component/sidebar/navbar";
import Menu from "./component/sidebar/sidebar";
import NewInvoce from "./component/page/visitation/CreateVisit";
import { Route, Switch, Redirect } from "react-router-dom";
import NotFound from "./component/page/Notfound";
import VisitationList from "./component/page/Visitations/VisitationList.js";
import InterResult from "./component/page/Visitations/InterResult";
import EditVisit from "./component/page/Visitations/EditVisit";
import ManageTest from "./component/page/test/ManageTest";
import TestCategory from "./component/page/test/testCategory";
import DoctorScreen from "./component/page/doctor/doctorScreen";
import Expenses from "./component/page/Expenses/Expenses";
import User from "./component/page/admin/user";
import Register from "./component/page/account/register";
import LoginForm from "./component/page/account/LoginForm";
import Supplier from "./component/page/admin/supplier";
import Report from "./component/page/admin/report";
import EachSupplier from "./component/page/admin/EachSupplier";
import { useSelector } from "react-redux";
import EditTest from "./component/page/test/EditTest";
import EditUser from "./component/page/account/editUser";
import IncomesReport from "./component/page/admin/IncomesReport";
import ProfitREport from "./component/page/admin/profitReport";
import ReportByTest from "./component/page/admin/ReportByTest";
import ReportByDoctor from "./component/page/admin/reportByDoctor";
// import ReportByDoctor from "./component/page/admin/reportByDoctor";

export default function App() {
  let loggedIn = useSelector((state) => state.loginReducer.loggedIn);
  let user = useSelector((state) => state.loginReducer.user);
  var styleContent = {
    backgroundColor: "#f4f6f9",
  };
  let isAdmin = false;

  useEffect(() => {

    isAdmin = user?.user.user_info.user_type === "lab-admin";
    ////user_type: "lab-admin"
  }, []);
  //

  return (
    <div>
      {loggedIn === true ? (
        <div className="App bg-white" style={styleContent}>
          <Navbar />
          <Menu />
          <Switch>
            <Route exact path="/">
              <NewInvoce />
            </Route>
            <Route exact path="/lastInvoice">
              <VisitationList />
            </Route>
            <Route exact path="/interResult" component={InterResult} />

            <Route exact path="/editeInvoce" component={EditVisit} />

            <Route exact path="/IncomesReport" component={IncomesReport} />
            <Route exact path="/ProfitREport" component={ProfitREport} />

            <Route exact path="/editTest" component={EditTest} />
            <Route exact path="/editUser" component={EditUser} />

            <Route exact path="/manageTest">
              <ManageTest />
            </Route>
            <Route exact path="/testCategory">
              <TestCategory />
            </Route>
            <Route exact path="/doctorScreen">
              <DoctorScreen />
            </Route>
            <Route exact path="/expenses">
              <Expenses />
            </Route>

            {
              <Route exact path="/users">
                <User />
              </Route>
            }
            <Route exact path="/register">
              <Register />
            </Route>
            <Route exact path="/loginForm">
              <LoginForm />
            </Route>
            <Route exact path="/supplier">
              <Supplier />
            </Route>
            <Route exact path="/report" component={Report} />
            <Route exact path="/reportByTest" component={ReportByTest} />
            <Route exact path="/ReportByDoctor" component={ReportByDoctor} />

            <Route path="/eachSupplier" component={EachSupplier} />

            {isAdmin === true ? (
              <Route exact path="/register" component={Register} />
            ) : (
              <Redirect to={{ pathname: "/" }} />
            )}
            {isAdmin === true ? (
              <Route exact path="/report" component={Report} />
            ) : (
              <Redirect to={{ pathname: "/" }} />
            )}
            {isAdmin === true ? (
              <Route path="/eachSupplier" component={EachSupplier} />
            ) : (
              <Redirect to={{ pathname: "/" }} />
            )}
            {isAdmin === true ? (
              <Route exact path="/users" component={User} />
            ) : (
              <Redirect to={{ pathname: "/" }} />
            )}
            <Route>
              <NotFound />
            </Route>
          </Switch>
        </div>
      ) : (
        <LoginForm />
      )}
    </div>
  );
}
