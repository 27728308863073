import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import {Provider} from 'react-redux';
import reportWebVitals from './reportWebVitals';
import store from './store/store';
import Bootstrap from "bootstrap/dist/css/bootstrap.css";
import {checkUserState} from "./store/reducer/loginReducer";
import {BrowserRouter} from "react-router-dom";
import Print from "./component/page/printresult/print";

require('dotenv').config()

checkUserState();
ReactDOM.render(
    <React.StrictMode>
        {/*<Print/>*/}

        <Provider store={store}>
            <BrowserRouter>
                <App/>
            </BrowserRouter>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
);

reportWebVitals();
