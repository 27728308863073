import * as types from "../actions/action_types";
import api from "../api";
import store from "../store";
import * as fun from "../actions/actions";
import { getToken, logout } from "./loginReducer";
import swal from "sweetalert";

export default function doctorsReducer(
  state = { doctors: [], isLoaded: false, alertOpen: false, selectedDoc: null },
  action
) {
  switch (action.type) {
    case types.GET_DOCTOR:
      return (state = {
        ...state,
        isLoaded: true,
        selectedDoc: null,
        alertOpen: false,
        doctors: action.payload,
      });
    case types.ADD_DOCTOR: {
      return {
        ...state,
        isLoaded: true,
        alertOpen: false,
        selectedDoc: null,
        doctors: [action.payload, ...state.doctors],
      };
    }
    case types.DELETE_DOCTOR: {
      return state;
    }
    case types.OPEN_DOCTOR_ALERT: {
      return {
        ...state,
        isLoaded: true,
        alertOpen: true,
        selectedDoc: action.payload,
      };
    }
    case types.CLOSE_DOCTOR_ALERT: {
      return {
        ...state,
        isLoaded: true,
        alertOpen: false,
        selectedDoc: null,
      };
    }
    case types.EDIT_DOCTOR: {
      var index = -1;
      [...state.doctors].forEach((value, i) => {
        if (value.id === action.payload.id) {
          index = i;
        }
      });
      state.doctors[index] = action.payload;

      return {
        ...state,
        isLoaded: true,
        alertOpen: false,
        selectedDoc: null,
        doctors: [...state.doctors],
      };
    }
    default:
      return state;
  }
}
export async function getDoctors() {
  const config = {
    headers: { Authorization: `Bearer ${getToken()}` },
  };
  try {
    const response = await api.get("doctors?_take=10000000", config);

    const data = response.data;

    store.dispatch(fun.getDoctorsAction(data));
  } catch (error) {
    if (error?.response?.status == 401) {
      logout();
    }
  }
}
export async function addNewDoctors(name) {
  const config = {
    headers: { Authorization: `Bearer ${getToken()}` },
  };
  try {
    const response = await api.post(
      "doctors/create",
      {
        name: name,
      },
      config
    );
    const data = response.data;

    document.getElementById("doctorNameForm").reset();
    document.getElementById("doctorNameForm").value = "";
    document.getElementById("doctorNameForm").text = "";

    store.dispatch(fun.addDoctorAction(data));
    swal("Good job!", "doctor was added successfully", "success");
  } catch (error) {
    await swal("Opps!", "Failed to add doctor", "error");

    if (error?.response?.status == 401) {
      logout();
    }
  }
}
export async function EditDoctors(name, id) {
  const config = {
    headers: { Authorization: `Bearer ${getToken()}` },
  };
  try {
    const response = await api.put(
      "doctors/update/" + id,
      {
        name: name,
        reason: "notes here",
      },
      config
    );

    const data = response.data;

    document.getElementById("doctorNameForm").reset();
    document.getElementById("doctorNameForm").value = "";
    document.getElementById("doctorNameForm").text = "";

    store.dispatch(fun.editDoctorAction(data));
    swal("Good job!", "doctor was updated successfully", "success");
  } catch (error) {
    if (error?.response?.status == 401) {
      logout();
    }
    await swal("Opps!", "Failed to update doctor", "error");
  }
}
