import * as types from "../actions/action_types";
import { LOG_OUT } from "../actions/action_types";
import axios from "axios";
import store from "../store";
import { createBrowserHistory } from "history";
import swal from "sweetalert";
var CryptoJS = require("crypto-js");

export default function loginReducer(
  state = { loggedIn: false, user: undefined },
  action
) {
  switch (action.type) {
    case types.SET_CURRENT_USER:
      return (state = {
        ...state,
        loggedIn: true,
        user: { ...action.payload },
      });
    case types.LOG_OUT: {
      return (state = {
        ...state,
        loggedIn: false,
        user: undefined,
      });
    }
    case types.CHECK_LOGIN_STATE: {
      return (state = {
        ...state,
        loggedIn: false,
        user: undefined,
      });
    }
    default:
      return state;
  }
}
axios.defaults.withCredentials = true;

export async function login(username, password) {
  
      try {
        const response = await loginAPI.post("/login", {
          username: username,
          password: password,
        });
        const data = JSON.stringify(response.data);

        var ciphertext = CryptoJS.AES.encrypt(
          JSON.stringify(data),
          process.env.REACT_APP_KEY
        ).toString();

        localStorage.setItem("user", ciphertext);

        store.dispatch({
          type: types.SET_CURRENT_USER,
          payload: { user: JSON.parse(data) },
        });
      } catch (error) {
        await swal("Opps!", "Failed to login", "error");
      }
    
    
}

export async function checkUserState() {
  try {
    const user = await localStorage.getItem("user");
    var decryptedData;
    if (user != null && user != undefined) {
      var bytes = CryptoJS.AES.decrypt(user, process.env.REACT_APP_KEY);
      decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    }
    if (decryptedData != null && decryptedData) {
      store.dispatch({
        type: types.SET_CURRENT_USER,
        payload: { loggedIn: true, user: JSON.parse(decryptedData) },
      });
    } else {
      //
      store.dispatch({
        type: types.LOG_OUT,
        payload: { loggedIn: false, user: undefined },
      });
    }
  } catch (e) {
    store.dispatch({
      type: types.LOG_OUT,
      payload: { loggedIn: false, user: undefined },
    });
  }
}
export function logout() {
  createBrowserHistory().push("/");
  store.dispatch({ type: LOG_OUT });
  localStorage.removeItem("user");
  window.location.reload();
}
const API_URL = "https://yourlab.medicalit.info/app";
const loginAPI = axios.create({
  // baseURL: API_URL,
  baseURL:"https://testlab.medicalit.digital/app/public/api/v1",

  // baseURL:"https://yourlab.medicalitt.info/app2/public/api/v1",
  headers: { "Access-Control-Allow-Origin": "*" },
  withCredentials: true,
  credentials: "Access-Control-Allow-Credentials",
  "Access-Control-Allow-Credentials": true,
  crossorigin: true,
});

export function getToken() {
  try {
    const user = localStorage.getItem("user");
    var decryptedData;
    if (user != null && user != undefined) {
      var bytes = CryptoJS.AES.decrypt(user, process.env.REACT_APP_KEY);

      decryptedData = JSON.parse(JSON.parse(bytes.toString(CryptoJS.enc.Utf8)));
    }
    return decryptedData?.token;
  } catch (e) {
    return null;
  }
}
export function getUserName() {
  try {
    const user = localStorage.getItem("user");
    var decryptedData;
    if (user != null && user != undefined) {
      var bytes = CryptoJS.AES.decrypt(user, process.env.REACT_APP_KEY);

      decryptedData = JSON.parse(JSON.parse(bytes.toString(CryptoJS.enc.Utf8)));
    }
    return decryptedData?.user_info?.fullname ?? "";
  } catch (e) {
    return null;
  }
}
