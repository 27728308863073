import "./TestStyle.css";

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import * as types from "../../../store/actions/action_types";
import {
  addCatagory,
  deleteCatagory,
  getCatagory,
  upDateCatagory,
} from "../../../store/reducer/testGatagoryReducer";
import swal from "sweetalert";

export default function TestCategory() {
  const dispatch = useDispatch();
  const { gatagories, alertOpen } = useSelector(
    (state) => state.catagoryReducer
  );

  useEffect(() => {
    if (!gatagories || gatagories == "" || gatagories.length == 0) {
      getCatagory();
    }
    getCatagory();
  }, []);
  let index = 1;
  const handleClick = (e) => {
    dispatch({
      type: types.OPEN_TEST_GATAGORY_ALERT,
    });
  };

  var name = "";

  function handleName(e, id) {
    if (e.key === "Enter") {
    }

    name = e.target.value;
  }

  const handleSubmitDec = (evt, currentName, currentDes, id) => {
    evt.preventDefault();
    if (currentName.length > 0 && currentDes.length > 1) {
      upDateCatagory(currentName, "currentDes", id);
    }
  };
  let listItems = Array();
  //
  gatagories.map((d) => {
    //
    if(d.category_name!=="Stool"&&d.category_name!=="Urine"&&d.category_name!=="Seminal Fluid"){
      listItems.push(
          <tr key={index}>
            <th scope="row">{index++}</th>
            <td>
              {" "}
              <form
                  className="form"
                  onSubmit={(e) => {
                    handleSubmitDec(e, name, " d.category_description", d.id);
                  }}
                  id="testCatagoryForm"
              >
                <input
                    className="form-control w-75"
                    type="text"
                    placeholder="name"
                    defaultValue={d.category_name}
                    onFocus={handleName}
                    onBlur={handleName}
                    onChange={(e) => {
                      handleName(e, d.id);
                    }}
                />{" "}
              </form>
            </td>
            <td>
              {" "}
              <form
                  className="form"
                  onSubmit={(e) => {
                    handleSubmitDec(e, d.category_name, "derterec", d.id);
                  }}
                  id="testCatagoryForm"
              ></form>
            </td>
            <td>
              <button
                  onClick={(e) => {
                    swal({
                      title: "Are you sure?",
                      text: "Delete This Category ?!",
                      icon: "warning",
                      buttons: true,
                      dangerMode: true,
                    }).then((willDelete) => {
                      if (willDelete) {
                        deleteCatagory(d.id);
                      } else {
                      }
                    });
                  }}
                  className="red btn-circle btn-sm"
              >
                <i className="fas fa-trash white"> </i>
              </button>
            </td>
          </tr>
      );
    }else{
      //
    }
  });

  return (
    <div className="content-wrapper topForm">
      <section className="tableSection">
        <button
          className="btn btn-primary  w-100 tableCateg mb-3"
          onClick={handleClick}
        >
          Add Category
        </button>

        <br />
        <table className="table table-striped  w-100 tableCateg">
          <thead className="bg-cyan">
            <tr>
              <th scope="col">#</th>
              <th scope="col">Name</th>
            </tr>
          </thead>

          <tbody>{listItems}</tbody>
        </table>
      </section>

      <Example show={alertOpen} />
    </div>
  );
}

function Example(props) {
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch({
      type: types.CLOSE_TEST_GATOGORY_ALERT,
    });
  };
  const [testCatagoryType, seTtestCatagoryType] = useState("");
  const [testCatagoryTypeError, seTtestCatagoryTypeError] = useState("");

  const handlAdd = () => {
    if (testCatagoryType !== "") {
      if (testCatagoryTypeError === "") {
        addCatagory(testCatagoryType, "testCatagoryDesc");
        dispatch({
          type: types.CLOSE_TEST_GATOGORY_ALERT,
        });
      }
    } else {
      if (testCatagoryType !== "" || !testCatagoryType) {
        seTtestCatagoryTypeError("catagory type must be more than 2 character");
      }
    }
  };

  const { selectedGatagory } = useSelector((state) => state.catagoryReducer);

  function handleName(e) {
    seTtestCatagoryType(e.target.value);
    if (e.target.value.length < 1) {
      seTtestCatagoryTypeError("category type must be more than 3 character");
    } else {
      seTtestCatagoryTypeError("");
    }
  }

  return props.show == true ? (
    <>
      {" "}
      <Modal show={props.show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add new test catagory</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="form" onSubmit={handlAdd} id="testCatagoryForm">
            <div className="row">
              <div className="col-10">
                <input
                  type="text"
                  onChange={handleName}
                  className="form-control"
                  id="testCatagoryName"
                  placeholder="Test Catagory"
                />
              </div>
              <div style={{ fontSize: 12, color: "red" }}>
                {testCatagoryTypeError}
              </div>
              <div className="col-2"></div>
            </div>
            <br></br>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handlAdd}>
            Add
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  ) : (
    <div></div>
  );
}
