import {Link, useHistory} from "react-router-dom";
import React from "react";
import {useSelector} from "react-redux";

export default function Sidebar(props) {
    const history = useHistory();

    function changeTo(path) {
        history.push(path)
    }

    const {user} = useSelector(
        (state) => state.loginReducer
    );

    function Greeting(props) {

        return props.isLoggedIn == true ? <li className="nav-item">
            <a href="#" className="nav-link">
                <i className="nav-icon fas fa-user-shield"/>
                <p>
                    Admin
                    <i className="fas fa-angle-left right"></i>
                </p>
            </a>
            <ul className="nav nav-treeview">
                <li className="nav-item">
                    <Link to="/users" className="nav-link">
                        <i className="far fa-circle nav-icon"></i>
                        <p>User</p>
                    </Link>
                </li>

                <li className="nav-item">
                    <Link to="/supplier" className="nav-link">
                        <i className="far fa-circle nav-icon"></i>
                        <p>Supplier</p>
                    </Link>
                </li>
                <li className="nav-item">
                    <Link to="/report" className="nav-link">
                        <i className="far fa-circle nav-icon"></i>
                        <p>Report</p>
                    </Link>
                </li>
            </ul>
        </li> : null;
    }

    const fullName = user.user?.user_info?.fullname;
    return (
        <div className="wrapper">
            <aside className="main-sidebar sidebar-dark-primary elevation-4 position-fixed ">
                {/*<BrowserRouter basename="/lab">
                         /!* Brand Logo *!/*/}
                <div className="brand-link" onClick={() => changeTo("/")}>
                    <img
                        src="http://via.placeholder.com/512x512"
                        alt="AdminLTE Logo"
                        className="brand-image img-circle elevation-3"
                        style={{opacity: ".8"}}
                    />
                    <span className="brand-text font-weight-light">Test Lab</span>
                </div>
                {/* Sidebar */}
                <div className="sidebar">
                    {/* Sidebar user panel (optional) */}
                    <div className="user-panel mt-3 pb-3 mb-3 d-flex">

                        <div className="info text-white">

                            {fullName}

                        </div>
                    </div>
                    {/* SidebarSearch Form */}


                    {/* Sidebar Menu */}
                    <nav className="mt-2">
                        <ul
                            className="nav nav-pills nav-sidebar flex-column"
                            data-widget="treeview"
                            role="sidebar"
                            data-accordion="false"
                        >
                            {/*if open each  link add sidebar-open and active*/}

                            <li className="nav-item ">
                                <Link to="/" className="nav-link ">
                                    <i className="nav-icon fas fa-plus-circle"/>
                                    <p>New Invoice</p>
                                </Link>
                            </li>

                            <li className="nav-item">
                                <Link to="/lastInvoice" className="nav-link">
                                    <i className="nav-icon fas fa-list-alt"/>
                                    <p>Latest Invoices</p>
                                </Link>
                            </li>
                            <li className="nav-item  ">
                                <Link to="/manageTest" className="nav-link  ">
                                    <i className="nav-icon fas fa-vial"/>
                                    <p>Manage Tests</p>
                                </Link>
                            </li>
                            <li className="nav-item  ">
                                <Link to="/testCategory" className="nav-link  ">
                                    <i className="nav-icon fas fa-flask"/>
                                    <p>Test Categories</p>
                                </Link>
                            </li>
                            <li className="nav-item  ">
                                <Link to="/doctorScreen" className="nav-link  ">
                                    <i className="nav-icon fas fa-user-md"/>
                                    <p>Doctors</p>
                                </Link>
                            </li>

                            <li className="nav-item  ">
                                <Link to="/expenses" className="nav-link  ">
                                    <i className="nav-icon fas fa-donate"/>
                                    <p>Expense</p>
                                </Link>
                            </li>

                            < Greeting isLoggedIn={user.user.user_info.user_type === "lab-admin"}/>


                        </ul>
                    </nav>
                    {/* /.sidebar-sidebar */}
                </div>
                {/* /.sidebar */}
                {/*</BrowserRouter>*/}
            </aside>
        </div>
    );
}
