import React, { useRef } from "react";

import { useHistory } from "react-router-dom";
import "./LastInvoce.css";
import { useDispatch, useSelector } from "react-redux";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import PatientAndBillInfo from "./UserAndBillInfo";
import {
  CLOSE_ALERT_INVOICES,
  OPEN_ALERT_INVOICES,
} from "../../../store/actions/action_types";
import { deleteInvoice } from "../../../store/reducer/invoicesReducer";
import { useReactToPrint } from "react-to-print";
import { createBrowserHistory } from "history";
import Print from "../printresult/print";

const VisitationComponent = (props) => {
  let dispatch = useDispatch();

  let history = useHistory();
  let index = 0;
  const { selectedInvoices, alertOpen } = useSelector(
    (state) => state.invoicesReducer
  );

  const listItems = props.data.tests.map((test, indexs) => {
    index++;

    return (
      <tr key={index}>
        <td>{index}</td>
        <td>{test?.test?.name} </td>
        <td>{test?.result == null ? `0` : test?.result}</td>
        <td>{test?.test?.unit} </td>
        <td>
          <p id="p_wrap"> {test?.test?.normal_range}</p>
        </td>
      </tr>
    );
  });

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <section className="content tableSection">
      <div className="card card-info">
        <div className="card-header row  ">
          <div className="col">
            <h4 className="card-title"> {props.data?.patient?.fullname} </h4>
          </div>
          <div className="col">
            <h4 className="float-right">Invoice No# {props.data?.id}</h4>
          </div>
          <div className="col">
            <h4 className="float-right"> {props.data?.doctor?.name} </h4>
          </div>
        </div>
        <div className="card-body ">
          <PatientAndBillInfo data={props.data} />
          <br />
          <table className="table table-bordered dataTable rounded-top">
            <thead>
              <tr className="bg-gradient-cyan light">
                <th>Id</th>
                <th>Test Name</th>
                <th>Result</th>
                <th>Unit</th>
                <th className="tableHdResult">Normal range</th>
              </tr>
            </thead>
            <tbody>{listItems}</tbody>
          </table>
        </div>
        {/* /.card-body */}

        <div>
          <div className="center white bottomBtn">
            <button
              onClick={(e) => {
                history.push("/editeInvoce", {
                  ...props.data,
                  tests: [...props.data.tests],
                });
              }}
              className="btn btn-success px-3 "
            >
              <i className="far fa-edit pr-3" aria-hidden="true" />
              Edit
            </button>
            <div hidden>
              {" "}
              <Print
                ref={componentRef}
                state={{ ...props.data, tests: [...props.data.tests] }}
              />
            </div>
            <button
              type="button"
              onClick={handlePrint}
              // onClick={(e) => {
              //
              //   history.push("/PrintResult", props.data);
              // }}
              className="btn btn-info px-3"
            >
              <i className="fas fa-tint pr-3" aria-hidden="true" /> Print
            </button>
            <button
              onClick={(e) => {
                history.push("/interResult", {
                  ...props.data,
                  tests: [...props.data.tests],
                });
              }}
              className="btn btn-warning px-3"
            >
              <i className="fas fa-flask pr-3" aria-hidden="true" />
              Enter Result
            </button>
            <button
              type="button"
              className="btn btn-danger px-3"
              onClick={(e) => {
                dispatch({ type: OPEN_ALERT_INVOICES, payload: props.data });
              }}
            >
              <i className="fas fa-trash pr-3" aria-hidden="true" />
              Delete
            </button>
          </div>
          <br />
        </div>
      </div>

      <Example
        show={alertOpen === true && selectedInvoices.id === props.data.id}
      />
      {/**/}
    </section>
  );
};

export default VisitationComponent;

function Example(props) {
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch({
      type: CLOSE_ALERT_INVOICES,
    });
  };
  const handleDelete = () => {
    deleteInvoice(selectedInvoices.id);
    dispatch({
      type: CLOSE_ALERT_INVOICES,
    });
  };

  const { selectedInvoices } = useSelector((state) => state.invoicesReducer);

  return props.show == true ? (
    <>
      {" "}
      <Modal show={props.show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Invoice</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">Are you Sure delete this invoice ?</div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            No
          </Button>
          <Button variant="primary" onClick={handleDelete}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  ) : (
    <div></div>
  );
}
