import React, { useEffect } from "react";
import "./adminStyle.css";
import { getInvocies } from "../../../store/reducer/reportReducer";
import { useSelector } from "react-redux";
import { createBrowserHistory } from "history";

const IncomesReport = (props) => {
  useEffect(() => {
    let data = props.location.state;

    getInvocies(data.from, data.to);
  }, []);
  const { user } = useSelector((state) => state.loginReducer, []);
 
  if (user.user.user_info.user_type !== "lab-admin") {
    createBrowserHistory().push("/");
    window.location.reload();
  }

  const { invoices, expenses, paybacks, isLoaded } = useSelector(
    (state) => state.reportReducer
  );
  let index = 1;
  let total = 0;
  let subtotal = 0;
  let discount = 0;
  let totalExpesne = 0;
  let totalPayPack = 0;
  const testListItem = invoices?.map((d) => {
    try {
      total = total + parseInt(d.total);
    } catch (e) {}

    let dat =
      parseInt(d.total) -
      parseInt(d.dicount_money) -
      parseInt(d.total) * (parseInt(d.dicount_percent) / 100);

    subtotal = parseInt(subtotal) + parseInt(dat);
    discount = parseInt(total) - parseInt(subtotal);
    return (
      <tr key={d.id}>
        <td>{index++}</td>
        <td>{d.patient.fullname}</td>

        <td>{d.dicount_money}</td>
        <td>{d.dicount_percent} %</td>
        <td>
          {parseInt(d.total) -
            (parseInt(d.dicount_money) + d.total * (d.dicount_percent / 100))}
        </td>
        <td>{d.total}</td>
        <td>{d.created_at}</td>
      </tr>
    );
  });
  let expensesIndex = 0;
  const lexpensesistItem = expenses?.map((d) => {
    totalExpesne = totalExpesne + parseInt(d.amount);
    expensesIndex++;
    return (
      <tr key={d.id}>
        <td>{expensesIndex}</td>
        <td>{d.amount}</td>
        <td>{d.created_by.fullname}</td>
        <td>{d.reason}</td>

        <td>{d.date}</td>
      </tr>
    );
  });
  let paybacksIndex = 0;
  const paybacksistItem = paybacks?.map((d) => {
    totalPayPack = totalPayPack + parseInt(d.amount);
    return (
      <tr key={d.id}>
        <td>{expensesIndex++}</td>
        <td>{d.amount}</td>
        <td>{d.created_by.fullname}</td>
        <td>{d.note}</td>

        <td>{d.date}</td>
      </tr>
    );
  });

  return (
    <div className="content-wrapper topForm">
      <section className="tableSection">
        <ul className="nav nav-tabs" id="myTab" role="tablist">
          <li className="nav-item">
            <a
              className="nav-link active"
              id="home-tab"
              data-toggle="tab"
              href="#home"
              role="tab"
              aria-controls="home"
              aria-selected="true"
            >
              List Invoice
            </a>
          </li>
          <li className="nav-item">
            <a
              className="nav-link"
              id="profile-tab"
              data-toggle="tab"
              href="#profile"
              role="tab"
              aria-controls="profile"
              aria-selected="false"
            >
              Expenses
            </a>
          </li>
          <li className="nav-item">
            <a
              className="nav-link"
              id="contact-tab"
              data-toggle="tab"
              href="#contact"
              role="tab"
              aria-controls="contact"
              aria-selected="false"
            >
              {" "}
              Payback
            </a>
          </li>
        </ul>
        <div className="tab-content" id="myTabContent">
          <div
            className="tab-pane fade show active"
            id="home"
            role="tabpanel"
            aria-labelledby="home-tab"
          >
            {/*tab 1*/}
            <table className="table table-bordered  dataTable rounded-top mt-5">
              <thead className="rounded-top">
                <tr className="bg-gradient-cyan light rounded-top">
                  <th>#</th>
                  <th>Name</th>

                  <th>Discount</th>
                  <th>Discount%</th>
                  <th>SubTotal</th>

                  <th>Total</th>
                  <th className="tableHdResult">Date</th>
                </tr>
              </thead>
              <tbody>{testListItem}</tbody>
            </table>
            <br />
            <span className="badge badge-info mb-3">
              {" "}
              <h5 className="d-inline">Subtotal</h5>{" "}
            </span>
            :
            <span className="badge badge-danger mb-3 mr-3">
              {" "}
              <h5 className="d-inline">{subtotal} IQD</h5>{" "}
            </span>
            <span className="badge badge-info mb-3">
              {" "}
              <h5 className="d-inline">discount</h5>{" "}
            </span>
            :
            <span className="badge badge-danger mb-3 mr-3">
              {" "}
              <h5 className="d-inline">{discount} IQD</h5>{" "}
            </span>
            <span className="badge badge-info mb-3">
              {" "}
              <h5 className="d-inline">total</h5>{" "}
            </span>
            :
            <span className="badge badge-danger mb-3 mr-3">
              {" "}
              <h5 className="d-inline">{total} IQD</h5>{" "}
            </span>
          </div>

          <div
            className="tab-pane fade"
            id="profile"
            role="tabpanel"
            aria-labelledby="profile-tab"
          >
            {/*tab 2*/}

            <div className="mt-5">
              <table className="table table-bordered  dataTable rounded-top ">
                <thead className="rounded-top">
                  <tr className="bg-gradient-cyan light rounded-top">
                    <th>#</th>
                    <th>Amount</th>
                    <th>created by</th>
                    <th>reason</th>
                    <th>created at</th>
                  </tr>
                </thead>
                <tbody>{lexpensesistItem}</tbody>
              </table>
              <br />
              <span className="badge badge-info mb-3">
                {" "}
                <h5 className="d-inline">total</h5>{" "}
              </span>
              :
              <span className="badge badge-danger mb-3 mr-3">
                {" "}
                <h5 className="d-inline"> {totalExpesne} IQD</h5>{" "}
              </span>
            </div>
          </div>
          <div
            className="tab-pane fade"
            id="contact"
            role="tabpanel"
            aria-labelledby="contact-tab"
          >
            {/*tab 3*/}

            <div className="mt-5">
              <table className="table table-bordered  dataTable rounded-top ">
                <thead className="rounded-top">
                  <tr className="bg-gradient-cyan light rounded-top">
                    <th>#</th>
                    <th>Amount</th>
                    <th>created by</th>
                    <th>Note</th>
                    <th>created at</th>
                  </tr>
                </thead>
                <tbody>{paybacksistItem}</tbody>
              </table>
              <br />
              <span className="badge badge-info mb-3">
                {" "}
                <h5 className="d-inline">total</h5>{" "}
              </span>
              :
              <span className="badge badge-danger mb-3 mr-3">
                {" "}
                <h5 className="d-inline"> {totalPayPack} IQD</h5>{" "}
              </span>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default IncomesReport;
