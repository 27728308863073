import * as types from "../actions/action_types";
import api from "../api";
import store from "../store";
import swal from "sweetalert";
import { getToken, logout } from "./loginReducer";

export default function supplerReducer(
  state = {
    suppliers: [],
    isSupplierLoaded: false,
    supplerAlertOpen: false,
    selectedSuppler: null,
  },
  action
) {
  switch (action.type) {
    case types.GET_SUPPLERS:
      return (state = {
        ...state,
        isSupplierLoaded: true,
        selectedSuppler: null,
        supplerAlertOpen: false,
        suppliers: action.payload,
      });
    case types.ADD_SUPPLER: {
      return {
        ...state,
        isSupplierLoaded: true,
        supplerAlertOpen: false,
        selectedSuppler: null,
        suppliers: [...state.suppliers, action.payload],
      };
    }

    case types.OPEN_SUPPLER_ALERT: {
      return {
        ...state,
        isSupplierLoaded: true,
        supplerAlertOpen: true,
        selectedSuppler: action.payload,
      };
    }
    case types.CLOSE_SUPPLER_ALERT: {
      return {
        ...state,
        isSupplierLoaded: true,
        supplerAlertOpen: false,
        selectedSuppler: null,
      };
    }
    case types.FORCE_REFRSH: {
      return {
        ...state,

        isSupplierLoaded: true,
        supplerAlertOpen: false,
        selectedSuppler: null,
        suppliers: [...state.suppliers],
      };
    }

    case types.EDIT_SUPPLER: {
      let index = 0;
      state.suppliers.forEach((value, i) => {
        if (value.id === action.payload.id) {
          index = i;
        }
      });
      state.suppliers[index] = action.payload;

      return {
        ...state,
        isSupplierLoaded: true,
        supplerAlertOpen: false,
        selectedSuppler: null,
        suppliers: [...state.suppliers],
      };
    }
    case types.SEARCH_SUPPLER: {
      var temp = state.suppliers.filter((test) =>
        test.name.toLowerCase().includes(action.payload.toLowerCase())
      );

      return {
        ...state,
        isSupplierLoaded: true,
        supplerAlertOpen: false,
        selectedSuppler: null,
        suppliers: [...state.suppliers],
      };
    }
    case types.FILTER_SUPPLER: {
      temp = state.suppliers.filter(
        (test) => test.category.id == action.payload.id
      );

      return {
        ...state,
        isSupplierLoaded: true,
        supplerAlertOpen: false,
        selectedSuppler: null,
        suppliers: [...state.suppliers],
      };
    }
    case types.DELETE_SUPPLER: {
      const newList = state.suppliers.filter(
        (item) => item.id !== action.payload.id
      );

      return {
        ...state,
        isSupplierLoaded: true,
        supplerAlertOpen: false,
        selectedSuppler: null,
        suppliers: [...newList],
      };
    }
    default:
      return state;
  }
}

export async function getSupplers() {
  const config = {
    headers: { Authorization: `Bearer ${getToken()}` },
  };
  try {
    const response = await api.get("suppliers?_take=10000000", config);

    const data = response.data;

    store.dispatch({ type: types.GET_SUPPLERS, payload: data });
  } catch (error) {
    if (error?.response?.status == 401) {
      logout();
    }
  }
}

export async function addSuppler(name, phone, address) {
  const config = {
    headers: { Authorization: `Bearer ${getToken()}` },
  };
  try {
    const response = await api.post(
      "suppliers/create",
      {
        name: name,
        phone: phone,
        address: address,
      },
      config
    );

    const data = response.data;

    document.getElementById("supplerAddForm").reset();
    store.dispatch({ type: types.ADD_SUPPLER, payload: data });
    await swal("Good job!", "Supplier was added successfully", "success");
  } catch (error) {
    if (error?.response?.status == 401) {
      logout();
    }
    await swal("Opps!", "Failed to add supplier", "error");
  }
}

export async function upDateSuppler(name, phone, address, id) {
  const config = {
    headers: { Authorization: `Bearer ${getToken()}` },
  };
  try {
    const response = await api.put(
      "suppliers/update/" + id,
      {
        name: name,
        phone: phone,
        address: address,
      },
      config
    );

    const data = response.data;

    store.dispatch({ type: types.EDIT_SUPPLER, payload: data });
    swal("Good job!", "Supplier was updated successfully", "success");
  } catch (error) {
    if (error?.response?.status == 401) {
      logout();
    }
    swal("Opps!", "Failed to update supplier", "error");
  }
}

export async function deleteSuppler(id) {
  const config = {
    headers: { Authorization: `Bearer ${getToken()}` },
  };
  try {
    const response = await api.delete(
      "suppliers/delete/" + id,

      config
    );

    const data = response.data;

    store.dispatch({ type: types.DELETE_SUPPLER, payload: { id: id } });
    swal("Good job!", "Supplier was deleted successfully", "success");
  } catch (error) {
    swal("Opps!", "Failed to delete supplier", "error");
    if (error?.response?.status == 401) {
      logout();
    }
  }
}
