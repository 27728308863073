import React, { Component } from "react";
import "./printStyle.css";

class PageUrine extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    //
    let title = "";

    let resultWithTest = [...this.props.resultWithTest];
    resultWithTest.sort((a, b) =>
      a.test.order_no > b.test.order_no
        ? 1
        : b.test.order_no > a.test.order_no
        ? -1
        : 0
    );

    //

    let index = 1;
    let rowsList = Array([], []);
    resultWithTest.forEach((element, index) => {
      if (element.test.order_no < 12) {
        rowsList[0].push(element);
      } else {
        rowsList[1].push(element);
      }
    });
    const names = ["Macroscopic Urine", "Microscopic Urine"];
    let finalRes = Array();

    //
    rowsList.forEach((element, ind) => {
      //
      if (element != [] && element.length != 0) {
        let name = "";

        let item = element.map((d, spIndex) => {
          name = d.test.category.category_name;
          return (
            <tr
              className="border_bottom"
              key={Math.floor(Math.random() * Math.floor(457687574))}
            >
              <td>{index++}</td>
              <td className="text-bold">{d.test?.name}</td>
              <td className="text-bold">
                {d?.result}{" "}
                <span className="p-0 pl-1 m-0 text-success ">
                  {d.test?.unit}
                </span>
              </td>
              <td>{d.test?.instrument}</td>
              <td>
                <p id="p_wrap">{d.test?.normal_range}</p>
              </td>
            </tr>
          );
        });
        finalRes.push(
          <div key={`indindindind${ind}`}>
            <span
              className="h3"
              className="text-white  p-2 mb-2 bg-danger d-inline-block w-50"
            >
              {names[ind]}
            </span>

            <table className="table table-bordered">
              <thead>
                <tr>
                  <th className="text-white p-0" style={{ width: "5%" }}>
                    <div className="bg-primary m-0 p-3"> # </div>
                  </th>
                  <th className="text-white p-0" style={{ width: "15%" }}>
                    <div className="bg-primary m-0 p-3">Test</div>
                  </th>
                  <th className="bg-primary p-0" style={{ width: "15%" }}>
                    <div className="bg-primary m-0 p-3">Result</div>
                  </th>
                  <th className="bg-primary p-0" style={{ width: "10%" }}>
                    <div className="bg-primary m-0 p-3">Device</div>
                  </th>
                  <th className="text-white p-0" style={{ width: "55%" }}>
                    <div className="bg-primary m-0 p-3">Normal Range</div>
                  </th>
                </tr>
              </thead>
              <tbody>{item}</tbody>
            </table>
          </div>
        );
      }
    });
    return (
      <div className="container ">
        <div className="m-2 text-center">
          <h5>{finalRes}</h5>
        </div>
      </div>
    );
  }
}

export default PageUrine;
