import React, { useEffect, useState } from "react";
import "./LastInvoce.css";
import Select from "react-select";
import VisitationComponent from "./VisitationComponent";
import { useDispatch, useSelector } from "react-redux";
import {
  addNewDoctors,
  getDoctors,
} from "../../../store/reducer/doctorsReducer";

import { getInvoices } from "../../../store/reducer/invoicesReducer";
import ReactPaginate from "react-paginate";
import { getTests } from "../../../store/reducer/testReducer";
import { SET_NODATA_FOUND } from "../../../store/actions/action_types";

const VisitationList = () => {
  const { doctors } = useSelector((state) => state.doctorsReducer);
  useEffect(() => {
    if (!doctors || doctors == "" || doctors.length == 0) {
      getDoctors();
    }
  }, []);
  const { orginalTestList } = useSelector((state) => state.testReducer);
  useEffect(() => {
    if (orginalTestList.length == 0) {
      getTests();
    }
  }, []);

  let testsList = [];
  orginalTestList.map((d) => {
    testsList.push({ value: d.id, label: d.name });
  });
  let dispatch = useDispatch();
  const { invoices, totalPages } = useSelector(
    (state) => state.invoicesReducer
  );

  useEffect(() => {
    getInvoices(searchParam);
  }, []);
  let index = 1;
   const listItems = invoices.map((d) => {
    index++;
    return <VisitationComponent key={index} data={d} />;
  });
  const handleSubmit = (evt) => {
    evt.preventDefault();
    addNewDoctors(name);
  };
  var name = "";

  let doctrosList = [];
  doctors.map((d) => {
    doctrosList.push({ value: d.id, label: d.name });
  });

  //
  const dropStyle = {
    colors: "red",
  };
  const customStyles = {
    control: (styles) => ({ ...styles, backgroundColor: "white" }),
    option: (provided, state) => ({
      ...provided,
      borderBottom: "1px dotted pink",
      color: "blue",
      padding: 10,
    }),

    singleValue: (provided, state) => {
      const opacity = state.isDisabled ? 0.5 : 1;
      const transition = "opacity 300ms";

      return { ...provided, opacity, transition };
    },
  };

  const [searchParam, setSearchParam] = useState({
    test_name: "",
    invoice_id: "",
    filter: "",
    patient_name: "",
    to: "3000-01-01",
    patient_id: "",
    doctor_id: "",
    from: "2021-01-01",
    page: 0,
  });
   return (
    <div className="content-wrapper mainContent">
      <div className="card m-4 card-primary ">
        <div className="card-body card-body-cascade text-center bg-primary rounded">
          <form className="form-inline active-pink-3 active-pink-4">
            <div className="row w-100">
              {/* <div className="col-md-4 input-group-lg mt-2">
                <div className="input-group md-form form-sm form-1 pl-0">
                  <input
                    onChange={(event) => {
                   
                    }}
                    className="form-control my-0 py-1"
                    type="search"
                    placeholder="Search by doctor"
                    aria-label="Search"
                  />
                  <div className="input-group-prepend">
                    <span
                      className="input-group-text purple lighten-3"
                      id="basic-text1"
                    >
                      <i className="fas fa-search" aria-hidden="true" />
                    </span>
                  </div>
                </div>
              </div> */}

              <div className="col-md-4 input-group-lg mt-2">
                <div className="input-group md-form form-sm form-1 pl-0">
                  <input
                    onChange={(event) => {
                      if (event.target.value == undefined) {
                        setSearchParam({
                          ...searchParam,
                          filter: "",
                          patient_name: "",
                        });
                      } else {
                        if (!isNaN(event.target.value)) {
                          setSearchParam({
                            ...searchParam,
                            filter: event.target.value,
                            patient_name:""
                          });
                        } else {
                          setSearchParam({
                            ...searchParam,
                            filter:"",
                            patient_name: event.target.value,
                          });
                        }
                      }
                    }}
                    className="form-control my-0 py-1"
                    type="text"
                    placeholder="Search by patient name"
                    aria-label="Search"
                  />
                  <div className="input-group-prepend">
                    <span
                      className="input-group-text purple lighten-3"
                      id="basic-text1"
                    >
                      <i className="fas fa-search" aria-hidden="true" />
                    </span>
                  </div>
                </div>
              </div>

              <div className="col-md-4 input-group-lg mt-2">
                <div className="input-group md-form form-sm form-1 pl-0">
                  <input
                    onChange={(event) => {
                      if (event.target.value == undefined) {
                        setSearchParam({
                          ...searchParam,
                          patient_id: "",
                        });
                      } else {
                        setSearchParam({
                          ...searchParam,
                          patient_id: event.target.value,
                        });
                      }
                    }}
                    className="form-control my-0 py-1"
                    type="text"
                    placeholder="Search by patient id"
                    aria-label="Search"
                  />
                  <div className="input-group-prepend">
                    <span
                      className="input-group-text purple lighten-3"
                      id="basic-text1"
                    >
                      <i className="fas fa-search" aria-hidden="true" />
                    </span>
                  </div>
                </div>
              </div>

              <div className="col-md-4 input-group-lg mt-2">
                <div className="input-group md-form form-sm form-1 pl-0">
                  <input
                    onChange={(event) => {
                      if (event.target.value == undefined) {
                        setSearchParam({
                          ...searchParam,
                          invoice_id: "",
                        });
                      } else {
                        setSearchParam({
                          ...searchParam,
                          invoice_id: event.target.value,
                        });
                      }
                    }}
                    className="form-control my-0 py-1"
                    type="text"
                    placeholder="Search by Invoice Id"
                    aria-label="Search"
                  />
                  <div className="input-group-prepend">
                    <span
                      className="input-group-text purple lighten-3"
                      id="basic-text1"
                    >
                      <i className="fas fa-search" aria-hidden="true" />
                    </span>
                  </div>
                </div>
              </div>

              <div className="col-md-4 input-group-lg mt-2">
                <div className="input-group md-form form-sm form-1 pl-0">
                  <input
                    onChange={(event) => {
                      if (event.target.value == undefined) {
                        setSearchParam({
                          ...searchParam,
                          test_name: "",
                        });
                      } else {
                        setSearchParam({
                          ...searchParam,
                          test_name: event.target.value,
                        });
                      }
                    }}
                    className="form-control my-0 py-1"
                    type="text"
                    placeholder="Search by test name"
                    aria-label="Search"
                  />
                  <div className="input-group-prepend">
                    <span
                      className="input-group-text purple lighten-3"
                      id="basic-text1"
                    >
                      <i className="fas fa-search" aria-hidden="true" />
                    </span>
                  </div>
                </div>
              </div>

              {/*testes list drop down*/}
              <div className="col-md-4 input-group-lg mt-2">
                <Select
                  styles={customStyles}
                  classNamePrefix="select test "
                  name="color"
                  isClearable={true}
                  isSearchable={true}
                  onChange={(event) => {
                    if (event == undefined) {
                      setSearchParam({
                        ...searchParam,
                        test_name: "",
                      });
                    } else {
                      setSearchParam({
                        ...searchParam,
                        test_name: event.label,
                      });
                    }
                  }}
                  options={testsList}
                />
              </div>
              <div className="col-md-4 input-group-lg mt-2">
                <div className="">
                  <Select
                    styles={customStyles}
                    classNamePrefix="select doctor"
                    isDisabled={false}
                    isClearable={true}
                    isSearchable={true}
                    onChange={(event) => {
                      if (event == undefined) {
                        setSearchParam({
                          ...searchParam,
                          doctor_id: "",
                        });
                      } else {
                        setSearchParam({
                          ...searchParam,
                          doctor_id: event.value,
                        });
                      }
                    }}
                    options={doctrosList}
                  />
                </div>
              </div>
              {/*start date*/}
              <div className="col-md-4 input-group-lg mt-2">
                <div className="input-group md-form form-sm form-1 pl-0">
                  <input
                    onChange={(event) => {
                      if (event.target.value == undefined) {
                        setSearchParam({
                          ...searchParam,
                          from: "",
                        });
                      } else {
                        setSearchParam({
                          ...searchParam,
                          from: event.target.value,
                        });
                      }
                    }}
                    type="date"
                    className="form-control my-0 py-1"
                    placeholder="Start date"
                  />
                </div>
              </div>
              {/**/}
              <div className="col-md-4 input-group-lg mt-2">
                <div className="input-group md-form form-sm form-1 pl-0">
                  <input
                    onChange={(event) => {
                      if (event.target.value == undefined) {
                        setSearchParam({
                          ...searchParam,
                          to: "",
                        });
                      } else {
                        setSearchParam({
                          ...searchParam,
                          to: event.target.value,
                        });
                      }
                    }}
                    type="date"
                    className="form-control my-0 py-1"
                    placeholder="end date"
                  />
                </div>
              </div>
            </div>
          </form>
          <div className="  input-group-lg mt-2 mr-4">
            <button
              className="btn w-100 btn-info"
              onClick={(event) => {
                setSearchParam({ ...searchParam, page: 0 });
                dispatch({ type: SET_NODATA_FOUND });

                getInvoices(searchParam);
              }}
            >
              Search
            </button>
          </div>
        </div>
      </div>

      {listItems}

      {totalPages > 0 ? (
        <div className="center">
          <ReactPaginate
            previousLabel={"prev"}
            nextLabel={"next"}
            breakLabel={"..."}
            breakClassName={"break-me"}
            onPageChange={(ev) => {
              document.body.scrollTop = 0;
              document.documentElement.scrollTop = 0;
               getInvoices({ ...searchParam, page: ev.selected });
              dispatch({ type: SET_NODATA_FOUND });
              setSearchParam({ ...searchParam, page: ev.selected });
            }}
            pageCount={totalPages / 5}
            pageNum={searchParam.page}
            forcePage={searchParam.page}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            containerClassName={"pagination"}
            subContainerClassName={"pages pagination"}
            activeClassName={"active"}
          />
        </div>
      ) : null}
    </div>
  );
};

export default VisitationList;
