import React, { useEffect, useReducer, useRef, useState } from "react";
import UserAndBillInfo from "./UserAndBillInfo";
import { useHistory } from "react-router-dom";
import "./LastInvoce.css";
import {
  interResult,
  upDateInvoice,
} from "../../../store/reducer/invoicesReducer";
import { useReactToPrint } from "react-to-print";
import Print from "../printresult/print";

const InterResult = (props) => {
  let data = props.location.state;
  const [myData, SetData] = useState({});
  const [isCchaned, SetisCchaned] = useState(false);
  const [disable ,setDisable]= useState(false)

  const [tests, setTests] = useState([]);
  useEffect(() => {
    let resultWithTest = data.tests;
    let urine = [];
    let tempTests = [];
    resultWithTest.forEach((element) => {
      
      if (element.test.category.id == 4) {
        urine.push(element);
      } else {
        tempTests.push(element);
      }
    });
    urine.sort((a, b) =>
      a.test.order_no > b.test.order_no
        ? 1
        : b.test.order_no > a.test.order_no
        ? -1
        : 0
    );
    SetData({ ...data, tests: [...tempTests, ...urine] });
    
    SetisCchaned(true);
  }, []);

  const [, forceUpdate] = useReducer((x) => x + 1, 0);

  const handleChangeREsultTest = (index, d) => {
    
    let temp = { ...myData };
    temp.tests.forEach((element) => {
      if (element.test.id == index) {
        element.result = d;
      }
    });

    SetData(temp);

    forceUpdate();
  };

  let history = useHistory();
  
  let listItems = [];
  let index = 0;

  myData?.tests?.forEach((element) => {
    index = index + 1;
    

    listItems.push(
      <tr key={`${index}`}>
        <td>{index}</td>
        <td>{element.test.name} </td>
        <td>{element.test.unit} </td>
        <td key={`index${index}index`}>
          <input
            key={`index${index}`}
            className="form-control my-0 py-1"
            defaultValue={
              element.result !== null && element.result !== undefined
                ? element.result
                : ""
            }
            onChange={(event) => {
              handleChangeREsultTest(element.test.id, event.target.value);
            }}
            placeholder="Result Test"
          />
        </td>
      </tr>
    );
  });

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  return isCchaned == false ? null : (
    <div className="content-wrapper bg-whites topDevIner">
      <section className="interResultStyle">
        <div className="card card-info">
          <div className="card-header ">
            <h4 className="card-title">{myData.patient.fullname}</h4>
            <h4 className="float-right">Invoice No# {myData.id}</h4>
          </div>

          <div className="card-body ">
            <UserAndBillInfo data={{ ...myData }} />

            <br />
            <table className="table table-bordered  rounded-top w-100">
              <thead>
                <tr className="bg-gradient-cyan light">
                  <th>Id</th>
                  <th>Test Name</th>
                  <th>Unit</th>
                  <th className="w-75">Result</th>
                </tr>
              </thead>
              <tbody>{listItems}</tbody>
            </table>
          </div>
          {/* /.card-body */}

          <div>
            <div className="center white bottomBtn">
              <div hidden>
                {" "}
                <Print
                  ref={componentRef}
                  state={{ ...myData, tests: [...myData.tests] }}
                />
              </div>
              <button
                type="button"
                onClick={(event) => {
                  //
                  handlePrint();
                }}
                // onClick={(e) => {
                //
                //   history.push("/PrintResult", props.data);
                // }}
                className="btn btn-info px-3"
              >
                <i className="fas fa-tint pr-3" aria-hidden="true" /> Print
              </button>

              <button
              
                disabled={disable}
                className="btn btn-success px-3"
                onClick={async(event) => {
                  let permittedValues = myData.tests.map(function (value) {
                    return value.test.id;
                  });

                  let permittedRes = myData.tests.map(function (value) {
                    //
                    return value.result == "" || value.result === undefined
                      ? "0"
                      : value.result;
                  });
                  //

                  setDisable(true);  
                 await interResult(
                    {
                      patient_id: myData.patient.id,
                      total: myData.total,
                      dicount_percent: myData.dicount_percent,
                      dicount_money: myData.dicount_money,
                      doctor_id: myData.doctor.id,
                      tests: permittedValues,
                      results: permittedRes,
                    },
                    myData.id
                  );
                  setDisable(false);  
                }}

              >
                <i className="fas fa-flask pr-3" aria-hidden="true" />
                Save
              </button>
            </div>
            <br />
          </div>
        </div>
      </section>
    </div>
  );
};

export default InterResult;
