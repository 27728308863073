import React, { useEffect, useState } from "react";
import "./adminStyle.css";
import { useHistory } from "react-router-dom";
import { createBrowserHistory } from "history";
import Select from "react-select";

import { useSelector } from "react-redux";
import { getTests } from "../../../store/reducer/testReducer";
import { getDoctors } from "../../../store/reducer/doctorsReducer";

const Report = () => {
  const [from, setFrom] = useState();
  const [to, setTo] = useState();
  const { doctors } = useSelector((state) => state.doctorsReducer);
  let doctrosList = [];
  doctors.map((d) => {
    doctrosList.push({ value: d.id, label: d.name });
  });
  const [selectedDoctroId, setDoctroId] = useState(null);
  const [selectedTestId, setSelectedTestId] = useState();

  const [fromError, setFromError] = useState("");
  const [toError, setToError] = useState("");
  let history = useHistory();
  const { user } = useSelector((state) => state.loginReducer, []);

  if (user.user.user_info.user_type != "lab-admin") {
    //
    createBrowserHistory().push("/");
    window.location.reload();
  }
  const { orginalTestList } = useSelector((state) => state.testReducer);
  useEffect(() => {
    
    if (orginalTestList.length == 0) {
      getTests();
    }  if (doctors.length == 0) {
      getDoctors();
    }
  }, []);
  let testsList = [];
  orginalTestList.map((d) => {
    testsList.push({ value: d.id, label: d.name });
  });
  return (
    <div className="content-wrapper topSpace">
      <section className="tableSection">
        <label htmlFor="from" className="col-sm-2 col-form-label">
          From
        </label>
        <div id="from">
          <input
            type="date"
            defaultValue={from}
            onChange={(e) => {
              setFrom(e.target.value);
              setFromError("");
            }}
            name="bday"
            max="2025-1-1"
            min="1000-01-01"
            className="form-control"
          />{" "}
          <div style={{ fontSize: 12, color: "red" }}>{fromError}</div>
        </div>
        <br />
        <label htmlFor="to" className="col-sm-2 col-form-label">
          To
        </label>
        <div id="to">
          <input
            type="date"
            defaultValue={to}
            onChange={(e) => {
              setTo(e.target.value);
              setToError("");
            }}
            name="bday"
            max="2025-1-1"
            min="1000-01-01"
            className="form-control"
          />{" "}
          <div style={{ fontSize: 12, color: "red" }}>{toError}</div>
        </div>
        <br />
        <div className="row">
          <div className="col-md-6 input-group-lg mt-2 ">
            <Select
              className="basic-single"
              classNamePrefix="select doctor"
              isDisabled={false}
              isClearable={true}
              isSearchable={true}
              onChange={(e) => {
                //
                setDoctroId(e?.value);

                if (e == undefined || e.value == undefined) {
                }
              }}
              options={doctrosList}
            />
          </div>

          <button
            className={"btn btn-success"}
            onClick={(event) => {
              if (
                to != undefined &&
                from != undefined &&
                selectedDoctroId != "" &&
                selectedDoctroId != undefined
              ) {
                history.push("/ReportByDoctor", {
                  from: from,
                  to: to,
                  id: selectedDoctroId,
                });
              } else {
                if (from == undefined) {
                  setFromError("please select start date");
                }
                if (to == undefined) {
                  setToError("please select end date");
                }
              }
            }}
          >
            Get Report By Doctor
          </button>
        </div>
        <br />
        <div className="row">
          <div className="col-md-6 input-group-lg mt-2 ">
            <Select
              classNamePrefix="select test"
              isClearable={true}
              isSearchable={true}
              onChange={(e) => {
                setDoctroId(null);
                setSelectedTestId(e?.value);
              }}
              options={testsList}
            />
          </div>
          <button
            className={"btn btn-success"}
            onClick={(event) => {
              //
              if (
                to != undefined &&
                from != undefined &&
                selectedTestId != "" &&
                selectedTestId != undefined
              ) {
                history.push("/reportByTest", {
                  from: from,
                  to: to,
                  id: selectedTestId,
                });
              } else {
                if (from == undefined) {
                  setFromError("please select start date");
                }
                if (to == undefined) {
                  setToError("please select end date");
                }
              }
            }}
          >
            Get Report By Test
          </button>
        </div>

        <br />
        <button
          className={"btn btn-success mx-5"}
          onClick={(event) => {
            if (to != undefined && from != undefined) {
              history.push("/IncomesReport", { from: from, to: to });
            } else {
              if (from == undefined) {
                setFromError("please select start date");
              }
              if (to == undefined) {
                setToError("please select end date");
              }
            }
          }}
        >
          Get Report
        </button>
        <button
          className={"btn btn-success"}
          onClick={(event) => {
            if (to != undefined && from != undefined) {
              history.push("/ProfitREport", { from: from, to: to });
            } else {
              if (from == undefined) {
                setFromError("please select start date");
              }
              if (to == undefined) {
                setToError("please select end date");
              }
            }
          }}
        >
          Get Benefit
        </button>
      </section>
    </div>
  );
};

export default Report;
