import * as types from "../actions/action_types";
import api from "../api";
import store from "../store";
import swal from "sweetalert";
import { getToken, logout } from "./loginReducer";

export default function catagoryReducer(
  state = {
    gatagories: [],
    isLoaded: false,
    alertOpen: false,
    selectedGatagory: null,
  },
  action
) {
  switch (action.type) {
    case types.GET_TEST_GATOGORY:
      return (state = {
        ...state,
        isLoaded: true,
        selectedGatagory: null,
        alertOpen: false,
        gatagories: action.payload,
      });
    case types.ADD_TEST_GATAGORY: {
      return {
        ...state,
        isLoaded: true,
        alertOpen: false,
        selectedExpense: null,
        gatagories: [...state.gatagories, action.payload],
      };
    }

    case types.OPEN_TEST_GATAGORY_ALERT: {
      return {
        ...state,
        isLoaded: true,
        alertOpen: true,
        selectedExpense: action.payload,
      };
    }
    case types.CLOSE_TEST_GATOGORY_ALERT: {
      return {
        ...state,
        isLoaded: true,
        alertOpen: false,
        selectedExpense: null,
      };
    }
    case types.FORCE_REFRSH: {
      return {
        ...state,

        isLoaded: true,
        alertOpen: false,
        selectedExpense: null,
        gatagories: [...state.gatagories],
      };
    }
    case types.DELETE_TEST_CATEGORY: {
      const newList = state.gatagories.filter(
        (item) => item.id !== action.payload.id
      );

      return {
        ...state,
        isLoaded: true,
        alertOpen: false,
        selectedExpense: null,
        gatagories: newList,
      };
    }
    case types.EDIT_TEST_GATOGORY: {
      var index = 0;
      [...state.gatagories].forEach((value, i) => {
        if (value.id === action.payload.id) {
          index = i;
        }
      });
      state.gatagories[index] = action.payload;

      return {
        ...state,
        isLoaded: true,
        alertOpen: false,
        selectedExpense: null,
        gatagories: [...state.gatagories],
      };
    }
    default:
      return state;
  }
}

export async function getCatagory() {
  const config = {
    headers: { Authorization: `Bearer ${getToken()}` },
  };
  try {
    const response = await api.get("test-categories?_take=10000000", config);

    const data = response.data;

    store.dispatch({ type: types.GET_TEST_GATOGORY, payload: data });
  } catch (error) {
    if (error?.response?.status == 401) {
      logout();
    }
  }
}

export async function addCatagory(name, description) {
  const config = {
    headers: { Authorization: `Bearer ${getToken()}` },
  };
  try {
    const response = await api.post(
      "test-categories/create",
      {
        category_name: name,
        category_description: description,
      },
      config
    );

    const data = response.data;
    store.dispatch({ type: types.ADD_TEST_GATAGORY, payload: data });
    swal("Good job!", "test catagory was added successfully", "success");
  } catch (error) {
    await swal("Opps!", "Failed to add new test catagory", "error");
    if (error?.response?.status == 401) {
      logout();
    }
  }
}

export async function upDateCatagory(name, des, id) {
  const config = {
    headers: { Authorization: `Bearer ${getToken()}` },
  };
  try {
    const response = await api.put(
      "test-categories/update/" + id,
      {
        category_name: name,
        category_description: des,
      },
      config
    );

    const data = response.data;

    store.dispatch({ type: types.EDIT_TEST_GATOGORY, payload: data });
    swal("Good job!", "test catagory was updated successfully", "success");
  } catch (error) {
    if (error?.response?.status == 401) {
      logout();
    }
    store.dispatch({ type: types.FORCE_REFRSH });
    await swal("Opps!", "Failed to update test catagory", "error");
  }
}

export async function deleteCatagory(id) {
  const config = {
    headers: { Authorization: `Bearer ${getToken()}` },
  };
  try {
    const response = await api.delete("test-categories/delete/" + id, config);

    store.dispatch({
      type: types.DELETE_TEST_CATEGORY,
      payload: {
        id: id,
      },
    });
    swal("Good job!", "test category was deleted successfully", "success");
    window.location.reload();
  } catch (error) {
    if (error?.response?.status == 401) {
      logout();
    }

    await swal("Opps!", "Failed to delete test category", "error");
  }
}
