import * as types from "../actions/action_types";
import api from "../api";
import store from "../store";
import swal from "sweetalert";
import { getToken, logout } from "./loginReducer";
export default function expensesReducer(
  state = {
    expenses: [],
    isLoaded: false,
    alertOpen: false,
    total: 0,
    selectedExpense: null,
  },
  action
) {
  switch (action.type) {
    case types.GET_EXPENSES:
      return (state = {
        ...state,
        isLoaded: true,
        selectedExpense: null,
        ...action.payload,
      });
    case types.ADD_EXPENSE: {
      state.expenses.pop();
      return {
        ...state,
        isLoaded: true,
        alertOpen: false,
        total: parseInt(state.total) + 1,
        totalAmount:
          parseInt(state.totalAmount) + parseInt(action.payload.amount),
        selectedExpense: null,

        expenses: [action.payload, ...state.expenses],
      };
    }

    case types.OPEN_EXPENSE_ALERT: {
      return {
        ...state,
        isLoaded: true,
        alertOpen: true,
        selectedExpense: action.payload,
      };
    }
    case types.CLOSE_EXPENSE_ALERT: {
      return {
        ...state,
        isLoaded: true,
        alertOpen: false,
        selectedExpense: null,
      };
    }
    case types.EDIT_EXPENSES: {
      let perAmount = 0;
      var index = 0;
      [...state.expenses].forEach((value, i) => {
        if (value.id === action.payload.id) {
          index = i;
          perAmount=value.amount;
        }
      });
      state.expenses[index] = action.payload;

      return {
        ...state,
        isLoaded: true,
        alertOpen: false,
        totalAmount:
          parseInt(state.totalAmount) 
          +( parseInt(action.payload.amount)-perAmount),

        selectedExpense: null,
        expenses: [...state.expenses],
      };
    }
    default:
      return state;
  }
}
export async function getExpenses(page) {
  const config = {
    headers: { Authorization: `Bearer ${getToken()}` },
  };
  try {
    const response = await api.get("expenses?_take=10&_page=" + page, config);
    
    const data = response.data;

    store.dispatch({
      type: types.GET_EXPENSES,
      payload: {
        expenses: data,
        total: response.headers.total,
        totalAmount: response.headers["total-amount"],
      },
    });
  } catch (error) {
    {
      logout();
    }
  }
}
export async function addNewExpenses(reason, amount) {
  const config = {
    headers: { Authorization: `Bearer ${getToken()}` },
  };
  try {
    const response = await api.post(
      "expenses/create",
      {
        amount: amount,
        reason: reason,
        date: new Date().toISOString().split("T")[0],
      },
      config
    );

    const data = response.data;

    document.getElementById("expenseFormId").reset();
    document.getElementById("expenseFormId").value = "";
    document.getElementById("expenseFormId").text = "";

    store.dispatch({ type: types.ADD_EXPENSE, payload: data });
    swal("Good job!", "expense was added successfully", "success");
    return;
  } catch (error) {
    if (error?.response?.status == 401) {
      logout();
    }
    await swal("Opps!", "Failed to add expense", "error");
  }
  return;
}
export async function upDateExpense(reason, amount, id) {
  const config = {
    headers: { Authorization: `Bearer ${getToken()}` },
  };
  try {
    const response = await api.put(
      "expenses/update/" + id,
      {
        reason: reason,
        amount: amount,
        date: new Date().toISOString().split("T")[0],
      },
      config
    );

    const data = response.data;

    document.getElementById("expenseFormId").reset();
    document.getElementById("expenseFormId").value = "";
    document.getElementById("expenseFormId").text = "";

    store.dispatch({ type: types.EDIT_EXPENSES, payload: data });
    swal("Good job!", "expense was updated successfully", "success");
  } catch (error) {
    if (error?.response?.status == 401) {
      logout();
    }
    await swal("Opps!", "Failed to update expense", "error");
  }
}
const limit = 10;
