import React, { Component } from "react";
import "./printStyle.css";

class Page extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    let title = "";

    const isFromThree = (d) => {
      return (
        d.test.category.category_name.toString().toLowerCase() ===
          "Stool".toLowerCase() ||
        d.test.category.category_name.toString().toLowerCase() ===
          "Urine".toLowerCase() ||
        d.test.category.category_name.toString().toLowerCase() ===
          "Seminal Fluid".toLowerCase()
      );
    };
    const invoice = { ...this.props.invoice };
    const resultWithTest = [...this.props.resultWithTest];

    let perCat = resultWithTest[0]?.test?.category.category_name;
    let spreatedByIndex = Array();

    let index = 1;
    let rowsList = Array([], [], [], [], [], [], [], [], [], [], []);
    let indexxx = 0;
    this.props.resultWithTest.map((d, spIndex) => {
      spreatedByIndex.push(d.test.category.category_name);
      if (perCat === d.test.category.category_name) {
        rowsList[indexxx].push(d);
        //
      } else if (perCat === "") {
        perCat = d.test.category.category_name;
        rowsList[indexxx].push(d);
        //
      } else {
        perCat = d.test.category.category_name;

        if (isFromThree(d) != true) {
          indexxx++;
        }
        rowsList[indexxx].push(d);
        //
      }
    });
    let finalRes = Array();

    const uniq = [...new Set(spreatedByIndex)];

    rowsList.forEach((element, ind) => {
      if (element != [] && element.length != 0) {
        let name = "";

        let item = element.map((d, spIndex) => {
          name = d.test.category.category_name;
          return (
            <tr className="border_bottom" key={`spIndex${spIndex}`}>
              <td>{index++}</td>
              <td className="text-bold">{d.test?.name}</td>
              <td className="text-bold">
                {d?.result}{" "}
                <span className="p-0 pl-1 m-0 text-success ">
                  {d.test?.unit}
                </span>
              </td>
              <td>{d.test?.instrument}</td>
              <td>
                <p id="p_wrap">{d.test?.normal_range}</p>
              </td>
            </tr>
          );
        });
        finalRes.push(
          <div key={`ind${ind}`}>
            <span
              className="h3"
              className="text-white  p-2 mb-2 bg-danger d-inline-block w-50"
            >
              {" "}
              {uniq[ind]}
            </span>

            <table className="table table-bordered">
              <thead>
                <tr>
                  <th className="text-white p-0" style={{ width: "5%" }}>
                    <div className="bg-primary m-0 p-3"> # </div>
                  </th>
                  <th className="text-white p-0" style={{ width: "15%" }}>
                    <div className="bg-primary m-0 p-3">Test</div>
                  </th>
                  <th className="bg-primary p-0" style={{ width: "15%" }}>
                    <div className="bg-primary m-0 p-3">Result</div>
                  </th>
                  <th className="bg-primary p-0" style={{ width: "10%" }}>
                    <div className="bg-primary m-0 p-3">Device</div>
                  </th>
                  <th className="text-white p-0" style={{ width: "55%" }}>
                    <div className="bg-primary m-0 p-3">Normal Range</div>
                  </th>
                </tr>
              </thead>
              <tbody>{item}</tbody>
            </table>
          </div>
        );
      }
    });
    return (
      <div className="container">
        <div className="m-2 text-center">
          <h5>{finalRes}</h5>
        </div>
      </div>
    );
  }
}

export default Page;
