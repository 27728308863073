import "./TestStyle.css";
import React, { useEffect, useState } from "react";
import { getCatagory } from "../../../store/reducer/testGatagoryReducer";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import * as types from "../../../store/actions/action_types";
import {
  addTest,
  deleteTest,
  getTests,
} from "../../../store/reducer/testReducer";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import ReactPaginate from "react-paginate";

export default function ManageTest() {
  const { gatagories } = useSelector((state) => state.catagoryReducer);
  const { orginalTestList, testList, testAlertOpen } = useSelector(
    (state) => state.testReducer
  );
  const [searchs, setSearch] = useState({ query: "", type: -1 });
  let history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!gatagories || gatagories == "" || gatagories.length == 0) {
      getCatagory();
    }
  }, []);
  useEffect(() => {
    if (orginalTestList.length == 0) {
      getTests();
    }
  }, []);

  let listItems = Array();
  listItems.push(
    <option disabled={true} key={-1} value={-1}>
      Select Test Catagory
    </option>
  );
  gatagories.forEach((d) => {
    if (
      d.category_name !== "Stool" &&
      d.category_name !== "Urine" &&
      d.category_name !== "Seminal Fluid"
    ) {
      listItems.push(
        <option key={d.category_name} value={d.id}>
          {d.category_name}
        </option>
      );
    }
  });

  let searchItem = Array();
  searchItem.push(
    <option key={-1} value={-1}>
      All tests
    </option>
  );
  const [page, setPage] = useState(0);
  gatagories.forEach((d) => {
    if (
      d.category_name !== "Stool" &&
      d.category_name !== "Urine" &&
      d.category_name !== "Seminal Fluid"
    ) {
      searchItem.push(
        <option key={d.category_name} value={d.id}>
          {d.category_name}
        </option>
      );
    }
  });

  const handleEdit = (e) => {
    history.push("/editTest", e);
  };

  const openSAlert = (e) => {
    dispatch({
      type: types.OPEN_TEST_ALERT,
      payload: {
        e,
      },
    });
  };
  var index = 0;
  let testListItem = [];
  let total = 0;
  orginalTestList.forEach((d) => {
    if (
      d.category.category_name !== "Stool" &&
      d.category.category_name !== "Urine" &&
      d.category.category_name !== "Seminal Fluid"
    ) {
      if (
        d.name.toString().toUpperCase().includes(searchs.query.toUpperCase()) &&
        (searchs.type == -1 || d.category.id == searchs.type)
      ) {
        total = total + 1;
      }
    }
  });

  testList.forEach((d, pos) => {
    if (
      d.category.category_name !== "Stool" &&
      d.category.category_name !== "Urine" &&
      d.category.category_name !== "Seminal Fluid"
    ) {
      if (
        d.name.toString().toUpperCase().includes(searchs.query.toUpperCase()) &&
        (searchs.type == -1 || d.category.id == searchs.type)
      ) {
        if (index >= page * 10 && index < (page + 1) * 10) {
          testListItem.push(
            <tr key={d.id}>
              <td>{index + 1}</td>
              <td>{d.name}</td>
              <td>{d.price}</td>
              <td>{d.cost}</td>
              <td>{d.unit}</td>
              <td>{d?.category?.category_name}</td>
              <td>{d.instrument}</td>
              <td>
                <p id="p_wrap">{d.normal_range}</p>
              </td>
              <td>
                <button
                  className="bg-success btn-circle btn-sm"
                  onClick={(e) => {
                    handleEdit(d);
                  }}
                >
                  <i className="fas fa-pen"> </i>
                </button>
                <button
                  onClick={(e) => {
                    openSAlert(d);
                  }}
                  className="red btn-circle btn-sm"
                >
                  <i className="fas fa-trash white"> </i>
                </button>
              </td>
            </tr>
          );
        }
        index++;
      }
    }
  });

  const [nameErro, setNameError] = useState("");
  const [priceError, setPriceError] = useState("");
  const [costError, setCostError] = useState("");
  const [unitError, setUnitError] = useState("");
  const [instrumentError, setInstrumentError] = useState("");
  const [normalRangeError, setNormalRangeError] = useState("");
  const [catError, setCatError] = useState("");

  const handleSubmit = (evt) => {
    evt.preventDefault();
    onNameChange(name);
    onPriceChnage(price);
    onCostChnage(cost);
    onUnitChnage(unit);
    onInsturmentChange(instrument);
    onNormalRangeChnage(normalRange);
    if (catagoryId == null || catagoryId == "") {
      setCatError("please select test category");
    } else {
      setCatError("");
    }

    if (
      nameErro == "" &&
      name != "" &&
      cost != "" &&
      price != "" &&
      unit != "" &&
      priceError == "" &&
      costError == "" &&
      unitError == "" &&
      catagoryId !== null &&
      catagoryId !== undefined &&
      instrumentError === "" &&
      normalRangeError === ""
    ) {
      addTest(name, price, cost, unit, instrument, catagoryId, normalRange);
    }
  };

  const [name, setName] = useState("");
  const [price, setPrice] = useState("");

  const [cost, setCost] = useState("");

  const [unit, setUnit] = useState("");
  const [instrument, setInsturment] = useState("");
  const [catagoryId, steCatId] = useState("");
  const [normalRange, setNormalRange] = useState("");

  const addNewCatagory = (evt) => {
    history.push("/testCategory");
  };
  const handleTestTypeChnage = (evt) => {
    steCatId(evt.target.value);
  };
  const onNameChange = (evt) => {
    setName(evt);
    if (evt.length < 1) {
      setNameError("test name length must be more than 1");
    } else {
      setNameError("");
    }
  };
  const onPriceChnage = (evt) => {
    setPrice(evt);
    if (evt == "" || parseInt(evt) < 1) {
      setPriceError("test price must be more than 0 IQD");
    } else {
      setPriceError("");
    }
  };
  const onCostChnage = (evt) => {
    setCost(evt);
    if (evt == "" || parseInt(evt) < 1) {
      setCostError("test cost must be more than 0 IQD");
    } else {
      setCostError("");
    }
  };
  const onUnitChnage = (evt) => {
    setUnit(evt);
    if (evt.length < 1) {
      setUnitError("test unit must be more than zero");
    } else {
      setUnitError("");
    }
  };
  const onInsturmentChange = (evt) => {
    setInsturment(evt);
    if (evt.length < 1) {
      setInstrumentError(
        "test instrument must be more than more than one character"
      );
    } else {
      setInstrumentError("");
    }
  };
  const onNormalRangeChnage = (evt) => {
    setNormalRange(evt);
    if (evt.length < 2) {
      setNormalRangeError(
        "test normal range length must be more than 2 character"
      );
    } else {
      setNormalRangeError("");
    }
  };

  const handleTestTypeChnageFilter = (evt) => {
    setPage(0);
    setSearch({ ...searchs, type: evt.target.value });
  };
  const search = (evt) => {
    setPage(0);
    setSearch({ ...searchs, query: evt.target.value });
  };
  return (
    <div className="content-wrapper topForm">
      <section className="tableSection">
        <div className="row">
          <div className="col-lg-4 col-md-4 col-sm-12">
            <form className="form" id="testFormIdsss" onSubmit={handleSubmit}>
              <div className="form-group">
                <label htmlFor="testName" className="col-form-label">
                  Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="testName"
                  // defaultValue={name}
                  onChange={(event) => onNameChange(event.target.value)}
                  placeholder="Name"
                />
                <div style={{ fontSize: 12, color: "red" }}>{nameErro}</div>
              </div>
              <div className="form-group-lg">
                <label className=" col-form-label">Category</label>
                <div className="row">
                  <div className="col-10">
                    <div className="select2-purple input-group">
                      <select
                        className="form-control select2bs4 d-inline"
                        defaultValue={-1}
                        name="state"
                        onChange={handleTestTypeChnage}
                      >
                        {listItems}
                      </select>
                    </div>
                    <div style={{ fontSize: 12, color: "red" }}>{catError}</div>
                  </div>
                  <div className="col-2 center">
                    <button
                      className="bg-primary btn-circle btn-sm"
                      type={"button"}
                      onClick={(e) => {
                        addNewCatagory();
                      }}
                    >
                      <i className="fas fa-plus"> </i>
                    </button>
                  </div>
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="testUnit" className=" col-form-label">
                  Unit
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="testUnit"
                  // defaultValue={unit}
                  onChange={(event) => onUnitChnage(event.target.value)}
                  placeholder="Unit"
                />
                <div style={{ fontSize: 12, color: "red" }}>{unitError}</div>
              </div>
              <div className="form-group">
                <label htmlFor="testPrice" className=" col-form-label">
                  Price
                </label>
                <input
                  type="number"
                  className="form-control"
                  id="testPrice"
                  // defaultValue={price}
                  onChange={(event) => onPriceChnage(event.target.value)}
                  placeholder="Price"
                />
                <div style={{ fontSize: 12, color: "red" }}>{priceError}</div>
              </div>{" "}
              <div className="form-group">
                <label htmlFor="costPrice" className=" col-form-label">
                  Cost Price
                </label>
                <input
                  type="number"
                  className="form-control"
                  onChange={(event) => onCostChnage(event.target.value)}
                  id="costPrice"
                  placeholder="Cost Price"
                />
                <div style={{ fontSize: 12, color: "red" }}>{costError}</div>
              </div>
              <div className="form-group">
                <label htmlFor="testInstrument" className=" col-form-label">
                  Instrument
                </label>
                <input
                  type="text"
                  className="form-control"
                  // defaultValue={instrument}
                  onChange={(event) => onInsturmentChange(event.target.value)}
                  id="testInstrument"
                  placeholder="Instrument"
                />
                <div style={{ fontSize: 12, color: "red" }}>
                  {instrumentError}
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="testPrice" className=" col-form-label">
                  Normal Range
                </label>
                <textarea
                  type="textArea"
                  className="form-control"
                  onChange={(event) => onNormalRangeChnage(event.target.value)}
                  id="testRange"
                  placeholder="Normal Range"
                  rows="4"
                />
                <div style={{ fontSize: 12, color: "red" }}>
                  {normalRangeError}
                </div>
              </div>
              <div className="form-group">
                <button type="submit" className="btn btn-primary w-100">
                  <i className="fas fa-plus"> </i> Add
                </button>
              </div>
            </form>
          </div>
          <div className="col-lg-8 col-md-8 col-sm-12">
            {/*table*/}
            <div className="row m-3">
              <div className="col-sm-6">
                <div className="input-group md-form form-sm form-1 pl-0">
                  <input
                    className="form-control my-0 py-1"
                    type="search"
                    onChange={search}
                    placeholder="Search by Test"
                    aria-label="Search"
                  />
                  <div className="input-group-prepend">
                    <span
                      className="input-group-text purple lighten-3"
                      id="basic-text1"
                    >
                      <i className="fas fa-search" aria-hidden="true" />
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="select2-purple input-group">
                  <select
                    className="form-control select2bs4 d-inline"
                    name="state"
                    onChange={handleTestTypeChnageFilter}
                  >
                    {searchItem}
                  </select>
                </div>
              </div>
            </div>
            <table className="table table-bordered table-responsive dataTable rounded-top ">
              <thead className="rounded-top">
                <tr className="bg-gradient-cyan light rounded-top">
                  <th>#</th>
                  <th>Name</th>
                  <th>price</th>
                  <th>Cost</th>
                  <th>Unit</th>
                  <th>category</th>
                  <th>instrument</th>
                  <th className="tableHdResult">Normal range</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>{testListItem}</tbody>
            </table>
            <div className="center">
              <ReactPaginate
                previousLabel={"prev"}
                nextLabel={"next"}
                breakLabel={"..."}
                breakClassName={"break-me"}
                onPageChange={(ev) => {
                  setPage(ev.selected);
                  // getExpenses(ev.selected);
                }}
                pageNum={page}
                forcePage={page}
                pageCount={total / 10}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                containerClassName={"pagination"}
                subContainerClassName={"pages pagination"}
                activeClassName={"active"}
              />
            </div>
          </div>
        </div>
      </section>
      <Example show={testAlertOpen} />
    </div>
  );
}

function Example(props) {
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch({
      type: types.CLOSE_TEST_ALERT,
    });
  };
  const handleDelete = () => {
    deleteTest(selectedTest.e.id);
    dispatch({
      type: types.CLOSE_TEST_ALERT,
    });
  };

  const { selectedTest } = useSelector((state) => state.testReducer);

  var name = selectedTest?.e.name;

  return props.show == true ? (
    <>
      {" "}
      <Modal show={props.show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Test</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="form" onSubmit={handleDelete} id="doctorNameForm">
            <div className="row">Are you Sure delete " {name} " ?</div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            No
          </Button>
          <Button variant="primary" onClick={handleDelete}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  ) : (
    <div></div>
  );
}
