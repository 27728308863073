import * as types from "../actions/action_types";
import api from "../api";
import store from "../store";
import { getToken, logout } from "./loginReducer";

export default function reportReducer(
  state = {
    invoices: [],
    expenses: [],
    paybacks: [],
    profit: {},
    isLoaded: false,
  },
  action
) {
  switch (action.type) {
    case types.GET_REPORT:
      return (state = {
        ...state,
        isLoaded: true,
        invoices: action.payload.invoices,
        expenses: action.payload.expenses,
        paybacks: action.payload.paybacks,
      });

    case types.DELETE_REPORT: {
      return {
        ...state,
        isLoaded: false,
        invoices: [],
      };
    }
    case types.GET_PROFIT: {
      return {
        ...state,
        profit: action.payload,
      };
    }

    case types.REPORT_BY_DOCTOR: {
      return {
        ...state,
        byDoctor: action.payload,
      };
    }

    case types.REPORT_BY_TEST: {
      return {
        ...state,
        byTest: action.payload,
      };
    }

    default:
      return state;
  }
}

export async function getInvocies(from, to) {
  const config = {
    headers: { Authorization: `Bearer ${getToken()}` },
  };
  try {
    const response = await api.get(
      "reports/report?from=" + from + "&to=" + to,
      config
    );

    const data = response.data;

    store.dispatch({ type: types.GET_REPORT, payload: data });
  } catch (error) {
    if (error?.response?.status == 401) {
      logout();
    }
  }
}
export async function getProfit(from, to) {
  const config = {
    headers: { Authorization: `Bearer ${getToken()}` },
  };
  try {
    const response = await api.get(
      "reports/profit?from=" + from + "&to=" + to,
      config
    );

    const data = response.data;

    store.dispatch({ type: types.GET_PROFIT, payload: data });
  } catch (error) {
    if (error?.response?.status == 401) {
      logout();
    }
  }
}
export async function getByDoctorId(start, end, doctorId) {
  const config = {
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };

  try {
    const response = await api.get(
      "reports/doctor/" + doctorId + "?from=" + start + "&to=" + end,
      config
    );


    const data = response.data.invoices;
    let male = 0;
    let female = 0;
    let totalPrice = 0;
    data.forEach((e) => {

      totalPrice = parseInt(totalPrice) + (parseInt(e.total) -(parseInt(e.dicount_money)+parseInt(e.dicount_percent) / 100));
        //     -
        //  +
        //   (parseInt(e.total) - );
      if (e.patient.gender == "Male") {
        male = male + 1;
      } else if (e.patient.gender == "Female") {
        female = female + 1;
      }
    });


    store.dispatch({
      type: types.REPORT_BY_DOCTOR,
      payload: {
        male:male,
        female:female,
        number:data.length,
        totalPrice:totalPrice
      },
    });
  } catch (error) {

    if (error?.response?.status === 401) {
      logout();
    }
  }
}

export async function getByTest(start, end, testId) {
  const config = {
    headers: { Authorization: `Bearer ${getToken()}` },
  };

  try {
    const response = await api.get(
      "reports/test/" + testId + "?from=" + start + "&to=" + end,
      config
    );
    const data = response.data;
    store.dispatch({
      type: types.REPORT_BY_TEST,
      payload: data,
    });
  } catch (error) {

    if (error?.response?.status === 401) {
      logout();
    }
  }
}
