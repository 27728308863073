import React from 'react'
import {LOG_OUT} from '../../store/actions/action_types';

import {useDispatch} from "react-redux";
import {logout} from "../../store/reducer/loginReducer";

export default function Navbar() {
    const dispatch = useDispatch();
    const handleClick = () => {
        logout();
    }

    return (
        <div className='wrapper'>
            <nav className="main-header navbar navbar-expand navbar-dark  fixed-top">

                <ul className="navbar-nav">
                    <li className="nav-item">
                        <a className="nav-link" data-widget="pushmenu" role="button"><i
                            className="fas fa-bars"/></a>
                    </li>



                </ul>
                <div className="w-100">

                </div>
                <button className="btn nav-item btn-outline-light float-right" onClick={handleClick}>
                    logout
                </button>

            </nav>
        </div>

    )
}
