import React, { useEffect, useState } from "react";
import "./VisitStyle.css";
import { useDispatch, useSelector } from "react-redux";
import { getCatagory } from "../../../store/reducer/testGatagoryReducer";
import { getTests } from "../../../store/reducer/testReducer";
import { ADD_TEST_PATIENT } from "../../../store/actions/action_types";

const DefrentTest = (props) => {
  const { gatagories } = useSelector((state) => state.catagoryReducer);

  const { orginalTestList } = useSelector((state) => state.testReducer);
  const [state, tooggleState] = useState({
    Urine: false,
    Fluid: false,
    Stool: false,
  });

  useEffect(() => {
    

    if (orginalTestList.length === 0) {
      getTests();
    }
    if (!gatagories || gatagories == "" || gatagories.length == 0) {
      getCatagory();
    }
  }, []);
  const listItems = gatagories.map((d) => (
    <option key={d.category_name} value={d.id}>
      {d.category_name}
    </option>
  ));
  const { listOfTest } = useSelector((state) => state.patientReducer);

  const dispatch = useDispatch();
  let testListItem = [];
  gatagories.forEach((d, index) => {
    if (
      d.category_name === "Stool" ||
      d.category_name === "Urine" ||
      d.category_name === "Seminal Fluid"
    ) {
      testListItem.push(
        <button
          key={index}
          className={`badge bg-warning p-2 ml-1 text-white}`}
          style={
            state[state.category_name] === true
              ? { color: "red" }
              : { color: "green" }
          }
          onClick={(event) => {
            const array = orginalTestList;
            array.filter((e) => {
              if (d.category_name === "Stool") {
                tooggleState({ ...state, Stool: true });
              }
              if (d.category_name === "Urine") {
                tooggleState({ ...state, Urine: true });
              }
              if (d.category_name === "Seminal Fluid") {
                tooggleState({ ...state, Fluid: true });
              }

              let found = false;
              if (listOfTest != undefined && listOfTest != []) {
                listOfTest.forEach((tes) => {
                  if (tes.test.id == e.id) {
                    found = true;
                  }
                });
              }
              //
              if (e.category.id === d.id && found === false) {
                dispatch({
                  type: ADD_TEST_PATIENT,
                  payload: {
                    result: "0",
                    test: e,
                    index: Math.floor(Math.random() * 40567670),
                  },
                });
              }
            });
          }}
        >
          {d.category_name}
        </button>
      );
    }
  });

  return <div className="bagTest">{testListItem}</div>;
};
export default DefrentTest;
