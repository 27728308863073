import React, { useEffect, useState } from "react";
import "./VisitStyle.css";
import { useDispatch, useSelector } from "react-redux";
import { getCatagory } from "../../../store/reducer/testGatagoryReducer";
import { getTests } from "../../../store/reducer/testReducer";

const DefrentTestForEdit = (props) => {
  const { gatagories } = useSelector((state) => state.catagoryReducer);

  const { orginalTestList } = useSelector((state) => state.testReducer);
  const [state, tooggleState] = useState({
    Urine: false,
    Fluid: false,
    Stool: false,
  });

  useEffect(() => {
    
    if (orginalTestList.length === 0) {
      getTests();
    }
    if (gatagories.length == 0) {
      getCatagory();
    }
  }, []);
  const listItems = gatagories.map((d) => (
    <option key={d.category_name} value={d.id}>
      {d.category_name}
    </option>
  ));

  const dispatch = useDispatch();
  const testListItem = gatagories.map((d, index) => {
    return d.category_name === "Stool" ||
      d.category_name === "Urine" ||
      d.category_name === "Seminal Fluid" ? (
      <button
        key={d.id}
        className={`badge bg-warning p-2 ml-1 text-white}`}
        style={
          state[state.category_name] === true
            ? { color: "red" }
            : { color: "green" }
        }
        onClick={(event) => {
          //

          props.addNewTest(d);
        }}
      >
        {d.category_name}
      </button>
    ) : null;
  });

  return <div className="bagTest">{testListItem}</div>;
};
export default DefrentTestForEdit;
