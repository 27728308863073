
import axios from "axios";
const API_URL="https://yourlab.medicalit.info/app"
const api = axios.create({
  // baseURL: API_URL+ "/api/v1/",
  baseURL:"https://testlab.medicalit.digital/app/public/api/v1/",

  // baseURL:"https://yourlab.medicalitt.info/app2/public/api/v1/",
  //v  baseURL: " http://127.0.0.1:8000/",
  withCredentials: false,
  headers: { "Access-Control-Allow-Origin": "*" },

  credentials: "Access-Control-Allow-Credentials",
});

export default api;
