import * as types from "../actions/action_types";
import api from "../api";
import store from "../store";
import swal from "sweetalert";
import { getToken, logout } from "./loginReducer";
import { createBrowserHistory } from "history";

export default function userReducer(
  state = {
    users: [],
    isUserLoaded: false,
    isUserAlertOpend: false,
    selectedUser: null,
  },
  action
) {
  switch (action.type) {
    case types.GET_USERS:
      return (state = {
        ...state,
        isUserLoaded: true,
        selectedUser: null,
        isUserAlertOpend: false,
        users: action.payload,
      });
    case types.ADD_USER: {
      return {
        ...state,
        isUserLoaded: true,
        isUserAlertOpend: false,
        selectedUser: null,
        users: [action.payload, ...state.users],
      };
    }

    case types.OPEN_USER_ALERT: {
      return {
        ...state,
        isUserLoaded: true,
        isUserAlertOpend: true,
        selectedUser: action.payload,
      };
    }
    case types.CLOSE_USER_ALERT: {
      return {
        ...state,
        isUserLoaded: true,
        isUserAlertOpend: false,
        selectedUser: null,
      };
    }
    case types.FORCE_REFRSH: {
      return {
        ...state,

        isUserLoaded: true,
        isUserAlertOpend: false,
        selectedUser: null,
        users: [...state.users],
      };
    }

    case types.EDIT_USER: {
      var index = 0;
      [...state.users].forEach((value, i) => {
        if (value.id === action.payload.id) {
          index = i;
        }
      });
      state.users[index] = action.payload;

      return {
        ...state,
        isUserLoaded: true,
        isUserAlertOpend: false,
        selectedUser: null,
        users: [...state.users],
      };
    }
    case types.SEARCH_USER: {
      var temp = state.users.filter((test) =>
        test.name.toLowerCase().includes(action.payload.toLowerCase())
      );

      return {
        ...state,
        isUserLoaded: true,
        isUserAlertOpend: false,
        selectedUser: null,
        users: [...state.users],
      };
    }
    case types.FILTER_USER: {
       state.users.filter(
        (test) => test.category.id === action.payload.id
      );

      return {
        ...state,
        isUserLoaded: true,
        isUserAlertOpend: false,
        selectedUser: null,
        users: [...state.users],
      };
    }
    case types.DELETE_USER: {
      const newList = state.users.filter(
        (item) => item.id !== action.payload.id
      );

      return {
        ...state,
        isUserLoaded: true,
        isUserAlertOpend: false,
        selectedUser: null,
        users: [...newList],
      };
    }
    default:
      return state;
  }
}

export async function getUsers() {
  const config = {
    headers: { Authorization: `Bearer ${getToken()}` },
  };
  try {
    const response = await api.get("users?_take=10000000", config);
    const data = response.data;
    store.dispatch({ type: types.GET_USERS, payload: data });
  } catch (error) {
    if (error?.response?.status === 401) {
      logout();
    }
  }
}

export async function addUser(fullname, phone, username, password, role) {
  const config = {
    headers: { Authorization: `Bearer ${getToken()}` },
  };
  try {

    const response = await api.post(
      "users/create",
      {
        fullname: fullname,
        phone: phone,
        username: username,
        password: password,
        user_type: role,
      },
      config
    );

    const data = response.data;

    document.getElementById("createUserFormId").reset();
    createBrowserHistory
    ().back();
    store.dispatch({ type: types.ADD_USER, payload: data });
    await swal("Good job!", "user was added successfully", "success");
  } catch (error) {
    if (error?.response?.status == 401) {
      logout();
    }else if (error?.response?.status === 422) {
      const errors = JSON.parse(JSON.stringify(error?.response.data.errors));

      let str = "";
      Object.keys(errors).forEach(function (key) {
        str = str + errors[key][0] + "\n";
      });
      await swal("Opps!", "Failed to add user\n" + str, "error");
      return;
    }
  else{  await swal("Opps!", "Failed to add user", "error");}
  }
}

export async function upDateUser(
  state,
  id
) {
  const config = {
    headers: { Authorization: `Bearer ${getToken()}` },
  };

  try {
    const response = await api.put(
      "users/update/" + id,
     state,
      config
    );

    const data = response.data;

    store.dispatch({ type: types.EDIT_USER, payload: data });
    swal("Good job!", "user was updated successfully", "success");createBrowserHistory().back();
  } catch (error) {
    if (error?.response?.status == 401) {
      logout();
    }else if (error?.response?.status === 422) {
      const errors = JSON.parse(JSON.stringify(error?.response.data.errors));
   
      let str = "";
      Object.keys(errors).forEach(function (key) {
      
        str = str + errors[key][0] + "\n";
      });
      await swal("Opps!", "Failed to edit user\n" + str, "error");
      return;
    }
  else{  await swal("Opps!", "Failed to update user", "error");}
  }
}

export async function deleteUser(id) {
  const config = {
    headers: { Authorization: `Bearer ${getToken()}` },
  };
  try {
    const response = await api.delete(
      "users/delete/" + id,

      config
    );

    // const data = response.data;

    store.dispatch({ type: types.DELETE_USER, payload: { id: id } });
    swal("Good job!", "user was deleted successfully", "success");
  } catch (error) {
    if (error?.response?.status == 401) {
      logout();
    }
    await swal("Opps!", "Failed to delete user", "error");
  }
}
