import React, { useEffect } from "react";
import "./VisitStyle.css";
import { useDispatch, useSelector } from "react-redux";
import { getTests } from "../../../store/reducer/testReducer";
import {
  DELETE_TEST_PATIENT,
  EDIT_TEST_PATIENT,
} from "../../../store/actions/action_types";

const ListTest = (props) => {
  const dispatch = useDispatch();

  const { orginalTestList } = useSelector((state) => state.testReducer);
  useEffect(() => {
    if (orginalTestList.length == 0) {
      getTests();
    }
  }, []);
  let listItems = [];
  orginalTestList.map((d) => {
    listItems.push({ value: `${d.id}`, label: d.name });
  });

  //
  return (
    <div className="wrapper" key={props.state.index}>
      <div className="row">
        <div className="col-lg-1 col-md-1 col-2 center">
          <div className="cirlceDiv">
            <span>{props.index}</span>
          </div>
        </div>
        <div className="col-lg-10 col-md-10 col-7">
          <div className="card">
            <div className="card-body row">
              <div className="col-lg-4 col-md-4 col-12 pt-2">
                <input
                  type="text"
                  className="form-control"
                  id="inputName"
                  placeholder="Test Name"
                  value={props.state.test.name}
                  disabled={true}
                />
              </div>
              <div className="col-lg-4 col-md-4 col-12 pt-2 ">
                <div className="row">
                  <div className="col-8">
                    <input
                      className="form-control"
                      id="inputName"
                      placeholder="Result"
                      min={"0"}
                      value={props.state.result}
                      onChange={(e) => {
                        dispatch({
                          type: EDIT_TEST_PATIENT,
                          payload: {
                            ...props.state,
                            index: props.state.test.id,
                            result: e.target.value,
                          },
                        });
                      }}
                    />
                  </div>
                  <div className="col-4">
                    <span>{props.state.test?.unit}</span>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-4 col-12 pt-2">
                <input
                  type="text"
                  className="form-control"
                  id="inputName"
                  placeholder="Price"
                  value={props.showPrice === true ? props.state.test?.price : 0}
                  disabled={true}
                />
              </div>
            </div>
          </div>
        </div>
        <div
          onClick={(e) => {
            //
            dispatch({
              type: DELETE_TEST_PATIENT,
              payload: { index: props.state.test.id },
            });
          }}
          className="col-lg-1 col-md-1 col-2 center"
        >
          <div
            className="cirlceDiv"
            style={{
              backgroundColor: "#f05252",
            }}
          >
            <i className="fas fa-minus"></i>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListTest;