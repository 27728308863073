import React, {useEffect} from "react";
import "./adminStyle.css";
import {getByDoctorId} from "../../../store/reducer/reportReducer";
import {useSelector} from "react-redux";
import {createBrowserHistory} from "history";

const ReportByDoctor = (props) => {
    useEffect(() => {
        let data = props.location.state;
        getByDoctorId(data.from, data.to, data.id);
    }, []);
    const {user} = useSelector((state) => state.loginReducer, []);
    if (user.user.user_info.user_type !== "lab-admin") {
        createBrowserHistory().push("/");
        window.location.reload();
    }
    const {byDoctor} = useSelector(
        (state) => state.reportReducer
    );
    return (
        <div className="content-wrapper topForm bg-white">
            <section className="tableSection">
                <div className="card">
                    <div className="card-header bg-info w-100">Report By Doctor</div>
                    <div className="card-body">
                        <table className="table table-bordered">
                        
                            <tbody>
                            <tr>
                                <td>
                                    <h5 className="d-inline">invoices Number</h5>
                                </td>
                                <td>
                    <span className="badge badge-info   p-3 ">
                      {" "}
                        <h5 className="d-inline"> {byDoctor?.number}</h5>{" "}
                    </span>
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    <h5 className="d-inline">Male</h5>
                                </td>
                                <td>
                    <span className="badge badge-info   p-3 ">
                      {" "}
                        <h5 className="d-inline"> {byDoctor?.male}</h5>{" "}
                    </span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <h5 className="d-inline">Female</h5>
                                </td>
                                <td>
                    <span className="badge badge-info   p-3 ">
                      {" "}
                        <h5 className="d-inline"> {byDoctor?.female}</h5>{" "}
                    </span>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <h5 className="d-inline">total</h5>
                                </td>
                                <td>
                    <span className="badge badge-info   p-3 ">
                      {" "}
                        <h5 className="d-inline"> {byDoctor?.totalPrice}</h5>{" "}
                    </span>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </section>
        </div>);
};

export default ReportByDoctor;
