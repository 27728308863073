import React from "react";
import "./VisitStyle.css";

const TestRow = (props) => {


  return (
    <div className="wrapper" key={props.state?.index}>
      <div className="row">
        <div className="col-lg-1 col-md-1 col-2 center">
          <div className="cirlceDiv">
            <span>{props?.index + 1}</span>
          </div>
        </div>
        <div className="col-lg-10 col-md-10 col-7">
          <div className="card">
            <div className="card-body row">
              <div className="col-lg-4 col-md-4 col-12 pt-2">
                <div className="col-lg-12 col-md-12 col-6 pt-2">
                  <input
                    type="text"
                    className="form-control"
                    id="inputName"
                    placeholder="Test"
                    value={props.state?.test?.name}
                    disabled={true}
                  />
                </div>
              </div>
              <div className="col-lg-4 col-md-4 col-12 pt-2 row">
                <div className="col-7">
                  <input
                    type="text"
                    className="form-control"
                    id="inputName"
                    placeholder="Result"
                    value={props.state?.result}
                    onChange={(e) => {
                      props.handleChangeResult(props.state?.test.id, e.target?.value);
                    }}
                  />
                </div>
                <div className="col-5 center">
                  <span>{props.state?.unit}</span>
                </div>
              </div>

              <div className="col-lg-4 col-md-4 col-12 pt-2">
                <input
                  type="text"
                  className="form-control"
                  id="inputName"
                  placeholder="Price"
                  value={props.showPrice === true ? props.state?.test?.price : 0}
                  disabled={true}
                />
              </div>
            </div>
          </div>
        </div>
        <div
          onClick={(e) => {
            props.deleteTest(props?.index);
          }}
          className="col-lg-1 col-md-1 col-2 center"
        >
          <div
            className="cirlceDiv"
            style={{
              backgroundColor: "#f05252",
            }}
          >
            <i className="fas fa-minus"></i>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestRow;
