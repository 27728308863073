import "./doctorStyle.css";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

import {
  addNewDoctors,
  EditDoctors,
  getDoctors,
} from "../../../store/reducer/doctorsReducer";
import {
  CLOSE_DOCTOR_ALERT,
  OPEN_DOCTOR_ALERT,
} from "../../../store/actions/action_types";

export default function DoctorScreen() {
  const dispatch = useDispatch();
  const { doctors, isLoaded, alertOpen, selectedDoc } = useSelector(
    (state) => state.doctorsReducer
  );

  useEffect(() => {
    if (!doctors || doctors == "" || doctors.length == 0) {
      getDoctors();
    }
  }, []);
  let index = 1;

  const handleClick = (e) => {
    dispatch({
      type: OPEN_DOCTOR_ALERT,
      payload: {
        e,
      },
    });
  };
  const listItems = doctors.map((d) => (
    <tr key={index}>
      <td>{index++}</td>
      <td>{d.name}</td>

      <td>
        <button
          type="button"
          className="btn btn-primary px-3"
          data-toggle="modal"
          onClick={() => handleClick(d)}
          data-target="#basicExampleModal"
        >
          <i className="far fa-edit" aria-hidden="true"></i>
        </button>
      </td>
    </tr>
  ));
  const handleSubmit = (evt) => {
    evt.preventDefault();
    if (nameError == "") {
      addNewDoctors(name);
    }
  };
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState("");

  function handleEmail(e) {
    setName(e.target.value);
    if (e.target.value.length < 6) {
      setNameError("lenth must be more than 6 letters");
    } else {
      setNameError("");
    }
  }

  return (
    <div className="content-wrapper topSpace">
      <section className="tableSection">
        <form className="form" onSubmit={handleSubmit} id="doctorNameForm">
          <div className="row">
            <div className="col-10">
              <input
                type="text"
                onChange={handleEmail}
                className="form-control"
                id="doctorName"
                placeholder="Doctor name"
              />
              <div style={{ fontSize: 12, color: "red" }}>{nameError}</div>
            </div>
            <div className="col-2">
              <button type="submit" className="btn btn-primary w-100">
                <i className="fas fa-plus"></i> Add
              </button>
            </div>
          </div>
        </form>
        {isLoaded === false ? (
          <div>loading</div>
        ) : !doctors ? (
          <table className="table table-bordered table-responsive dataTable rounded-top mt-4">
            <thead className="rounded-top">
              <tr className="bg-gradient-cyan light rounded-top">
                <th>#</th>
                <th className="w-100">Name</th>
                <th>Edit</th>
              </tr>
            </thead>
            <tbody>{listItems}</tbody>
          </table>
        ) : (
          <table className="table table-bordered table-responsive dataTable rounded-top mt-4">
            <thead className="rounded-top">
              <tr className="bg-gradient-cyan light rounded-top">
                <th>#</th>
                <th className="w-100">Name</th>
                <th>Edit</th>
              </tr>
            </thead>
            <tbody>{listItems}</tbody>
          </table>
        )}
      </section>
      <Example show={alertOpen} />
    </div>
  );
}

function Example(props) {
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch({
      type: CLOSE_DOCTOR_ALERT,
    });
  };
  const handlUpdate = () => {
    if (nameError == "") {
      EditDoctors(name, selectedDoc.e.id);
      dispatch({
        type: CLOSE_DOCTOR_ALERT,
      });
    }
  };

  const { selectedDoc } = useSelector((state) => state.doctorsReducer);

  const [name, setName] = useState(selectedDoc?.e.name);
  const [nameError, setNameError] = useState("");
  function handleName(e) {
    setName(e.target.value);
    if (e.target.value.length < 6) {
      setNameError("name must be more than 6 character");
    } else {
      setNameError("");
    }
  }

  return props.show == true ? (
    <>
      {" "}
      <Modal show={props.show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Doctor name</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="form" onSubmit={handlUpdate} id="doctorNameForm">
            <div className="row">
              <div className="col-10">
                <input
                  type="text"
                  defaultValue={selectedDoc.e.name}
                  onChange={handleName}
                  className="form-control"
                  id="doctorName"
                  placeholder="Doctor name"
                />
              </div>
              <div style={{ fontSize: 12, color: "red" }}>{nameError}</div>
              <div className="col-2"></div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handlUpdate}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  ) : (
    <div></div>
  );
}
