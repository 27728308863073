import React, { useEffect, useState } from "react";
import "../visitation/VisitStyle.css";
import { getDoctors } from "../../../store/reducer/doctorsReducer";
import { useSelector } from "react-redux";
import TestRow from "./TestRow";
import useForceUpdate from "antd/es/_util/hooks/useForceUpdate";
import { upDateInvoice } from "../../../store/reducer/invoicesReducer";
import AddTestRowForEditInvoice from "./AddTestRowForEditInvoice";
import DefrentTestForEdit from "./DefrentTestForEdit";
import swal from "sweetalert";

const EditVisit = (props) => {
  let data = props.location.state;

  const [nameError, setNameError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [discount_precen, setPrecentage] = useState(data.dicount_percent);
  const [discount, setDiscount] = useState(data.dicount_money);
  const [total, setTotal] = useState(data.total);
  const [final, setFinal] = useState(
    data.total -
      (data.dicount_money + data.total * (data.dicount_percent / 100))
  );

  const [tests, setTests] = useState([]);

  const [doctorId, setDoctorId] = useState(data.doctor.id);
  const { doctors } = useSelector((state) => state.doctorsReducer);
  const forceUpdate = useForceUpdate();
  const { orginalTestList } = useSelector((state) => state.testReducer);
  let today = new Date();
  let dd = String(today.getDate()).padStart(2, "0");
  let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  let yyyy = today.getFullYear();

  today = yyyy + "-" + mm + "-" + dd;
  useEffect(() => {
    getAge(data.patient.birthdate);
    handlePrices();
    if (!doctors || doctors == "" || doctors.length == 0) {
      getDoctors();
    }
    const resultWithTest = data.tests;
    let urine = [];
    let tempTests = [];
    resultWithTest.forEach((element) => {
      if (element.test.category.id == 4) {
        urine.push(element);
      } else {
        tempTests.push(element);
      }
    });
    urine.sort((a, b) =>
      a.test.order_no > b.test.order_no
        ? 1
        : b.test.order_no > a.test.order_no
        ? -1
        : 0
    );
    setTests([...tempTests, ...urine]);
  }, []);
  const doctorsList = doctors.map((d) => (
    <option key={d.id} value={d.id}>
      {d.name}
    </option>
  ));
  const handleDiscount = (e) => {
    setDiscount(e);
    setPrecentage(0);

    handlePrices(tests, e, 0);
  };
  const handleDiscountPrecenate = (e) => {
    setDiscount(0);
    setPrecentage(e);
    let dis = e;
    if (parseInt(e) > 100) {
      setPrecentage(100);
      dis = 100;
    }
    handlePrices(tests, 0, dis);
  };

  const addNewTestWithResult = (test, result) => {
    let temps = [{ test: test, result: result }, ...tests];

    let urine = [];
    let tempTests = [];
    temps.forEach((element) => {
      if (element.test.category.id == 4) {
        urine.push(element);
      } else {
        tempTests.push(element);
      }
    });
    urine.sort((a, b) =>
      a.test.order_no > b.test.order_no
        ? 1
        : b.test.order_no > a.test.order_no
        ? -1
        : 0
    );

    setTests([...tempTests, ...urine]);
    handlePrices([...tempTests, ...urine]);
  };
  const [disable, setDisable] = useState(false);

  const addNewTest = (testTemp) => {
    let newtest = Array();
    const reveredTest = orginalTestList;
    // reveredTest.reverse();
    reveredTest.forEach((e) => {
      //
      let found = false;
      tests.forEach((et) => {
        if (e.id === et.test.id) {
          found = true;
        }
      });
      if (found == false) {
        if (testTemp.category_name == e.category.category_name) {
          newtest.push({ test: e, result: "0" });
        }
      }
    });
    let temps = [...tests, ...newtest];
    // temps.sort((a, b) =>
    //   a.test.id > b.test.id ? 1 : b.test.id > a.test.id ? -1 : 0
    // );

    let urine = [];
    let tempTests = [];
    temps.forEach((element) => {
      //
      if (element.test.category.id == 4) {
        urine.push(element);
      } else {
        tempTests.push(element);
      }
    });
    urine.sort((a, b) =>
      a.test.order_no > b.test.order_no
        ? 1
        : b.test.order_no > a.test.order_no
        ? -1
        : 0
    );

    setTests([...tempTests, ...urine]);
    handlePrices([...tempTests, ...urine]);
  };
  let [name, setName] = useState(data.patient.fullname);
  let [phone, setPhone] = useState(data.patient.phone);
  let [dateOfBirth, setBirth] = useState(data.patient.birthdate);
  let [gender, setGender] = useState(data.patient.gender);
  let [age, setAge] = useState();

  function getAge(dateString) {
    var today = new Date();
    var DOB = new Date(dateString);
    var totalMonths =
      (today.getFullYear() - DOB.getFullYear()) * 12 +
      today.getMonth() -
      DOB.getMonth();
    totalMonths += today.getDay() < DOB.getDay() ? -1 : 0;
    var years = today.getFullYear() - DOB.getFullYear();
    if (DOB.getMonth() > today.getMonth()) years = years - 1;
    else if (DOB.getMonth() === today.getMonth())
      if (DOB.getDate() > today.getDate()) years = years - 1;

    var days;
    var months;

    if (DOB.getDate() > today.getDate()) {
      months = totalMonths % 12;
      if (months == 0) months = 11;
      var x = today.getMonth();
      switch (x) {
        case 1:
        case 3:
        case 5:
        case 7:
        case 8:
        case 10:
        case 12: {
          var a = DOB.getDate() - today.getDate();
          days = 31 - a;
          break;
        }
        default: {
          var a = DOB.getDate() - today.getDate();
          days = 30 - a;
          break;
        }
      }
    } else {
      days = today.getDate() - DOB.getDate();
      if (DOB.getMonth() === today.getMonth()) months = totalMonths % 12;
      else months = (totalMonths % 12) + 1;
    }
    if (months < 0) {
      months = 0;
    }
    if (years < 0) {
      years = 0;
    }
    if (days < 0) {
      days = 0;
    }
    if (Number.isNaN(months)) {
      months = 0;
    }
    if (Number.isNaN(years)) {
      years = 0;
    }
    if (Number.isNaN(days)) {
      days = 0;
    }
    var age = years + " years , " + months + " ,  months " + days + " days";
   
    setAge(age);
  }

  const deleteTest = (index) => {
    let trmp = [...tests];

    trmp.splice(index, 1);

    setTests(trmp);
    //
    handlePrices(trmp);
  };
  const handleChangeTest = (testIndex, newTestId) => {
    let newTest;

    orginalTestList.forEach((value) => {
      if (value.id === parseInt(newTestId)) {
        newTest = value;
      }
    });

    let trmp = [...tests];
    trmp[testIndex] = newTest;

    setTests(trmp);
    forceUpdate();
  };
  const handleChangeResult = (test_id, newResult) => {
    let temp = [...tests];

    temp.forEach((element) => {
      if (element.test.id == test_id) {
        element.result = newResult;
      }
    });

    setTests(temp);

    forceUpdate();
  };

  let listItems = Array();
  let urineFound = true;
  let stoolFound = true;
  let fluifFound = true;
  tests.map((test, index) => {
    if (test.test.category.category_name === "Urine") {
      listItems.push(
        <TestRow
          key={index}
          state={test}
          index={index}
          deleteTest={deleteTest}
          showPrice={urineFound}
          handleChangeResult={handleChangeResult}
        />
      );
      urineFound = false;
    } else if (test.test.category.category_name === "Stool") {
      listItems.push(
        <TestRow
          key={index}
          state={test}
          index={index}
          deleteTest={deleteTest}
          showPrice={stoolFound}
          handleChangeResult={handleChangeResult}
        />
      );
      stoolFound = false;
    } else if (test?.test?.category?.category_name === "Seminal Fluid") {
      listItems.push(
        <TestRow
          key={index}
          state={test}
          index={index}
          deleteTest={deleteTest}
          showPrice={fluifFound}
          handleChangeResult={handleChangeResult}
        />
      );
      fluifFound = false;
    } else {
      listItems.push(
        <TestRow
          key={index}
          state={test}
          index={index}
          deleteTest={deleteTest}
          showPrice={true}
          handleChangeResult={handleChangeResult}
        />
      );
    }
  });

  const handlePrices = (teststs, dis, dis_pres) => {
    if (teststs === undefined) {
      teststs = tests;
    }
    if (dis === undefined) {
      dis = discount;
    }
    if (dis_pres === undefined) {
      dis_pres = discount_precen;
    }

    if (dis === undefined || dis === "") {
      dis = 0;
    }
    if (dis_pres === undefined || dis_pres === "") {
      dis_pres = 0;
    }
    let totalParam = 0;
    let urineFound = 0;
    let stoolfound = 0;
    let fluidfound = 0;
    //
    teststs.forEach((value, i) => {
      if (value?.test?.category?.category_name === "Urine") {
        urineFound = parseInt(value.test?.price);
      } else if (value?.test?.category?.category_name === "Stool") {
        stoolfound = parseInt(value.test?.price);
      } else if (value?.test?.category?.category_name === "Seminal Fluid") {
        fluidfound = parseInt(value.test?.price);
      } else {
        totalParam = totalParam + parseInt(value.test?.price);
      }
    });
    totalParam = totalParam + urineFound + stoolfound + fluidfound;

    let finalParam =
      parseInt(totalParam) -
      (parseInt(dis) + parseInt(totalParam) * (parseInt(dis_pres) / 100));
    if (finalParam < 0) {
      finalParam = 0;
    }
    if (totalParam % 250 < 125) {
      setTotal(totalParam - (totalParam % 250));
    } else {
      setTotal(totalParam + (250 - (totalParam % 250)));
    }
    if (finalParam % 250 < 125) {
      setFinal(finalParam - (finalParam % 250));
    } else {
      setFinal(finalParam + (250 - (finalParam % 250)));
    }

    //
  };

  const handleSave = async () => {
    let testss = Array();
    let result = Array();

    tests.forEach((e, index) => {
      if (e != null && e != undefined) {
        testss.push(e.test.id);
        result.push(
          e.result == null || e.result == undefined || e.result == ""
            ? "0"
            : e.result
        );
      }
    });
    if (testss.length == 0) {
      swal("Opps!", "Failed save, no test was selected", "error");

      return;
    }
    let currentDiscount =
      discount == "" || discount == undefined ? 0 : discount;
    if (discount > total) {
      currentDiscount = total;
    }

    setDisable(true);
    await upDateInvoice(
      {
        patient_id: data.patient.id,
        total: total,
        dicount_percent:
          discount_precen == "" || discount_precen == undefined
            ? 0
            : discount_precen,
        dicount_money: currentDiscount,
        doctor_id: doctorId,
        tests: testss,
        results: result,
      },
      data.id,

      {
        fullname: name,
        phone: phone,
        birthdate: dateOfBirth,
        gender: gender,
        blood_type: "A+",
      },
      data.patient.id
    );
    setDisable(false);
  };
  return (
    <div className="content-wrapper mainClassInvoice">
      <div className="card card-info topForm">
        <div className="card-header">
          <div className="row">
            <div className="col">
              <h3 className="card-title">Patient Information </h3>
            </div>
            <div className="col">
              <h3 className="card-title">Invoice # {data.id}</h3>
            </div>
          </div>
        </div>
        {/* /.card-header */}
        {/* form start */}
        <form className="form-horizontal sartUserForm">
          <div className="card-body">
            <div className="row">
              <div className="col-lg-6">
                <div className="form-group row">
                  <label
                    htmlFor="inputName"
                    className="col-sm-2 col-form-label"
                  >
                    Name
                  </label>
                  <div className="col-sm-10">
                    <input
                      value={name}
                      onChange={(event) => {
                        if (
                          event.target.value == "" ||
                          event.target.value == undefined ||
                          event.target.value.length < 4
                        ) {
                          setNameError("name must be more than 4 letters");
                        } else {
                          setNameError("");
                        }
                        setName(event.target.value);
                      }}
                      type="text"
                      className="form-control"
                      id="inputName"
                      placeholder="Name"
                    />
                  </div>
                  <div style={{ fontSize: 12, color: "red" }}>{nameError}</div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group row">
                  <label
                    htmlFor="inputPhone"
                    className="col-sm-2 col-form-label"
                  >
                    Phone
                  </label>
                  <div className="col-sm-10">
                    <input
                      pattern="[0-9]*"
                      value={phone}
                      onChange={(event) => {
                        if (
                          event.target.value == "" ||
                          event.target.value == undefined ||
                          event.target.value.length != 11
                        ) {
                          setPhoneError("phone number must be 11 digits");
                        } else {
                          setPhoneError("");
                        }
                        setPhone(event.target.value);
                      }}
                      type="text"
                      className="form-control"
                      id="inputPhone"
                      placeholder="Phone number"
                    />
                  </div>
                  <div style={{ fontSize: 12, color: "red" }}>{phoneError}</div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-6">
                <div className="form-group row">
                  <label
                    htmlFor="inputbirthday"
                    className="col-sm-2 col-form-label"
                  >
                    Birthday
                  </label>

                  <div
                    className="input-group date col-sm-10"
                    id="inputbirthday"
                    data-target-input="nearest"
                  >
                    <div
                      className="input-group-append"
                      data-target="#reservationdate"
                      data-toggle="datetimepicker"
                    >
                      <div className="input-group-text">
                        <i className="fa fa-calendar" />
                      </div>
                    </div>

                    <input
                      type="date"
                      name="bday"
                      value={dateOfBirth}
                      onChange={(e) => {
                        setBirth(e.target.value);
                        getAge(e.target.value);
                        setBirth(e.target.value);
                      }}
                      name="bday"
                      max={today}
                      min="1900-01-01"
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group row">
                  <label className="col-sm-2 col-form-label">Age</label>

                  <div className="col-sm-9 text-center form-control ml-3">
                    <span className="text ml-1 pt-2">{age}</span>
                  </div>
                </div>
              </div>

              <div className="col-lg-6">
                <div className="form-group row">
                  <label
                    htmlFor="inputGender"
                    className="col-sm-2 col-form-label"
                  >
                    Gender
                  </label>
                  <div className="col-sm-10">
                    <select
                      value={gender}
                      onChange={(event) => {
                        setGender(event.target.value);
                      }}
                      className="form-control"
                      id="inputGender"
                    >
                      <option>Male</option>
                      <option>Female</option>
                      <option>child</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* /.card-body */}
        </form>
      </div>

      <section className="content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="card card-info">
                <button className="card-header ">
                  <h3 className="card-title white">Add Tests</h3>
                </button>

                <div className="card-body">
                  {/* start  price and discount */}
                  <div className="row">
                    <div className="col-md-3 col-sm-6">
                      <div className="form-group">
                        <label>Total</label>
                        <input
                          type="text"
                          disabled={true}
                          value={total}
                          className="form-control"
                          placeholder="Total"
                        />
                      </div>
                    </div>

                    <div className="col-md-3 col-sm-6">
                      <div className="form-group">
                        <label>Discount</label>
                        <input
                          type="number"
                          onChange={(event) => {
                            handleDiscount(event.target.value);
                          }}
                          value={discount}
                          className="form-control"
                          placeholder="Discount"
                        />
                      </div>
                    </div>
                    <div className="col-md-3 col-sm-6">
                      <div className="form-group">
                        <label>Discount %</label>
                        <input
                          type="number"
                          value={discount_precen}
                          onChange={(event) => {
                            handleDiscountPrecenate(event.target.value);
                          }}
                          className="form-control"
                          placeholder="Discount %"
                        />
                      </div>
                    </div>
                    <div className="col-md-3 col-sm-6">
                      <div className="form-group">
                        <label>Final Price</label>
                        <div className="badge badge-warning">
                          <input
                            type="number"
                            disabled={true}
                            value={final}
                            className="form-control"
                            placeholder="Final Price"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3 col-sm-6">
                      <div className="form-group">
                        <label>Referred by</label>
                        <div className="select2-purple">
                          <select
                            value={doctorId}
                            onChange={(event) => {
                              setDoctorId(event.target.value);
                            }}
                            className="form-control select2bs4"
                            name="state"
                          >
                            {doctorsList}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* end price and discount */}
                  <hr></hr>
                  <DefrentTestForEdit
                    addNewTest={addNewTest}
                    listOfTest={tests}
                  />
                  <hr></hr>
                  <AddTestRowForEditInvoice
                    addNewTestWithResult={addNewTestWithResult}
                    listOfTest={tests}
                  />
                  <section className="content tableSection">
                    <div className="card card-info">
                      <div className="card-body ">
                        <br />
                        {listItems}
                      </div>
                    </div>
                  </section>

                  <div className="bottomSubmet">
                    <button
                      disabled={disable}
                      onClick={(event) => {
                        handleSave();
                      }}
                      className="btn btn-success btn-block"
                    >
                      <i className="fas fa-check"></i> Save
                      <i className="nav-icon fas fa-flask" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default EditVisit;
