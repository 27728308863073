import React from "react";
import "./adminStyle.css";
import Purchases from "./supplierComponent/purchases";
import Payback from "./supplierComponent/Payback";
import { useSelector } from "react-redux";
import { createBrowserHistory } from "history";

const EachSupplier = (props) => {
  const { purchases, paybacks } = useSelector(
    (state) => state.purchasePayBackRudycer
  );
  let totalPurchase = 0.0;
  let totalBuyPack = 0.0;
  purchases.map((e) => {
    totalPurchase = totalPurchase + parseInt(e.amount);
  });
  paybacks.map((e) => {
    totalBuyPack = totalBuyPack + parseInt(e.amount);
  });
  const { user } = useSelector((state) => state.loginReducer, []);
   
  if (user.user.user_info.user_type !== "lab-admin") {
    createBrowserHistory().push("/lastInvoice");
    window.location.reload();
  }
  return (
    <div className="content-wrapper topSpace mb-5">
      <section className="tableSection">
        <div className="card card-outline card-info p-3">
          <div className="card-header text-center row justify-content-between">
            <p className="text-danger">
              {" "}
              Supplier Name: {props.location.state.name}
            </p>
            <p className="text-info">Purchases: {totalPurchase}</p>
            <p className="text-blue">Paybacks:{totalBuyPack}</p>
            <p className="green">Current Debt:{totalPurchase - totalBuyPack}</p>
          </div>
          <div className="card-body">
            <ul className="nav nav-tabs" id="myTab" role="tablist">
              <li className="nav-item">
                <a
                  className="nav-link active"
                  id="home-tab"
                  data-toggle="tab"
                  href="#home"
                  onClick={() => {}}
                  role="tab"
                  aria-controls="home"
                  aria-selected="true"
                >
                  purchases
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  id="profile-tab"
                  data-toggle="tab"
                  href="#profile"
                  role="tab"
                  onClick={() => {}}
                  aria-controls="profile"
                  aria-selected="false"
                >
                  paybacks
                </a>
              </li>
            </ul>
            <div className="tab-content" id="myTabContent">
              <div
                className="tab-pane fade show active"
                id="home"
                role="tabpanel"
                aria-labelledby="home-tab"
              >
                {/*purchases*/}
                <div className="mt-3">
                  <Purchases state={props.location.state} />
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="profile"
                role="tabpanel"
                aria-labelledby="profile-tab"
              >
                {/*paybacks*/}
                <div className="mt-3">
                  <Payback state={props.location.state} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default EachSupplier;
