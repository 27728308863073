import React from "react";
import { useDispatch, useSelector } from "react-redux";

const PatientAndBillInfo = (props) => {
  const {
    invoices,
    isLoaded,
    alertOpen,
    currentPage,
    selectedInvoices,
  } = useSelector((state) => state.invoicesReducer);
  const dispatch = useDispatch();
  const total =
    props.data.dicount_money == 0 && props.data.dicount_percent == 0
      ? props.data.total
      : parseInt(props.data.total) -
        (parseInt(props.data.dicount_money) +
          parseInt(props.data.total) *
            (parseInt(props.data.dicount_percent) / 100));
  return (
    <section className="text-center dark-grey-text ">
      <div className="userInfo">
        <div className="row">
          <div className="col-4">
            <p>
              <span>Patient ID:</span> {props.data.patient.id}
            </p>
          </div>

          <div className="col-4">
            <p>
              <span>Phone :</span> {props.data?.patient.phone}
            </p>
          </div>
          <div className="col-4">
            <p>
              <span>Date :</span> {props.data?.created_at}
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-4">
            <p>
              <span>Total : </span> {props.data.total} IQD
            </p>
          </div>
          <div className="col-4">
            <p>
              <span>Discount : </span>
              {props.data?.dicount_percent != 0
                ? props.data.dicount_percent + ` %`
                : props.data.dicount_money + ` IQD`}
            </p>
          </div>
          <div className="col-4">
            <p>
              <span>Final :</span> {total} <span>IQD</span>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PatientAndBillInfo;
