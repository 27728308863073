import * as types from "../actions/action_types";
import api from "../api";
import store from "../store";
import { getToken, logout } from "./loginReducer";
import swal from "sweetalert";

export default function patientReducer(
  state = {
    patients: [],
    isLoaded: false,
    alertOpen: false,
    selectedPatient: null,
    finalPrice: 0,
    listOfTest: [],
    totalPrice: 0,
    dicount_money: 0,
    dicount_percent: 0,
  },
  action
) {
  //
  switch (action.type) {
    case types.GET_PATIENT:
      return (state = {
        ...state,
        isLoaded: true,
        selectedPatient: null,
        patients: action.payload.patients,
        total: action.payload.total,
      });
    case types.ADD_PATIENT: {
      return {
        ...state,
        isLoaded: true,
        listOfTest: [],
        totalPrice: 0,
        dicount_money: 0,
        dicount_percent: 0,
        selectedPatient: action.payload,
        patients: [...state.patients, action.payload],
      };
    }
    case types.RESET_FORM: {
      return {
        ...state,
        isLoaded: true,
        selectedPatient: null,
        finalPrice: 0,
        listOfTest: [],
        totalPrice: 0,
        dicount_money: 0,
        dicount_percent: 0,
      };
    }
    case types.ADD_INVOICES: {
      return {
        ...state,
        isLoaded: true,
        selectedPatient: null,
        finalPrice: 0,
        listOfTest: [],
        totalPrice: 0,
        dicount_money: 0,
        dicount_percent: 0,
      };
    }
    case types.SELECTED_PATIENT: {
      return {
        ...state,
        isLoaded: true,
        alertOpen: true,
        selectedPatient: action.payload,
      };
    }
    case types.ADD_TEST_PATIENT: {
      state.listOfTest = [
        {
          test: action.payload.test,
          index: action.payload.index,
          result: action.payload.result,
        },
        ...state.listOfTest,
      ];
      let total = 0;
      let urineFound = 0;
      let stoolfound = 0;
      let fluidfound = 0;
      state.listOfTest.forEach((value, i) => {
        if (value.test.category.category_name === "Urine") {
          urineFound = parseInt(value.test?.price);
        } else if (value.test.category.category_name === "Stool") {
          stoolfound = parseInt(value.test?.price);
        } else if (value.test.category.category_name === "Seminal Fluid") {
          fluidfound = parseInt(value.test?.price);
        } else {
          total = total + parseInt(value.test?.price);
        }
      });
      total = total + urineFound + stoolfound + fluidfound;
      let final =
        total - (state.dicount_money + total * (state.dicount_percent / 100));
      if (final < 0) {
        final = 0;
      }
      if (total % 250 < 125) {
        total = total - (total % 250);
      } else {
        total = total + (250 - (total % 250));
      }
      if (final % 250 < 125) {
        final = final - (final % 250);
      } else {
        final = final + (250 - (final % 250));
      }

      return {
        ...state,
        totalPrice: total,
        isLoaded: true,
        finalPrice: final,
        selectedPatient: undefined,
        patients: [...state.patients],
        listOfTest: [...state.listOfTest],
      };
    }
    case types.EDIT_TEST_PATIENT: {
      let total = 0;
      state.listOfTest.forEach((value, i) => {
        if (value.test.id === action.payload.index) {
          state.listOfTest[i].test = action.payload.test;
          state.listOfTest[i].result = action.payload.result;
        }
      });
      let urineFound = 0;
      let stoolfound = 0;
      let fluidfound = 0;
      state.listOfTest.forEach((value, i) => {
        if (value.test.category.category_name === "Urine") {
          urineFound = parseInt(value.test?.price);
        } else if (value.test.category.category_name === "Stool") {
          stoolfound = parseInt(value.test?.price);
        } else if (value.test.category.category_name === "Seminal Fluid") {
          fluidfound = parseInt(value.test?.price);
        } else {
          total = total + parseInt(value.test?.price);
        }
      });
      total = total + urineFound + stoolfound + fluidfound;

      let final =
        total - (state.dicount_money + total * (state.dicount_percent / 100));

      if (final < 0) {
        final = 0;
      }
      if (total % 250 < 125) {
        total = total - (total % 250);
      } else {
        total = total + (250 - (total % 250));
      }
      if (final % 250 < 125) {
        final = final - (final % 250);
      } else {
        final = final + (250 - (final % 250));
      }
      return {
        ...state,
        isLoaded: true,
        totalPrice: total,
        finalPrice: final,
        selectedPatient: undefined,
        patients: [...state.patients],
        listOfTest: [...state.listOfTest],
      };
    }
    case types.EDIT_DISCOUNT: {
      let total = 0;
      let urineFound = 0;
      let stoolfound = 0;
      let fluidfound = 0;
      state.listOfTest.forEach((value, i) => {
        if (value.test.category.category_name === "Urine") {
          urineFound = parseInt(value.test?.price);
        } else if (value.test.category.category_name === "Stool") {
          stoolfound = parseInt(value.test?.price);
        } else if (value.test.category.category_name === "Seminal Fluid") {
          fluidfound = parseInt(value.test?.price);
        } else {
          total = total + parseInt(value.test?.price);
        }
      });
      total = total + urineFound + stoolfound + fluidfound;

      let final =
        total -
        (parseInt(action.payload) + total * (state.dicount_percent / 100));
      if (final < 0) {
        final = 0;
      }
      if (total % 250 < 125) {
        total = total - (total % 250);
      } else {
        total = total + (250 - (total % 250));
      }
      if (final % 250 < 125) {
        final = final - (final % 250);
      } else {
        final = final + (250 - (final % 250));
      }
      return {
        ...state,
        isLoaded: true,
        dicount_money: parseInt(action.payload),
        dicount_percent: 0,
        totalPrice: total,
        finalPrice: final,
        discount: parseInt(action.payload),
        selectedPatient: undefined,
        patients: [...state.patients],
        listOfTest: [...state.listOfTest],
      };
    }
    case types.EDIT_DISCOUNT_PRECENTAGE: {
      let total = 0;
      let urineFound = 0;
      let stoolfound = 0;
      let fluidfound = 0;
      state.listOfTest.forEach((value, i) => {
        if (value.test.category.category_name === "Urine") {
          urineFound = parseInt(value.test?.price);
        } else if (value.test.category.category_name === "Stool") {
          stoolfound = parseInt(value.test?.price);
        } else if (value.test.category.category_name === "Seminal Fluid") {
          fluidfound = parseInt(value.test?.price);
        } else {
          total = total + parseInt(value.test?.price);
        }
      });
      total = total + urineFound + stoolfound + fluidfound;
      let final =
        total -
        (state.dicount_money + total * (parseInt(action.payload) / 100));
      if (final < 0) {
        final = 0;
      }
      if (total % 250 < 125) {
        total = total - (total % 250);
      } else {
        total = total + (250 - (total % 250));
      }
      if (final % 250 < 125) {
        final = final - (final % 250);
      } else {
        final = final + (250 - (final % 250));
      }
      return {
        ...state,
        isLoaded: true,
        dicount_money: 0,
        dicount_percent: parseInt(action.payload),
        totalPrice: total,
        finalPrice: final,
        selectedPatient: undefined,
        patients: [...state.patients],
        listOfTest: [...state.listOfTest],
      };
    }
    case types.DELETE_TEST_PATIENT: {
      const newList = state.listOfTest.filter(
        (item) => item.test.id != action.payload.index
      );
      let total = 0;
      let urineFound = 0;
      let stoolfound = 0;
      let fluidfound = 0;
      newList.forEach((value, i) => {
        if (value.test.category.category_name === "Urine") {
          urineFound = parseInt(value.test?.price);
        } else if (value.test.category.category_name === "Stool") {
          stoolfound = parseInt(value.test?.price);
        } else if (value.test.category.category_name === "Seminal Fluid") {
          fluidfound = parseInt(value.test?.price);
        } else {
          total = total + parseInt(value.test?.price);
        }
      });
      total = total + urineFound + stoolfound + fluidfound;
      let final =
        total - (state.dicount_money + total * (state.dicount_percent / 100));
      if (final < 0) {
        final = 0;
      }

      if (total % 250 < 125) {
        total = total - (total % 250);
      } else {
        total = total + (250 - (total % 250));
      }
      if (final % 250 < 125) {
        final = final - (final % 250);
      } else {
        final = final + (250 - (final % 250));
      }

      return {
        ...state,
        totalPrice: total,
        finalPrice: final,
        isLoaded: true,
        selectedPatient: undefined,
        patients: [...state.patients],
        listOfTest: [...newList],
      };
    }
    default:
      return state;
  }
}

export async function getPatients() {
  const config = {
    headers: { Authorization: `Bearer ${getToken()}` },
  };
  try {
    const response = await api.get("patients?_take=10000000", config);

    const data = response.data;

    store.dispatch({
      type: types.GET_PATIENT,
      payload: data,
    });
  } catch (error) {
    if (error?.response?.status == 401) {
      logout();
    }
  }
}

export async function addPatient(
  name,
  phone,
  blood,
  birth,
  gender,
  testsList,
  resultsList,
  totalPrice,
  discount,
  discountPrecentage,
  doctorId,
  setTodefualt
) {
  if (testsList.length == 0) {
    await swal("Opps!", "Please select at least one test", "error");
    return;
  }
  const config = {
    headers: { Authorization: `Bearer ${getToken()}` },
  };
  try {
    if (
      discountPrecentage == undefined ||
      discountPrecentage == "" ||
      discountPrecentage == null
    ) {
      discountPrecentage = 0;
    }
    if (discount == undefined || discount == "" || discount == null) {
      discount = 0;
    }
    let response = await api.post(
      "patients/create",
      {
        fullname: name,
        phone: phone,
        birthdate: birth,
        gender: gender,
        blood_type: blood,
      },
      config
    );
    const data = response.data;

    response = await api.post(
      "invoices/create",
      {
        patient_id: data.id,
        total: totalPrice,
        dicount_percent: discountPrecentage,
        dicount_money: discount,
        doctor_id: doctorId,
        tests: testsList,
        results: resultsList,
      },
      config
    );
    const invoice = response.data;
    document.getElementById("fromCreateTest").reset();
    setTodefualt();
    swal("Good job!", "Invoice was added successfully", "success");
    store.dispatch({
      type: types.ADD_PATIENT,
      payload: invoice,
    });

    ////TODO

    let resutls = [];
    let urines = [];
    let temp = invoice;
    invoice.tests.forEach((test, index) => {
      //
      if (test.category.id === 4) {
        urines.push({ test: test, ...invoice.results[index] });
      } else {
        resutls.push({ test: test, ...invoice.results[index] });
      }
    });
    urines.sort((a, b) =>
      a.test.order_no > b.test.order_no
        ? 1
        : b.test.order_no > a.test.order_no
        ? -1
        : 0
    );
    delete temp["results"];
    temp.tests = [...resutls, ...urines];

    store.dispatch({
      type: types.ADD_INVOICES,
      payload: temp,
    });
    //TODO
    //
    document.getElementById("fromCreateTest").reset();
    return;
  } catch (error) {
    if (error?.response?.status == 401) {
      logout();
    } else if (error.response?.status === 422) {
      const errors = JSON.parse(JSON.stringify(error.response.data.errors));

      let str = "";
      Object.keys(errors).forEach(function (key) {
        str = str + errors[key][0] + "\n";
      });
      await swal("Opps!", "Failed to add invoice\n" + str, "error");
    } else {
      await swal("Opps!", "Failed to invoice", "error");
    }
  }
  return;
}

export async function addInvoice(
  patient_id,
  testsList,
  resultsList,
  totalPrice,
  discount,
  discountPrecentage,
  doctorId,
  setTodefualt
) {
  if (testsList.length == 0) {
    await swal("Opps!", "Please select at least one test", "error");
    return;
  }
  const config = {
    headers: { Authorization: `Bearer ${getToken()}` },
  };
  try {
    if (
      discountPrecentage == undefined ||
      discountPrecentage == "" ||
      discountPrecentage == null
    ) {
      discountPrecentage = 0;
    }
    if (discount == undefined || discount == "" || discount == null) {
      discount = 0;
    }
    let response = await api.post(
      "invoices/create",
      {
        patient_id: patient_id,
        total: totalPrice,
        dicount_percent: discountPrecentage,
        dicount_money: discount,
        doctor_id: doctorId,
        tests: testsList,
        results: resultsList,
      },
      config
    );
    document.getElementById("fromCreateTest").reset();
    //
    setTodefualt();
    swal("Good job!", "Invoice was added successfully", "success");

    let resutls = [];
    let urines = [];
    let temp = response.data;
    temp.tests.forEach((test, index) => {
      //
      if (test.category.id === 4) {
        urines.push({ test: test, ...temp.results[index] });
      } else {
        resutls.push({ test: test, ...temp.results[index] });
      }
    });
    urines.sort((a, b) =>
      a.test.order_no > b.test.order_no
        ? 1
        : b.test.order_no > a.test.order_no
        ? -1
        : 0
    );
    delete temp["results"];
    temp.tests = [...resutls, ...urines];

    store.dispatch({
      type: types.ADD_INVOICES,
      payload: temp,
    });
  } catch (error) {
    if (error?.response?.status == 401) {
      logout();
    } else if (error.response?.status === 422) {
      const errors = JSON.parse(JSON.stringify(error.response.data.errors));

      let str = "";
      Object.keys(errors).forEach(function (key) {
        str = str + errors[key][0] + "\n";
      });
      await swal("Opps!", "Failed to add sell-invoices\n" + str, "error");
    } else {
      await swal("Opps!", "Failed to add invoice", "error");
    }
  } finally {
    return;
  }
}

//id , index , result , price , testId
export async function searchForPatients(search) {
  const config = {
    headers: { Authorization: `Bearer ${getToken()}` },
  };
  let url = "";

  url = `patients?_take=40&_page=0&`;
  if (isNaN(search.trim())) {
    url = url + "patient_name=" + search.trim();
  } else {
    url = url + "_filter=" + search.trim();
  }
  try {
    if (search == "" || search == undefined || search == null) {
      return [];
    }

    const response = await api.get(url, config);

    const data = response.data.patients;

    let list = [];
    data.forEach((element) => {
      list.push({
        value: element,
        label: element.fullname + " " + element.phone,
      });
    });
    return list;
  } catch (error) {
    if (error.response?.status === 401) {
      logout();
    }
    return [];
  }
}
