import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addPurchase,
  editPurchase,
  getPurchase,
} from "../../../../store/reducer/purchaseReducer";
import { useHistory } from "react-router-dom";
import {
  CLOSE_PURCHASE_ALERT,
  OPEN_PURCHASE_ALERT,
} from "../../../../store/actions/action_types";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

const Purchases = (props) => {
  const dispatch = useDispatch();
  const {
    purchases,

    purchaseAlertOpen,
  } = useSelector((state) => state.purchasePayBackRudycer);

  useEffect(() => {
    getPurchase(props.state.id);
  }, []);

  const handleClick = (e) => {
    dispatch({
      type: OPEN_PURCHASE_ALERT,
      payload: {
        e,
      },
    });
  };
  const [amount, setAmmount] = useState("");

  const [note, setNote] = useState("");

  const [amountError, setAmountErro] = useState("");
  const [noteError, setNoteError] = useState("");
  const onAmount = (evt) => {
    setAmmount(evt.target.value);
    if (evt.target.value.length < 4) {
      setAmountErro("invalid amount input");
    } else {
      setAmountErro("");
    }
  };
  const onNote = (evt) => {
    setNote(evt.target.value);
    if (evt.target.value.length < 3) {
      setNoteError("invalid note input");
    } else {
      setNoteError("");
    }
  };
  const history = useHistory();
  const handleSubmitDec = (evt) => {
    evt.preventDefault();
    if (noteError == "" && amountError == "") {
      var today = new Date();
      var dd = String(today.getDate()).padStart(2, "0");
      var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = today.getFullYear();

      today = yyyy + "-" + mm + "-" + dd;

      addPurchase(props.state.id, today, amount, note);
    } else {
      if (amount == "") {
        setAmountErro("invalid ammount");
      }
      if (note == "") {
        setNoteError("invalid note");
      }
    }
  };
  let index = 0;
  const listItems = purchases.map((d) => (
    <tr key={index}>
      <td>{index++}</td>
      <td>{d.created_by.fullname}</td>

      <td>{d.amount}</td>
      <td>{d.note}</td>
      <td>{d.date}</td>
      <td>
        <button
          type="button"
          className="btn btn-primary px-3"
          onClick={() => handleClick(d)}
        >
          <i className="far fa-edit" aria-hidden="true">
            {" "}
          </i>
        </button>
      </td>
    </tr>
  ));
  return (
    <div className="row">
      <div className="col-lg-4 col-md-4 col-sm-12">
        <form className="form" id="purchaseFormId" onSubmit={handleSubmitDec}>
          <div className="form-group">
            <label htmlFor="testAmount" className="col-form-label">
              Amount
            </label>
            <input
              type="number"
              className="form-control"
              id="testAmount"
              onChange={onAmount}
              placeholder="Amount "
            />
            <div style={{ fontSize: 12, color: "red" }}>{amountError}</div>
          </div>

          <div className="form-group">
            <label htmlFor="note" className=" col-form-label">
              Note
            </label>
            <textarea
              type="textArea"
              className="form-control"
              id="note"
              onChange={onNote}
              placeholder="Note"
              rows="4"
            />
            <div style={{ fontSize: 12, color: "red" }}>{noteError}</div>
          </div>
          <div className="form-group">
            <button type="submit" className="btn btn-primary w-100">
              <i className="fas fa-plus"></i> Add
            </button>
          </div>
        </form>
      </div>
      <div className="col-lg-8 col-md-8 col-sm-12">
        <table className="table table-bordered mt-3">
          <thead className="rounded-top">
            <tr className="bg-gradient-cyan light rounded-top">
              <th>#</th>
              <th>User</th>
              <th>Amount</th>
              <th>Note</th>
              <th>Date</th>
              <th>Edit</th>
            </tr>
          </thead>

          <tbody>{listItems}</tbody>
        </table>
      </div>
      <Example show={purchaseAlertOpen} />
    </div>
  );
};

function Example(props) {
  const dispatch = useDispatch();
  const { selectedPurchase } = useSelector(
    (state) => state.purchasePayBackRudycer
  );

  const handleClose = () => {
    dispatch({
      type: CLOSE_PURCHASE_ALERT,
    });
  };
  const handlUpdate = () => {
    if (noteError == "" && amountError == "") {
      let today = new Date();
      let dd = String(today.getDate()).padStart(2, "0");
      let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      let yyyy = today.getFullYear();

      today = yyyy + "-" + mm + "-" + dd;

      editPurchase(
        selectedPurchase.e.supplier.id,
        today,
        amount,
        note,
        selectedPurchase.e.id
      );

      dispatch({
        type: CLOSE_PURCHASE_ALERT,
      });
    }
  };

  let amount = selectedPurchase?.e.amount;
  let note = selectedPurchase?.e.note;

  const [amountError, setAmountErro] = useState("");
  const [noteError, setNoteError] = useState("");
  const onAmount = (evt) => {
    amount = evt.target.value;
    if (amount.length < 4) {
      setAmountErro("invalid amount input");
    } else {
      setAmountErro("");
    }
  };
  const onNote = (evt) => {
    note = evt.target.value;
    if (note.length < 3) {
      setNoteError("invalid note input");
    } else {
      setNoteError("");
    }
  };

  return props.show == true ? (
    <>
      {" "}
      <Modal show={props.show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Purchased</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="form" onSubmit={handlUpdate} id="doctorNameForm">
            <div className="row">
              <div className="col-10">
                Amount :{" "}
                <input
                  type="text"
                  defaultValue={amount}
                  onChange={onAmount}
                  className="form-control"
                  id="doctorName"
                  placeholder="Amount"
                />
                <div style={{ fontSize: 12, color: "red" }}>{amountError}</div>
              </div>
              <br /> <br />
              <div className="col-10">
                Note :{" "}
                <input
                  type="text"
                  defaultValue={note}
                  onChange={onNote}
                  className="form-control"
                  id="doctorName"
                  placeholder="Note"
                />
                <div style={{ fontSize: 12, color: "red" }}>{noteError}</div>
              </div>
              <div className="col-2"></div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handlUpdate}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  ) : (
    <div></div>
  );
}

export default Purchases;
