import React, { useEffect } from "react";
import "./adminStyle.css";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as types from "../../../store/actions/action_types";
import {
  CLOSE_USER_ALERT,
  OPEN_USER_ALERT,
} from "../../../store/actions/action_types";
import { deleteUser, getUsers } from "../../../store/reducer/usersReducer";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { createBrowserHistory } from "history";

export default function User() {
  const dispatch = useDispatch();
  const { users, isUserLoaded, isUserAlertOpend, selectedUser } = useSelector(
    (state) => state.userReducer
  );

  useEffect(() => {
    if (!users || users == "" || users.length == 0) {
      getUsers();
    }
  }, []);
  let index = 1;

  const handleClick = (e) => {
    dispatch({
      type: OPEN_USER_ALERT,
      payload: e,
    });
  };

  let history = useHistory();
  const { user } = useSelector((state) => state.loginReducer, []);
  if (user.user.user_info.user_type !== "lab-admin") {
    createBrowserHistory().push("/");
    window.location.reload();
  }
  const handleEdit = (e) => {
    history.push("/editUser", e);
  };
  const listItems = users.map((d) => (
    <tr key={index}>
      <td>{index++}</td>
      <td>{d.fullname}</td>
      <td>{d.phone}</td>
      <td>{d.username}</td>
      <td>{d.user_type}</td>
      <td scope="row">
        <button
          type="button"
          className="btn btn-outline-danger waves-effect px-3"
          onClick={(e) => {
            handleClick(d);
          }}
        >
          <i className="fas fa-user-times" aria-hidden="true">
            {" "}
          </i>
        </button>
        <span> </span>
        <button
          type="button"
          onClick={(e) => {
            handleEdit(d);
          }}
          className="btn btn-outline-info waves-effect px-3"
        >
          <i className="far fa-edit" aria-hidden="true">
            {" "}
          </i>
        </button>
      </td>
    </tr>
  ));
  return (
    <div className="content-wrapper topSpace">
      <section className="tableSection">
        <form className="form mb-3">
          <div className="row center ">
            <div className="col-6">
              <Link type="button" className="btn btn-info w-100" to="/register">
                <i className="fas fa-plus"></i> Add
              </Link>
            </div>
          </div>
        </form>
        <table className="table">
          <thead className="topthrade">
            <tr>
              <th scope="col">#</th>
              <th scope="col">Name</th>
              <th scope="col">Phone</th>
              <th scope="col">Email</th>
              <th scope="col">Role</th>
              <th scope="col">
                <span> </span>
              </th>
            </tr>
          </thead>
          <tbody>{listItems}</tbody>
        </table>
      </section>
      <Example show={isUserAlertOpend} />
    </div>
  );
}
function Example(props) {
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch({
      type: CLOSE_USER_ALERT,
    });
  };
  const { users, isUserLoaded, isUserAlertOpend, selectedUser } = useSelector(
    (state) => state.userReducer
  );
  const handleDelete = () => {
    dispatch({
      type: types.CLOSE_USER_ALERT,
    });
    deleteUser(selectedUser.id);
  };

  return props.show == true ? (
    <>
      {" "}
      <Modal show={props.show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Delete User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are You Sure To Delete This User "{selectedUser.fullname}"
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            No
          </Button>
          <Button variant="primary" onClick={handleDelete}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  ) : (
    <div></div>
  );
}
