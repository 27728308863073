import * as types from "../actions/action_types";
import api from "../api";
import store from "../store";
import swal from "sweetalert";
import { getToken, logout } from "./loginReducer";

export default function purchasePayBackRudycer(
  state = {
    purchases: [],
    paybacks: [],
    isPurshasedLoaded: false,
    isPayBackLoaded: false,
    purchaseAlertOpen: false,
    payBackAlertOpen: false,
    selectedPayBack: null,
    selectedPurchase: null,
  },
  action
) {
  switch (action.type) {
    case types.GET_PAYBACKS:
      return (state = {
        ...state,
        paybacks: [...action.payload],
        isPayBackLoaded: true,
      });
    case types.GET_PURCHASE: {
      return {
        ...state,
        isPurshasedLoaded: true,
        purchases: [...action.payload],
      };
    }
    case types.ADD_PURCHASE: {
      return {
        ...state,
        isPurshasedLoaded: true,
        purchases: [...state.purchases, action.payload],
      };
    }
    case types.ADD_PAYBACK: {
      return {
        ...state,
        paybacks: [...state.paybacks, action.payload],
        isPayBackLoaded: true,
      };
    }
    case types.DELETE_PURCHASE: {
      let arr = state.purchases.filter(function (item) {
        return item !== action.payload.id;
      });
      return {
        ...state,
        isPurshasedLoaded: true,
        purchases: [...arr],
      };
    }

    case types.DELETE_PAYBACK: {
      let arr = state.paybacks.filter(function (item) {
        return item !== action.payload.id;
      });
      return {
        ...state,
        isPayBackLoaded: true,
        paybacks: [...arr],
      };
    }
    case types.EDIT_PURCHASE: {
      var index = 0;
      state.purchases.forEach((value, i) => {
        if (value.id === action.payload.id) {
          index = i;
        }
      });
      state.purchases[index] = action.payload;

      return {
        ...state,
        isPurshasedLoaded: true,
        purchases: [...state.purchases],
      };
    }
    case types.EDIT_PAYBACK: {
      let index = 0;
      state.paybacks.forEach((value, i) => {
        if (value.id === action.payload.id) {
          index = i;
        }
      });
      state.paybacks[index] = action.payload;

      return {
        ...state,
        isPayBackLoaded: true,
        paybacks: [...state.paybacks],
      };
    }
    case types.OPEN_PURCHASE_ALERT: {
      return {
        ...state,
        purchaseAlertOpen: true,
        selectedPurchase: action.payload,
      };
    }
    case types.OPEN_PAYBACK_ALERT: {
      return {
        ...state,
        payBackAlertOpen: true,
        selectedPayBack: action.payload,
      };
    }
    case types.CLOSE_PURCHASE_ALERT: {
      return {
        ...state,
        purchaseAlertOpen: false,
        selectedPurchase: undefined,
      };
    }
    case types.CLOSE_PAYBACK_ALERT: {
      return {
        ...state,
        payBackAlertOpen: false,
        selectedPayBack: undefined,
      };
    }
    default:
      return state;
  }
}

export async function getPayPacks(suppler_id) {
  const config = {
    headers: { Authorization: `Bearer ${getToken()}` },
  };
  try {
    const response = await api.get(
      "paybacks/by-supplier/" + suppler_id,
      config
    );

    const data = response.data;

    store.dispatch({ type: types.GET_PAYBACKS, payload: data });
  } catch (error) {}
}

export async function getPurchase(suppler_id) {
  const config = {
    headers: { Authorization: `Bearer ${getToken()}` },
  };
  try {
    const response = await api.get(
      "purchased/by-supplier/" + suppler_id,
      config
    );

    const data = response.data;

    store.dispatch({ type: types.GET_PURCHASE, payload: data });
  } catch (error) {
    if (error?.response?.status == 401) {
      logout();
    }
  }
}

export async function addPurchase(supplier_id, date, amount, note) {
  const config = {
    headers: { Authorization: `Bearer ${getToken()}` },
  };
  try {
 
    const response = await api.post(
      "purchased/create",
      {
        supplier_id: supplier_id,
        date: new Date().toISOString().split("T")[0],
        amount: amount,
        note: note,
      },
      config
    );

    const data = response.data;

    store.dispatch({ type: types.ADD_PURCHASE, payload: response.data });
    document.getElementById("purchaseFormId").reset();

    await swal("Good job!", "purchase was added successfully", "success");
  } catch (error) {
    if (error?.response?.status == 401) {
      logout();
    }
    await swal("Opps!", "Failed to add purchase", "error");
  }
}

export async function addPayBack(supplier_id, date, amount, note) {
  const config = {
    headers: { Authorization: `Bearer ${getToken()}` },
  };
  try {
 
    const response = await api.post(
      "paybacks/create",
      {
        supplier_id: supplier_id,
        date: new Date().toISOString().split("T")[0],
        amount: amount,
        note: note,
      },
      config
    );
    const data = response.data;
    //
    document.getElementById("paybacksFormId").reset();

    store.dispatch({ type: types.ADD_PAYBACK, payload: response.data });
    swal("Good job!", "payback was added successfully", "success");
  } catch (error) {
    if (error?.response?.status == 401) {
      logout();
    }
    await swal("Opps!", "Failed to add payback", "error");
  }
}

export async function editPurchase(supplier_id, date, amount, note, id) {
  const config = {
    headers: { Authorization: `Bearer ${getToken()}` },
  };
  try {
    const response = await api.put(
      "purchased/update/" + id,
      {
        supplier_id: supplier_id,
        date: new Date().toISOString().split("T")[0],
        amount: amount,
        note: note,
      },
      config
    );

    const data = response.data;

    store.dispatch({ type: types.EDIT_PURCHASE, payload: response.data });
    swal("Good job!", "putchase was added successfully", "success");
  } catch (error) {
    if (error?.response?.status == 401) {
      logout();
    }
    await swal("Opps!", "Failed to update purchase", "error");
  }
}

export async function editPayBack(supplier_id, date, amount, note, id) {
  const config = {
    headers: { Authorization: `Bearer ${getToken()}` },
  };
  try {
    const response = await api.put(
      "paybacks/update/" + id,
      {
        supplier_id: supplier_id,
        date: new Date().toISOString().split("T")[0],
        amount: amount,
        note: note,
      },
      config
    );

    const data = response.data;

    store.dispatch({ type: types.EDIT_PAYBACK, payload: response.data });
    swal("Good job!", "payback was update successfully", "success");
  } catch (error) {
    if (error?.response?.status == 401) {
      logout();
    }
    await swal("Opps!", "Failed to update payback", "error");
  }
}
