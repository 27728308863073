import * as types from "./action_types";

export function getDoctorsAction(doctors) {
    return { type: types.GET_DOCTOR,payload: doctors };
}

export function addDoctorAction(doctor) {
    return { type: types.ADD_DOCTOR,payload: doctor };
}

export function deleteDoctorAction(doctor) {
    return { type: types.DELETE_DOCTOR,payload: doctor };
}
export function setUser(user) {
    return { type: types.SET_CURRENT_USER,payload: user };
}
export function editDoctorAction(doctor) {
    return { type: types.EDIT_DOCTOR,payload: doctor };
}