import * as types from "../actions/action_types";
import api from "../api";
import store from "../store";
import swal from "sweetalert";

import { createBrowserHistory } from "history";
import { getToken, logout } from "./loginReducer";

export default function testReducer(
  state = {orginalTestList
    : [],
    testList: [],
    isTestsLoaded: false,
    testAlertOpen: false,

    selectedTest: null,
  },
  action
) {
  switch (action.type) {
    case types.GET_TESTS:
      return (state = {
        ...state,
        isTestsLoaded: true,
        selectedTest: null,
        testAlertOpen: false,
        orginalTestList: action.payload,
        testList: action.payload,
      });
    case types.ADD_TEST: {
      return {
        ...state,
        isTestsLoaded: true,
        testAlertOpen: false,
        selectedTest: null,
        orginalTestList: [action.payload, ...state.orginalTestList],
        testList: [action.payload, ...state.testList],
      };
    }

    case types.OPEN_TEST_ALERT: {
      return {
        ...state,
        isTestsLoaded: true,
        testAlertOpen: true,
        selectedTest: action.payload,
      };
    }
    case types.CLOSE_TEST_ALERT: {
      return {
        ...state,
        isTestsLoaded: true,
        testAlertOpen: false,
        selectedTest: null,
      };
    }
    case types.FORCE_REFRSH: {
      return {
        ...state,

        isTestsLoaded: true,
        testAlertOpen: false,
        selectedTest: null,
        orginalTestList: [...state.orginalTestList],
      };
    }
    case types.EDIT_TEST_GATOGORY: {
      var index = 0;
      [...state.orginalTestList].forEach((value, i) => {
        if (value.id === action.payload.id) {
          index = i;
        }
      });
      state.orginalTestList[index] = action.payload;

      index = 0;
      [...state.testList].forEach((value, i) => {
        if (value.id === action.payload.id) {
          index = i;
        }
      });
      state.testList[index] = action.payload;

      return {
        ...state,
        isTestsLoaded: true,
        testAlertOpen: false,
        selectedTest: null,
        orginalTestList: [...state.orginalTestList],
        testList: [...state.testList],
      };
    }
    case types.EDIT_TEST: {
      index = 0;
      [...state.orginalTestList].forEach((value, i) => {
        if (value.id === action.payload.id) {
          index = i;
        }
      });
      state.orginalTestList[index] = action.payload;

      index = 0;
      [...state.testList].forEach((value, i) => {
        if (value.id === action.payload.id) {
          index = i;
        }
      });
      state.testList[index] = action.payload;

      return {
        ...state,
        isTestsLoaded: true,
        testAlertOpen: false,
        selectedTest: null,
        orginalTestList: [...state.orginalTestList],
        testList: [...state.testList],
      };
    }
    case types.SEARCH_TEST: {
      var temp = state.orginalTestList.filter((test) =>
        test.name.toLowerCase().includes(action.payload.toLowerCase())
      );

      return {
        ...state,
        isTestsLoaded: true,
        testAlertOpen: false,
        selectedTest: null,
        orginalTestList: [...state.orginalTestList],
        testList: [...temp],
      };
    }
    case types.FILTER_TEST: {
      temp = state.orginalTestList.filter(
        (test) => test.category.id == action.payload.id
      );
      if (action.payload.id == -1) {
        temp = [...state.orginalTestList];
      }
      return {
        ...state,
        isTestsLoaded: true,
        testAlertOpen: false,
        selectedTest: null,
        orginalTestList: [...state.orginalTestList],
        testList: [...temp],
      };
    }
    case types.DELETE_TEST: {
      const newList = state.orginalTestList.filter(
        (item) => item.id !== action.payload.id
      );

      return {
        ...state,
        isTestsLoaded: true,
        testAlertOpen: false,
        selectedTest: null,
        orginalTestList: [...newList],
        testList: [...newList],
      };
    }
    default:
      return state;
  }
}

export async function getTests() {
  const config = {
    headers: { Authorization: `Bearer ${getToken()}` },
  };
  try {
    const response = await api.get("tests?_take=10000000", config);

    let data = response.data;
    store.dispatch({ type: types.GET_TESTS, payload: data });
  } catch (error) {
    if (error?.response?.status == 401) {
      logout();
    }
  }
}

export async function addTest(
  name,
  price,
  cost,
  unit,
  instrument,
  catagoryId,
  normalRange
) {
  const config = {
    headers: { Authorization: `Bearer ${getToken()}` },
  };
  try {
    const response = await api.post(
      "tests/create",
      {
        name: name,
        price: price,
        cost: cost,
        unit: unit,
        instrument: instrument,
        normal_range: normalRange,
        category_id: catagoryId,
      },
      config
    );

    const data = response.data;

    document.getElementById("testFormIdsss").reset();
    store.dispatch({ type: types.ADD_TEST, payload: data });
    swal("Good job!", "Test was added successfully", "success");
  } catch (error) {
    await swal("Opps!", "Failed to add test", "error");
    if (error?.response?.status == 401) {
      logout();
    }
  }
}

export async function upDateTest(
  name,
  price,
  cost,
  unit,
  instrument,
  catagoryId,
  normalRange,
  id
) {
  const config = {
    headers: { Authorization: `Bearer ${getToken()}` },
  };
  try {
    const response = await api.put(
      "tests/update/" + id,
      {
        name: name,
        price: price,
        cost: cost,
        unit: unit,
        instrument: instrument,
        normal_range: normalRange,
        category_id: catagoryId,
      },
      config
    );

    const data = response.data;

    store.dispatch({ type: types.EDIT_TEST, payload: data });
    swal("Good job!", "Test was updated successfully", "success");
    createBrowserHistory().back();
  } catch (error) {
    await swal("Opps!", "Failed to update test", "error");
    if (error?.response?.status == 401) {
      logout();
    }
  }
}

export async function deleteTest(id) {
  const config = {
    headers: { Authorization: `Bearer ${getToken()}` },
  };
  try {
    const response = await api.delete(
      "tests/delete/" + id,

      config
    );

    const data = response.data;

    store.dispatch({ type: types.DELETE_TEST, payload: { id: id } });
    swal("Good job!", "Test was deleted successfully", "success");
  } catch (error) {
    await swal("Opps!", "Failed to delete test", "error");
    if (error?.response?.status == 401) {
      logout();
    }
  }
}
