import "./TestStyle.css";
import React, { useEffect, useState } from "react";
import { getCatagory } from "../../../store/reducer/testGatagoryReducer";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { upDateTest } from "../../../store/reducer/testReducer";

const EditTest = (props) => {
  const { gatagories, isLoaded, alertOpen, selectedGatagory } = useSelector(
    (state) => state.catagoryReducer
  );
  const {
    orginalTestList,
    testList,
    isTestsLoaded,
    testAlertOpen,
    selectedTest,
  } = useSelector((state) => state.testReducer);
  let history = useHistory();
  const dispatch = useDispatch();

  const [name, setName] = useState(props.location.state.name);
  const [price, setPrice] = useState(props.location.state.price);

  const [cost, setCost] = useState(props.location.state.cost);

  const [unit, setUnit] = useState(props.location.state.unit);
  const [instrument, setInsturment] = useState(props.location.state.instrument);
  const [catagoryId, steCatId] = useState(props.location.state.category.id);
  const [normalRange, setNormalRange] = useState(
    props.location.state.normal_range
  );

  const [nameErro, setNameError] = useState("");
  const [priceError, setPriceError] = useState("");
  const [costError, setCostError] = useState("");
  const [unitError, setUnitError] = useState("");
  const [instrumentError, setInstrumentError] = useState("");
  const [normalRangeError, setNormalRangeError] = useState("");

  useEffect(() => {
    if (!gatagories || gatagories == "" || gatagories.length == 0) {
      getCatagory();
    }
  }, []);

  let listItems = Array();

  gatagories.map((d) => {
    if (
      d.category_name !== "Stool" &&
      d.category_name !== "Urine" &&
      d.category_name !== "Seminal Fluid"
    ) {
      listItems.push(
        <option key={d.category_name} value={d.id}>
          {d.category_name}
        </option>
      );
    }
  });


  const handleSubmit = (evt) => {
    evt.preventDefault();
 

    if (
      nameErro === "" &&
      priceError === "" &&
      costError === "" &&
      unitError === "" &&
      instrumentError === "" &&
      normalRangeError === ""
    ) {
      upDateTest(
        name,
        price,
        cost,
        unit,
        instrument,
        catagoryId,
        normalRange,
        props.location.state.id
      );
    }
  };

  const handleTestTypeChnage = (evt) => {
    steCatId(evt.target.value);
  };
  const onNameChange = (evt) => {
    setName(evt);
    if (evt.length < 2) {
      setNameError("test name length must be more than 2");
    } else {
      setNameError("");
    }
  };
  const onPriceChnage = (evt) => {
    setPrice(evt);
    if (evt == "" || parseInt(evt) < 1) {
      setPriceError("test price must be more than 0 IQD");
    } else {
      setPriceError("");
    }
  };
  const onCostChnage = (evt) => {
    setCost(evt);
    if (evt == "" || parseInt(evt) < 1) {
      setCostError("test cost must be more than 0 IQD");
    } else {
      setCostError("");
    }
  };
  const onUnitChnage = (evt) => {
    setUnit(evt);
    if (evt.length < 1) {
      setUnitError("test unit must be more than zero");
    } else {
      setUnitError("");
    }
  };
  const onInsturmentChange = (evt) => {
    setInsturment(evt);
    if (evt.length < 1) {
      setInstrumentError(
        "test instrument must be more than more than one character"
      );
    } else {
      setInstrumentError("");
    }
  };
  const onNormalRangeChnage = (evt) => {
    setNormalRange(evt);
    if (evt.length < 2) {
      setNormalRangeError(
        "test normal range length must be more than 2 character"
      );
    } else {
      setNormalRangeError("");
    }
  };

  return (
    <div className="content-wrapper topForm">
      <section className="tableSection">
        <div className="row">
          <div className="col-lg-4 col-md-4 col-sm-12">
            <form className="form" id="testFormId" onSubmit={handleSubmit}>
              <div className="form-group">
                <label htmlFor="testName" className="col-form-label">
                  Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="testName"
                  defaultValue={name}
                  onChange={(event) => onNameChange(event.target.value)}
                  placeholder="Name"
                />
                <div style={{ fontSize: 12, color: "red" }}>{nameErro}</div>
              </div>
              <div className="form-group">
                <label htmlFor="testPrice" className=" col-form-label">
                  Price
                </label>
                <input
                  type="number"
                  className="form-control"
                  id="testPrice"
                  defaultValue={price}
                  onChange={(event) => onPriceChnage(event.target.value)}
                  placeholder="Price"
                />
                <div style={{ fontSize: 12, color: "red" }}>{priceError}</div>
              </div>{" "}
              <div className="form-group">
                <label htmlFor="costPrice" className=" col-form-label">
                  Cost Price
                </label>
                <input
                  type="number"
                  className="form-control"
                  onChange={(event) => onCostChnage(event.target.value)}
                  defaultValue={cost}
                  id="costPrice"
                  placeholder="Cost Price"
                />
                <div style={{ fontSize: 12, color: "red" }}>{costError}</div>
              </div>
              <div className="form-group">
                <label htmlFor="testUnit" className=" col-form-label">
                  Unit
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="testUnit"
                  defaultValue={unit}
                  onChange={(event) => onUnitChnage(event.target.value)}
                  placeholder="Unit"
                />
                <div style={{ fontSize: 12, color: "red" }}>{unitError}</div>
              </div>
              <div className="form-group">
                <label htmlFor="testInstrument" className=" col-form-label">
                  Instrument
                </label>
                <input
                  type="text"
                  className="form-control"
                  defaultValue={instrument}
                  onChange={(event) => onInsturmentChange(event.target.value)}
                  id="testInstrument"
                  placeholder="Instrument"
                />
                <div style={{ fontSize: 12, color: "red" }}>
                  {instrumentError}
                </div>
              </div>
              <div className="form-group-lg">
                <label className=" col-form-label">Category</label>
                <div className="row">
                  <div className="col-10">
                    <div className="select2-purple input-group">
                      <select
                        className="form-control select2bs4 d-inline"
                        defaultValue={catagoryId}
                        name="state"
                        onChange={handleTestTypeChnage}
                      >
                        {listItems}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="testPrice" className=" col-form-label">
                  Normal Range
                </label>
                <textarea
                  type="textArea"
                  className="form-control"
                  defaultValue={normalRange}
                  onChange={(event) => onNormalRangeChnage(event.target.value)}
                  id="testRange"
                  placeholder="Normal Range"
                  rows="4"
                />
                <div style={{ fontSize: 12, color: "red" }}>
                  {normalRangeError}
                </div>
              </div>
              <div className="form-group">
                <button type="submit" className="btn btn-primary w-100">
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>
      </section>
    </div>
  );
};
export default EditTest;
