import React, { Component } from "react";
import "./printStyle.css";
import Page from "./page";
import PageUrine from "./page_urine";
import header from "../image/header.png";
import footer from "../image/footer.png";
import { getUserName } from "../../../store/reducer/loginReducer";

class Print extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const invoice = this.props.state;

    let pageNumber = 1;
    let tests = invoice.tests;

    const tempInvoices = tests.sort((a, b) => {
      return a.test.category.id > b.test.category.id ? 1 : -1;
    });
    const stool = Array();
    const urine = Array();
    const fluid = Array();

    tempInvoices.forEach((e, ind) => {
      if (e.test.category.category_name === "Stool") {
        stool.push(e);
      } else if (e.test.category.category_name === "Urine") {
        urine.push(e);
      } else if (e.test.category.category_name === "Seminal Fluid") {
        fluid.push(e);
      }
    });

    urine.reverse();
    fluid.reverse();
    stool.reverse();
    let finalResult = tempInvoices.filter(function (el, index) {
      return (
        el.test.category.category_name !== "Stool" &&
        el.test.category.category_name !== "Urine" &&
        el.test.category.category_name !== "Seminal Fluid"
      );
    });
    //
    let addSpace = false;
    const getStools = () => {
      if (stool.length > 0) {
        addSpace = true;
        if (finalResult.length > 0) {
          return (
            <div>
              <div className="page-divide"></div>
              <Page
                resultWithTest={stool}
                page={pageNumber}
                index={pageNumber}
                invoice={invoice}
              />
            </div>
          );
        } else {
          return (
            <Page
              resultWithTest={stool}
              page={pageNumber}
              index={pageNumber}
              invoice={invoice}
            />
          );
        }
      } else {
        if (finalResult.length > 0) {
          addSpace = true;
        }
        return null;
      }
    };
    let addSpaceAfterUrine = false;
    const getUrine = () => {
      if (urine.length > 0) {
        addSpaceAfterUrine = true;
        if (addSpace === true) {
          return (
            <div>
              <div className="page-divide"></div>
              <PageUrine
                resultWithTest={urine}
                page={pageNumber}
                index={pageNumber}
                invoice={invoice}
              />
            </div>
          );
        } else {
          return (
            <PageUrine
              resultWithTest={urine}
              page={pageNumber}
              index={pageNumber}
              invoice={invoice}
            />
          );
        }
      } else {
        if (finalResult.length > 0) {
          addSpaceAfterUrine = true;
        }
        return null;
      }
    };

    const getFluid = () => {
      if (fluid.length > 0) {
        if (addSpaceAfterUrine === true) {
          return (
            <div>
              <div className="page-divide"></div>
              <Page
                resultWithTest={fluid}
                page={pageNumber}
                index={pageNumber}
                invoice={invoice}
              />
            </div>
          );
        } else {
          return (
            <Page
              resultWithTest={fluid}
              page={pageNumber}
              index={pageNumber}
              invoice={invoice}
            />
          );
        }
      } else {
        return null;
      }
    };
    //
    //
    //

    let currentDate = new Date();
    let day = currentDate.getDate();
    let month = currentDate.getMonth() + 1;
    let year = currentDate.getFullYear();
    return (
      <div>
        <div className="page-header">
          <img
            // https://firebasestorage.googleapis.com/v0/b/teststore-bf42c.appspot.com/o/headr3.png?alt=media&token=a1fa7833-7bc5-4f98-b72a-caa0556ba65c
            src={header}
            id="headerImg"
          />

          <div className="personInfo">
            {" "}
            <table className="table table-bordered">
              <thead className="bg-primary">
                <tr>
                  <th className="text-white p-0">
                    <div className="bg-primary m-0 p-3">ID</div>
                  </th>

                  <th className="text-white p-0">
                    <div className="bg-primary m-0 p-3"> Name & Phone</div>
                  </th>
                  <th className="text-white p-0">
                    <div className="bg-primary m-0 p-3">Age & gender</div>
                  </th>
                  <th className="text-white p-0">
                    <div className="bg-primary m-0 p-3">Referral</div>
                  </th>
                  <th className="text-white p-0">
                    <div className="bg-primary m-0 p-3">By</div>
                  </th>
                  <th className="text-white p-0">
                    <div className="bg-primary m-0 p-3">Date</div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <h5 className="font-weight-bold">{invoice.id} </h5>
                  </td>
                  <td>
                    <h5 className="font-weight-bold">
                      {invoice.patient.fullname}-{invoice.patient.phone}
                    </h5>
                  </td>
                  <td>
                    <h5 className="font-weight-bold">
                      {getAge(invoice.patient.birthdate)} -{" "}
                      {invoice.patient.gender}
                    </h5>
                  </td>
                  <td>
                    <h5 className="font-weight-bold">{invoice.doctor.name}</h5>
                  </td>
                  <td>{getUserName()}</td>
                  <td>{invoice.created_at.toString().split(" ")[0]}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div className="page-footer">
          <img
            // src="https://firebasestorage.googleapis.com/v0/b/teststore-bf42c.appspot.com/o/footer2.png?alt=media&token=106d8285-e784-4ed4-88a5-c25f35b95c97"
            src={footer}
            id="footerImg"
          />
        </div>

        <table>
          <thead>
            <tr>
              <td>
                <div className="page-header-space"></div>
              </td>
            </tr>
          </thead>

          <tbody>
            <tr style={{ padding: "0" }}>
              <td>
                <Page
                  resultWithTest={finalResult}
                  page={pageNumber}
                  index={pageNumber}
                  invoice={invoice}
                />
                {getStools()}
                {getUrine()}
                {getFluid()}
              </td>
            </tr>
          </tbody>

          <tfoot>
            <tr>
              <td>
                <div className="page-footer-space"></div>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>

      //   </div>
    );
  }
}

export default Print;
function getAge(dateString) {
  var today = new Date();
  var DOB = new Date(dateString);
  var totalMonths =
    (today.getFullYear() - DOB.getFullYear()) * 12 +
    today.getMonth() -
    DOB.getMonth();
  totalMonths += today.getDay() < DOB.getDay() ? -1 : 0;
  var years = today.getFullYear() - DOB.getFullYear();
  if (DOB.getMonth() > today.getMonth()) years = years - 1;
  else if (DOB.getMonth() === today.getMonth())
    if (DOB.getDate() > today.getDate()) years = years - 1;

  var days;
  var months;

  if (DOB.getDate() > today.getDate()) {
    months = totalMonths % 12;
    if (months == 0) months = 11;
    var x = today.getMonth();
    switch (x) {
      case 1:
      case 3:
      case 5:
      case 7:
      case 8:
      case 10:
      case 12: {
        var a = DOB.getDate() - today.getDate();
        days = 31 - a;
        break;
      }
      default: {
        var a = DOB.getDate() - today.getDate();
        days = 30 - a;
        break;
      }
    }
  } else {
    days = today.getDate() - DOB.getDate();
    if (DOB.getMonth() === today.getMonth()) months = totalMonths % 12;
    else months = (totalMonths % 12) + 1;
  }
  if (months < 0) {
    months = 0;
  }
  if (years < 0) {
    years = 0;
  }
  if (days < 0) {
    days = 0;
  }
  if (Number.isNaN(months)) {
    months = 0;
  }
  if (Number.isNaN(years)) {
    years = 0;
  }
  if (Number.isNaN(days)) {
    days = 0;
  }
  var age = "";

  if (years > 0) {
    return years + " years";
  }
  if (months > 0) {
    return `${months}` + " months";
  }
  if (days > 0) {
    return `${days}` + "days";
  }

  return age;
}
