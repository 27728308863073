import React, { useEffect, useState } from "react";
import * as types from "../../../store/actions/action_types";
import { useDispatch, useSelector } from "react-redux";
import {
  addNewExpenses,
  getExpenses,
  upDateExpense,
} from "../../../store/reducer/expensesReducer";
import "./ExpensesStyle.css";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import ReactPaginate from "react-paginate";

export default function Expenses() {
  const dispatch = useDispatch();
  const { expenses, total, alertOpen, totalAmount } = useSelector(
    (state) => state.expensesReducer
  );
  const [page, setPage] = useState(0);
  useEffect(() => {
    if (!expenses || expenses === "" || expenses.length === 0) {
      getExpenses(page);
    }
  }, []);
  let index = 1;
  const handleClick = (e) => {
    dispatch({
      type: types.OPEN_EXPENSE_ALERT,
      payload: {
        e,
      },
    });
  };
  const listItems = expenses.map((d) => {
    return (
      <tr key={index}>
        <td>{index++}</td>
        <td>{d.created_by.fullname}</td>
        <td>{d.amount}</td>
        <td>{d.reason}</td>
        <td>{d.date}</td>
        <td>
          <button
            type="button"
            className="btn btn-primary px-3"
            data-toggle="modal"
            onClick={() => handleClick(d)}
            data-target="#basicExampleModal"
          >
            <i className="far fa-edit" aria-hidden="true"></i>
          </button>
        </td>
      </tr>
    );
  });
  const handleSubmit = async (evt) => {
    evt.preventDefault();
    if (reasonError === "" && amountError === "") {
      setDisable(true);
      await addNewExpenses(reason, amount);
      setDisable(false);
    }
  };
  const [reason, setReason] = useState("");
  const [amount, setAmount] = useState("");
  const [reasonError, setReasonError] = useState("");
  const [amountError, setAmountError] = useState("");

  function handleREason(e) {
    setReason(e.target.value);
    if (e.target.value.length < 6) {
      setReasonError("reason must be at least 6 character");
    } else {
      setReasonError("");
    }
  }

  function handleAmount(e) {
    setAmount(e.target.value);
    if (e.target.value.length < 4) {
      setAmountError("amount must be more than 3 character");
    } else {
      setAmountError("");
    }
  }
  const [disable, setDisable] = useState(false);
  return (
    <div className="content-wrapper topSpace">
      <section className="tableSection">
        <div className="row">
          <div className="col-lg-4 col-md-4 col-sm-12">
            <form className="form" onSubmit={handleSubmit} id="expenseFormId">
              <div className="form-group">
                <label htmlFor="testName" className="col-form-label">
                  Amount
                </label>
                <input
                  type="number"
                  onChange={handleAmount}
                  className="form-control"
                  id="testAmount"
                  placeholder="Amount "
                />

                <div style={{ fontSize: 12, color: "red" }}>{amountError}</div>
              </div>

              <div className="form-group">
                <label htmlFor="testPrice" className=" col-form-label">
                  Reason
                </label>
                <textarea
                  type="textArea"
                  onChange={handleREason}
                  className="form-control"
                  id="testReason"
                  placeholder="Reason"
                  rows="4"
                />
                <div style={{ fontSize: 12, color: "red" }}>{reasonError}</div>
              </div>
              <div className="form-group">
                <button
                  type="submit"
                  disabled={disable}
                  className="btn btn-primary w-100"
                >
                  <i className="fas fa-plus"></i> Add
                </button>
              </div>
            </form>
          </div>
          <div className="col-lg-8 col-md-8 col-sm-12">
            <table className="table table-bordered mt-3">
              <thead className="rounded-top">
                <tr className="bg-gradient-cyan light rounded-top">
                  <th>#</th>
                  <th>User</th>
                  <th>Amount</th>
                  <th>Reason</th>
                  <th>Date</th>
                  <th>Edit</th>
                </tr>
              </thead>

              <tbody>{listItems}</tbody>
            </table>

            {total > 0 ? (
              <div className="center">
                <ReactPaginate
                  previousLabel={"prev"}
                  nextLabel={"next"}
                  breakLabel={"..."}
                  breakClassName={"break-me"}
                  onPageChange={(ev) => {
                    setPage(ev.selected);

                    getExpenses(ev.selected);
                  }}
                  pageCount={total / 10}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  containerClassName={"pagination"}
                  subContainerClassName={"pages pagination"}
                  activeClassName={"active"}
                />
              </div>
            ) : null}
            <div>
              <span className="badge badge-info mb-3">
                {" "}
                <h5 className="d-inline">total</h5>{" "}
              </span>
              :
              <span className="badge badge-primary mb-3 mr-3">
                {" "}
                <h5 className="d-inline">
                  {" "}
                  {expenses.length > 0 ? totalAmount : ""} IQD
                </h5>{" "}
              </span>
            </div>
          </div>
        </div>
      </section>
      <Example show={alertOpen} />
    </div>
  );
}

function Example(props) {
  const dispatch = useDispatch();

  const { expenses, isLoaded, alertOpen, selectedExpense } = useSelector(
    (state) => state.expensesReducer
  );

  const handleClose = () => {
    dispatch({
      type: types.CLOSE_EXPENSE_ALERT,
    });
  };
  const handlUpdate = () => {
    upDateExpense(reason, amount, selectedExpense.e.id, {
      ...selectedExpense.e,
    });
    dispatch({
      type: types.CLOSE_EXPENSE_ALERT,
    });
  };

  var reason = selectedExpense?.e.name;

  function handleReason(e) {
    reason = e.target.value;
  }

  var amount = selectedExpense?.e.amount;

  function handleAmount(e) {
    amount = e.target.value;
  }

  return props.show === true ? (
    <>
      {" "}
      <Modal show={props.show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Expense </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="form" onSubmit={handlUpdate} id="doctorNameForm">
            <div className="row">
              <div className="col-10">
                <label htmlFor="testName" className="col-form-label">
                  Reason
                </label>
                <input
                  type="text"
                  defaultValue={selectedExpense.e.reason}
                  onChange={handleReason}
                  className="form-control"
                  id="testName"
                  placeholder="reason"
                />
              </div>

              <br />
              <div className="col-10">
                <label htmlFor="testName" className="col-form-label">
                  Amount
                </label>
                <input
                  type="text"
                  defaultValue={selectedExpense.e.amount}
                  onChange={handleAmount}
                  className="form-control"
                  id="testName"
                  placeholder="amount"
                />
              </div>
              <div className="col-2"></div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handlUpdate}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  ) : (
    <div></div>
  );
}
