import React, { useEffect } from "react";
import "./adminStyle.css";
import { getProfit } from "../../../store/reducer/reportReducer";
import { useSelector } from "react-redux";
import { createBrowserHistory } from "history";

const ProfitREport = (props) => {
  useEffect(() => {
    let data = props.location.state;

    getProfit(data.from, data.to);
  }, []);
  const { profit } = useSelector((state) => state.reportReducer);
  const { user } = useSelector((state) => state.loginReducer, []);

 
  if (user.user.user_info.user_type !== "lab-admin") {
    createBrowserHistory().push("/");
    window.location.reload();
  }
  return (
    <div className="content-wrapper topForm bg-white">
      <section className="tableSection">
        <div className="card">
          <div className="card-header bg-info w-100">benefit</div>
          <div className="card-body">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th>..</th>
                  <th>Price</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <h5 className="d-inline">invoices</h5>
                  </td>
                  <td>
                    <span className="badge badge-info   p-3 ">
                      {" "}
                      <h5 className="d-inline"> {profit.invoices}</h5>{" "}
                    </span>
                  </td>
                </tr>

                <tr>
                  <td>
                    <h5 className="d-inline">expenses</h5>
                  </td>
                  <td>
                    <span className="badge badge-info   p-3 ">
                      {" "}
                      <h5 className="d-inline"> {profit.expenses}</h5>{" "}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <h5 className="d-inline">purchased</h5>
                  </td>
                  <td>
                    <span className="badge badge-info   p-3 ">
                      {" "}
                      <h5 className="d-inline"> {profit.purchased}</h5>{" "}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <h5 className="d-inline">paybacks</h5>
                  </td>
                  <td>
                    <span className="badge badge-info   p-3 ">
                      {" "}
                      <h5 className="d-inline"> {profit.paybacks}</h5>{" "}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ProfitREport;
