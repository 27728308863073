import React, { useState } from "react";
import "./accountStyle.css";

import { login } from "../../../store/reducer/loginReducer";

export default function LoginForm() {
  const handleSubmit = (evt) => {
    evt.preventDefault();

    login(email, password);
  };
  const [email, setEmail] = useState();
  const [emailError, setEmailError] = useState();

  const [password, setPassword] = useState();
  const [passwordError, setPasswordError] = useState();

  function handleEmail(e) {
    setEmail(e.target.value);
  }

  function handlePassword(e) {
    setPassword(e.target.value);
  }

  return (
    <div className="loginMain">
      <form
        className="text-center border border-light p-5"
        onSubmit={handleSubmit}
      >
        <p className="h4 mb-4">Sign in</p>
        <input
          className={"form-control mb-4"}
          placeholder="Enter email"
          id="defaultLoginFormEmail"
          // value={state.email}
          onChange={handleEmail}
          // onBlur={handleBlur("email")}
        />
        <div style={{ fontSize: 12, color: "red" }}>{emailError}</div>
        <input
          className={"form-control mb-4"}
          id="defaultLoginFormPassword"
          type="password"
          placeholder="Enter password"
          // value={state.password}
          onChange={handlePassword}
          // onBlur={handleBlur("password")}
        />
        <div style={{ fontSize: 12, color: "red" }}>{passwordError}</div>
        <button className="btn btn-info btn-block my-4">Sign in</button>{" "}
      </form>
    </div>
  );
}
