import React, { useEffect, useState } from "react";
import "./adminStyle.css";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  addSuppler,
  getSupplers,
  upDateSuppler,
} from "../../../store/reducer/supplerReducer";
import {
  CLOSE_SUPPLER_ALERT,
  OPEN_SUPPLER_ALERT,
} from "../../../store/actions/action_types";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

export default function Supplier() {
  const dispatch = useDispatch();
  const {
    suppliers,
    isSupplierLoaded,
    supplerAlertOpen,
    selectedSuppler,
  } = useSelector((state) => state.supplerReducer);

  useEffect(() => {
    // if (!suppliers || suppliers == "" || suppliers.length == 0) {
      getSupplers();
    // }
  }, []);
  let index = 1;

  const handleClick = (e) => {
    dispatch({
      type: OPEN_SUPPLER_ALERT,
      payload: e,
    });
  };

  const onNameChange = (evt) => {
    setName(evt.target.value);
    if (evt.target.value.length < 3) {
      setNameError("invalid name");
    } else {
      setNameError("");
    }
  };
  const onChangePhone = (evt) => {
    setPhone(evt.target.value);
    if (evt.target.value.length != 11) {
      setPhoneError(" phone number must be 11 digit");
    } else {
      setPhoneError("");
    }
  };
  const onAddressChange = (evt) => {
    setAddress(evt.target.value);
    if (evt.target.value.length < 3) {
      setAddressError("invalid address");
    } else {
      setAddressError("");
    }
  };
  const history = useHistory();
  const handleSubmitDec = (evt) => {
    evt.preventDefault();
    if (name.length >= 3 && phone.length == 11 && address.length >= 3) {
      addSuppler(name, phone, address);
    } else {
      if (name.length < 3) {
        setNameError("invalid name");
      }
      if (phone.length != 11) {
        setPhoneError(" phone number must be 11 digit");
      }
      if (address.length < 3) {
        setAddressError("invalid address");
      }
    }
  };
  const handleClickRoute = (evt) => {
    history.push("/eachSupplier", evt);
  };

  const listItems = suppliers.map((d) => (
    <tr key={index}>
      <td>{index++}</td>
      <td>{d.name}</td>
      <td>
        <button
          type="button"
          onClick={(e) => {
            handleClickRoute(d);
          }}
          className="btn btn-outline-info"
        >
          {d.debit}
        </button>
      </td>
      <td>{d.phone}</td>
      <td>{d.address}</td>

      <td>
        <button
          type="button"
          className="btn btn-outline-info px-3 "
          data-toggle="modal"
          onClick={(e) => {
            handleClick(d);
          }}
          data-target="#basicExampleModal"
        >
          <i className="far fa-edit" aria-hidden="true"></i>
        </button>
      </td>
    </tr>
  ));
  let [name, setName] = useState("");
  let [phone, setPhone] = useState("");
  let [address, setAddress] = useState("");
  let [nameError, setNameError] = useState();
  let [phoneError, setPhoneError] = useState();
  let [addressError, setAddressError] = useState();

  return (
    <div className="content-wrapper topSpace bg-white">
      <section className="tableSection">
        <div className="row">
          <div className="col-lg-4 col-md-4 col-sm-12">
            <form
              className="form"
              onSubmit={handleSubmitDec}
              id="supplerAddForm"
            >
              <div className="form-group">
                <label htmlFor="inputName" className="col-form-label">
                  Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  onChange={onNameChange}
                  id="inputName"
                  placeholder="Name "
                />
              </div>
              <div style={{ fontSize: 12, color: "red" }}>{nameError}</div>
              <div className="form-group">
                <label htmlFor="inputPhone" className="col-form-label">
                  Phone
                </label>
                <input
                  type="text"
                  className="form-control"
                  onChange={onChangePhone}
                  id="inputPhone"
                  placeholder="Phone"
                />
                <div style={{ fontSize: 12, color: "red" }}>{phoneError}</div>
              </div>

              <div className="form-group">
                <label htmlFor="inputAddress" className=" col-form-label">
                  Address
                </label>
                <textarea
                  type="textArea"
                  className="form-control"
                  id="inputAddress"
                  onChange={onAddressChange}
                  placeholder="Address"
                  rows="4"
                />
                <div style={{ fontSize: 12, color: "red" }}>{addressError}</div>
              </div>
              <div className="form-group">
                <button type="submit" className="btn btn-primary w-100">
                  <i className="fas fa-plus"></i> Add
                </button>
              </div>
            </form>
          </div>
          <div className="col-lg-8 col-md-8 col-sm-12">
            <table className="table table-bordered mt-3">
              <thead className="rounded-top">
                <tr className="bg-gradient-cyan light rounded-top">
                  <th>#</th>
                  <th>name</th>
                  <th>Current Debt</th>
                  <th>phone</th>
                  <th>Address</th>
                  <th>Edit</th>
                </tr>
              </thead>

              <tbody>{listItems}</tbody>
            </table>
          </div>
        </div>
      </section>

      <Example show={supplerAlertOpen} />
    </div>
  );
}

function Example(props) {
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch({
      type: CLOSE_SUPPLER_ALERT,
    });
  };
  const handleSaveChanges = () => {
    dispatch({
      type: CLOSE_SUPPLER_ALERT,
    });
    saveUpdates();
  };

  const { selectedSuppler } = useSelector((state) => state.supplerReducer);
  let name = selectedSuppler?.name;
  let phone = selectedSuppler?.phone;
  let address = selectedSuppler?.address;

  let onNameChange = (evt) => {
    name = evt.target.value;
  };
  const onChangePhone = (evt) => {
    phone = evt.target.value;
  };
  const onAddressChange = (evt) => {
    address = evt.target.value;
  };
  const saveUpdates = () => {
    upDateSuppler(name, phone, address, selectedSuppler?.id);
  };

  return props.show == true ? (
    <>
      {" "}
      <Modal show={props.show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Suppler Information </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="form">
            <div className="form-group">
              <label htmlFor="inputName" className="col-form-label">
                Name
              </label>
              <input
                type="text"
                className="form-control"
                id="inputName"
                onChange={onNameChange}
                defaultValue={name}
                placeholder="Name "
              />
            </div>
            <div className="form-group">
              <label htmlFor="inputPhone" className="col-form-label">
                Phone
              </label>
              <input
                type="text"
                className="form-control"
                id="inputPhone"
                defaultValue={phone}
                onChange={onChangePhone}
                placeholder="Phone"
              />
            </div>

            <div className="form-group">
              <label htmlFor="inputAddress" className=" col-form-label">
                Address
              </label>
              <textarea
                type="textArea"
                className="form-control"
                id="inputAddress"
                onChange={onAddressChange}
                defaultValue={address}
                placeholder="Address"
                rows="4"
              />
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSaveChanges}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  ) : (
    <div></div>
  );
}
