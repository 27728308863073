export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const GET_CURRENT_USER = "LOAD_CATS_SUCCESS";
export const USER_LOGIN = "LOAD_CATS_SUCCESS";

export const LOG_IN_SUCCESS = "LOG_IN_SUCCESS";
export const LOG_OUT = "LOG_OUT";
export const CHECK_LOGIN_STATE = "CHECK_LOGIN_STATE";

export const ADD_DOCTOR = "ADD_DOCTOR";
export const DELETE_DOCTOR = "DELETE_DOCTOR";
export const GET_DOCTOR = "GET_DOCTOR";
export const EDIT_DOCTOR = "EDIT_DOCTOR";
export const OPEN_DOCTOR_ALERT = "OPEN_DOCTOR_ALERT";
export const CLOSE_DOCTOR_ALERT = "CLOSE_DOCTOR_ALERT";

//EXPENSE
export const ADD_EXPENSE = "ADD_EXPENSE";
export const GET_EXPENSES = "GET_EXPENSES";
export const EDIT_EXPENSES = "EDIT_EXPENSES";
 export const OPEN_EXPENSE_ALERT = "OPEN_EXPENSE_ALERT";
export const CLOSE_EXPENSE_ALERT = "CLOSE_EXPENSE_ALERT";


//TEST_GATOGORY
export const ADD_TEST_GATAGORY = "ADD_TEST_GATAGORY";
export const GET_TEST_GATOGORY = "GET_TEST_GATOGORY";
export const EDIT_TEST_GATOGORY = "EDIT_TEST_GATOGORY";
 export const OPEN_TEST_GATAGORY_ALERT = "OPEN_TEST_GATAGORY_ALERT";
export const CLOSE_TEST_GATOGORY_ALERT = "CLOSE_TEST_GATOGORY_ALERT";
export const FORCE_REFRSH = "FORCE_REFRSH";


//TEST_GATOGORY
export const ADD_TEST = "ADD_TEST";
export const GET_TESTS = "GET_TESTS";
export const EDIT_TEST = "EDIT_TEST";
export const OPEN_TEST_ALERT = "OPEN_TEST_ALERT";
export const CLOSE_TEST_ALERT = "CLOSE_TEST_ALERT";
export const FILTER_TEST = "FILTER_TEST";
export const SEARCH_TEST = "SEARCH_TEST";
export const DELETE_TEST = "DELETE_TEST";
export const DELETE_TEST_CATEGORY = "DELETE_TEST_CATEGORY";


// add_dd ofgh
// sgds

/// suppler 
export const ADD_SUPPLER = "ADD_SUPPLER";
export const GET_SUPPLERS = "GET_SUPPLERS";
export const EDIT_SUPPLER = "EDIT_SUPPLER";
export const OPEN_SUPPLER_ALERT = "OPEN_SUPPLER_ALERT";
export const CLOSE_SUPPLER_ALERT = "CLOSE_SUPPLER_ALERT";
export const FILTER_SUPPLER = "FILTER_SUPPLER";
export const SEARCH_SUPPLER = "SEARCH_SUPPLER";
export const DELETE_SUPPLER = "DELETE_SUPPLER";
export const ADD_PURCHASE = "ADD_PURCHASE";
export const DELETE_PURCHASE = "DELETE_PURCHASE";
export const DELETE_PAYBACK = "DELETE_PAYBACK";
export const ADD_PAYBACK = "ADD_PAYBACK";
export const EDIT_PURCHASE = "EDIT_PURCHASE";
export const EDIT_PAYBACK = "EDIT_PAYBACK";
export const GET_PAYBACKS = "GET_PAYBACKS";
export const GET_PURCHASE = "GET_PURCHASE";


export const OPEN_PURCHASE_ALERT= "OPEN_PURCHASE_ALERT";
export const OPEN_PAYBACK_ALERT = "OPEN_PAYBACK_ALERT";
export const CLOSE_PURCHASE_ALERT= "CLOSE_PURCHASE_ALERT";
export const CLOSE_PAYBACK_ALERT = "CLOSE_PAYBACK_ALERT";


/// suppler 
export const ADD_USER = "ADD_USER";
export const GET_USERS = "GET_USERS";
export const EDIT_USER = "EDIT_USER";
export const OPEN_USER_ALERT = "OPEN_USER_ALERT";
export const CLOSE_USER_ALERT = "CLOSE_USER_ALERT";
export const FILTER_USER = "FILTER_USER";
export const SEARCH_USER = "SEARCH_USER";
export const DELETE_USER = "DELETE_USER";
/// Patient
export const ADD_PATIENT  = "ADD_PATIENT";
export const GET_PATIENT = "GET_PATIENT";
export const ADD_TEST_PATIENT = "ADD_TEST_PATIENT";
export const EDIT_TEST_PATIENT = "EDIT_TEST_PATIENT";
export const DELETE_TEST_PATIENT = "DELETE_TEST_PATIENT";
export const EDIT_DISCOUNT_PRECENTAGE = "EDIT_DISCOUNT_PRECENTAGE";
export const EDIT_DISCOUNT = "EDIT_DISCOUNT";

export const SELECTED_PATIENT = "SELECTED_PATIENT";
export const REPORT_BY_TEST = "REPORT_BY_TEST";
export const REPORT_BY_DOCTOR = "REPORT_BY_DOCTOR";

export const RESET_FORM = "RESET_FORM";


//invoices
export const GET_INVOICES = "GET_INVOICES";
export const ADD_INVOICES = "ADD_INVOICES";
export const EDIT_INVOICES = "EDIT_INVOICES";
export const DELETE_INVOICES = "DELETE_INVOICES";
export const SET_SELECTED_INVOICES = "SET_SELECTED_INVOICES";
export const OPEN_ALERT_INVOICES = "OPEN_ALERT_INVOICES";
export const CLOSE_ALERT_INVOICES = "CLOSE_ALERT_INVOICES";
export const INC_PAGE = "INC_PAGE";
export const DEC_PAGE = "DEC_PAGE";
export const SET_PAGE = "SET_PAGE";
export const SET_NODATA_FOUND = "SET_NODATA_FOUND";
export const PAGINATE_INVOICES = "PAGINATE_INVOICES";
export const FILTER_INVOICES_DOCTOR_NAME = "FILTER_INVOICES_DOCTOR_NAME";
export const FILTER_INVOICES_TEST_NAME = "FILTER_INVOICES_TEST_NAME";
export const FILTER_INVOICES_PATIENT_NAME = "FILTER_INVOICES_PATIENT_NAME";
export const FILTER_INVOICES_INVOICE_ID = "FILTER_INVOICES_INVOICE_ID";
export const FILTER_INVOICES_TEXT_NAME = "FILTER_INVOICES_TEXT_NAME";

export const FILTER_INVOICES_PATIENT_ID = "FILTER_INVOICES_PATIENT_ID";
export const REMOVE_FILTER = "REMOVE_FILTER";
export const FILTER_INVOICES_ID_DATE = "FILTER_INVOICES_ID_DATE";

export const FILTER_INVOICES_DOCTOR_ID_DATE = "FILTER_INVOICES_DOCTOR_ID_DATE";

//report
export const GET_REPORT = "GET_REPORT";export const GET_PROFIT = "GET_PROFIT";

export const DELETE_REPORT = "DELETE_REPORT";
