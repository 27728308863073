import React, { useState } from "react";
import "./accountStyle.css";
import { Link } from "react-router-dom";
import { addUser } from "../../../store/reducer/usersReducer";
import { useSelector } from "react-redux";
import { createBrowserHistory } from "history";

export default function Register() {
  const [fullname, setFullname] = useState("");
  const [phone, setPhone] = useState("");
  const [pass, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [username, setUserName] = useState("");
  let selectedRole = "lab-staff";
  const [fullnameError, setFullNameErro] = useState("");
  const [phoneError, setPhoneError] = useState("");

  const [passError, setpassError] = useState("");

  const [confirmPassError, setConfirmPassError] = useState("");

  const [usernameError, setUserNameError] = useState("");

  const handSubmit = (evt) => {
    evt.preventDefault();
    if (
      fullnameError == "" &&
      passError == "" &&
      confirmPassError == "" &&
      phoneError == "" &&
      usernameError == ""
    ) {
      //fullname , phone , username , password , role
      addUser(fullname, phone, username, pass, selectedRole);
    }
  };
  const handleUsernameChanged = (evt) => {
    setUserName(evt.target.value);
    if (evt.target.value.length > 6) {
      setUserNameError("");
    } else {
      setUserNameError("username must be at least 6 character");
    }
  };
  const handleRoleChanged = (evt) => {
    selectedRole = evt.target.value;
  };
  const handleFullname = (evt) => {
    setFullname(evt.target.value);
    if (evt.target.value.length > 6) {
      setFullNameErro("");
    } else {
      setFullNameErro("full name must be at least 6 letters");
    }
  };
  const handlePhone = (evt) => {
    setPhone(evt.target.value);
    if (evt.target.value.length == 11) {
      setPhoneError("");
    } else {
      setPhoneError("phone number must be 11 digit");
    }
  };

  const handlePassword = (evt) => {
    setPassword(evt.target.value);
    if (evt.target.value.length > 7) {
      setpassError("");
    } else {
      setpassError("password must be at least 8 character");
    }
    if (pass !== confirmPassword) {
      setConfirmPassError("confirmed password does not match the password");
    } else {
      setConfirmPassError("");
    }
  };
  const { user } = useSelector((state) => state.loginReducer, []);
  if (user.user.user_info.user_type !== "lab-admin") {
    createBrowserHistory().push("/");
    window.location.reload();
  }
  const handleConfirmPassword = (evt) => {
    setConfirmPassword(evt.target.value);
    if (evt.target.value.length < 7) {
      setConfirmPassError("confirmed password must be at least 8 character");
    } else if (pass !== evt.target.value) {
      setConfirmPassError("confirmed password does not match the password");
    } else {
      setConfirmPassError("");
    }
  };
  return (
    <div className="content-wrapper center topSpace">
      <div className="register-box">
        <div className="card card-outline card-info">
          <div className="card-header text-center">
            <Link to="/" className="h1">
              <b>Medical</b>.IT
            </Link>
          </div>
          <div className="card-body">
            <p className="login-box-msg">Create a new account </p>
            <form
              onSubmit={(e) => {
                handSubmit(e);
              }}
              id="createUserFormId"
            >
              <div className="input-group mb-3">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Full name"
                  onChange={handleFullname}
                />

                <div className="input-group-append">
                  <div className="input-group-text">
                    <span className="fas fa-user" />
                  </div>
                </div>
              </div>
              <div style={{ fontSize: 12, color: "red" }}>{fullnameError}</div>
              <div className="input-group mb-3">
                <input
                  type="text"
                  className="form-control"
                  placeholder="username"
                  onChange={handleUsernameChanged}
                />

                <div className="input-group-append">
                  <div className="input-group-text">
                    <span className="fas fa-envelope" />
                  </div>
                </div>
              </div>
              <div style={{ fontSize: 12, color: "red" }}>{usernameError}</div>
              <div className="input-group mb-3">
                <input
                  type="text"
                  className="form-control"
                  placeholder="phone"
                  onChange={handlePhone}
                />

                <div className="input-group-append">
                  <div className="input-group-text">
                    <span className="fas" />
                  </div>
                </div>
              </div>
              <div style={{ fontSize: 12, color: "red" }}>{phoneError}</div>
              <div className="input-group mb-3">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Password"
                  onChange={handlePassword}
                />

                <div className="input-group-append">
                  <div className="input-group-text">
                    <span className="fas fa-lock" />
                  </div>
                </div>
              </div>
              <div style={{ fontSize: 12, color: "red" }}>{passError}</div>
              <div className="input-group mb-3">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Retype password"
                  onChange={handleConfirmPassword}
                />

                <div className="input-group-append">
                  <div className="input-group-text">
                    <span className="fas fa-lock" />
                  </div>
                </div>
              </div>

              <div style={{ fontSize: 12, color: "red" }}>
                {confirmPassError}
              </div>

              <div className="form-group row">
                <label
                  htmlFor="inputGender"
                  className="col-sm-4 col-form-label"
                >
                  User Roles
                </label>
                <div className="col-sm-10">
                  <select
                    defaultValue={selectedRole}
                    className="form-control col-sm-5"
                    id="inputGender"
                    onChange={handleRoleChanged}
                  >
                    <option value="lab-staff">Staff</option>
                    <option value="lab-admin">Admin</option>
                  </select>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <button type="submit" className="btn btn-info btn-block">
                    Create
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
