import { combineReducers } from "redux";

import loginReducer from "../store/reducer/loginReducer";
import doctorsReducer from "../store/reducer/doctorsReducer";
import expensesReducer from "./reducer/expensesReducer";
import catagoryReducer from "./reducer/testGatagoryReducer";
import testReducer from "./reducer/testReducer";
import supplerReducer from "./reducer/supplerReducer";
import userReducer from "./reducer/usersReducer";
import purchasePayBackRudycer from "./reducer/purchaseReducer";
import patientReducer from "./reducer/PatientReducer";
import invoicesReducer from "./reducer/invoicesReducer";
import reportReducer from "./reducer/reportReducer";

const rootReducer = combineReducers({
  loginReducer: loginReducer,
  doctorsReducer: doctorsReducer,
  expensesReducer: expensesReducer,
  catagoryReducer: catagoryReducer,
  testReducer: testReducer,
  supplerReducer: supplerReducer,
  userReducer: userReducer,
  purchasePayBackRudycer: purchasePayBackRudycer,
  patientReducer: patientReducer,
  invoicesReducer: invoicesReducer,
  reportReducer: reportReducer,
});

export default rootReducer;
