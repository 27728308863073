import * as types from "../actions/action_types";
import api from "../api";
import store from "../store";
import { getToken, logout } from "./loginReducer";
import swal from "sweetalert";

export default function invoicesReducer(
  state = {
    invoices: [],

    currentPage: 0,
    totalPages: 0,
    isLoaded: false,
    alertOpen: false,
    selectedInvoices: null,
  },
  action
) {
   switch (action.type) {
    case types.GET_INVOICES:
      const data = action.payload;
      //
      return {
        ...state,
        isLoaded: true,
        invoices: data.invoices,
        totalPages: data.totalPages,
        alertOpen: false,
        selectedInvoices: null,
      };

    case types.ADD_INVOICES: {
      return {
        ...state,
        invoices: [{ ...action.payload }, ...state.invoices],
        totalPages: 1 + state.totalPages,
        isLoaded: true,
        alertOpen: false,
        selectedInvoices: null,
      };
    }

    case types.DELETE_INVOICES: {
      const newList = state.invoices.filter(
        (item, index) => item.id !== action.payload.id
      );

      return {
        ...state,
        isLoaded: true,
        invoices: [...newList],
        alertOpen: false,
        totalPages: state.totalPages - 1,
        selectedPatient: null,
      };
    }
    case types.SET_SELECTED_INVOICES: {
      return {
        ...state,
        isLoaded: true,
        alertOpen: false,
        selectedPatient: action.payload,
      };
    }

    case types.SET_NODATA_FOUND: {
      return {
        ...state,
        invoices: [],
        isLoaded: false,
        alertOpen: false,
        totalPages: 0,
      };
    }

    case types.OPEN_ALERT_INVOICES: {
      return {
        ...state,
        selectedInvoices: action.payload,
        alertOpen: true,
      };
    }

    case types.CLOSE_ALERT_INVOICES: {
      return {
        ...state,
        selectedInvoices: null,
        alertOpen: false,
      };
    }

    case types.EDIT_INVOICES: {
      state.invoices.forEach((e, index) => {
        if (e.id == action.payload.id) {
          state.invoices[index] = action.payload;
        }
      });

      return {
        ...state,
        invoices: [...state.invoices],
        isLoaded: true,
        alertOpen: false,
        selectedInvoices: null,
      };
    }

    default:
      return state;
  }
}

export async function getInvoices(state) {
  const config = {
    headers: { Authorization: `Bearer ${getToken()}` },
  };
  try {
    const keys = Object.keys(state);

    let params = "";
    keys.forEach((element) => {
      if (
        state[element] == "" ||
        state[element] == undefined ||
        state[element] == null
      ) {
      } else {
        params =
          params +
          (element == "page" || element == "filter" ? "_" + element : element) +
          "=" +
          state[element] +
          "&";
      }
    });

    params = params.replace(/.$/, "");
    let url = "invoices?_take=5&" + params;

    const response = await api.get(url, config);

    const data = response.data.invoices;
    //
    let newData = [];
    data.forEach((element) => {
      //
      let pat = element;
      let resutls = [];
      let resultWithtest = [];
      let urines = [];
      element.tests.forEach((test, index) => {
        resultWithtest.push({ test: test, ...element.results[index] });
      });

      resultWithtest.sort((a, b) =>
        a.test.id > b.test.id ? 1 : b.test.id > a.test.id ? -1 : 0
      );
      resultWithtest.forEach((test, index) => {
        if (test.test.category.id === 4) {
          urines.push(test);
        } else {
          resutls.push(test);
        }
      });

      urines.sort((a, b) =>
        a.test.order_no > b.test.order_no
          ? 1
          : b.test.order_no > a.test.order_no
          ? -1
          : 0
      );

      pat.tests = [...resutls, ...urines];
      delete pat["results"];

      newData.push(pat);
    });

    store.dispatch({
      type: types.GET_INVOICES,
      payload: {
        invoices: data,
        totalPages: response.data.total,
      },
    });
  } catch (error) {
     //
    swal("Opps !", "can not get invoices", "error");
    //
    if (error?.response?.status == 401) {
      logout();
    }
  }
}

export async function interResult(bodys, id) {
  const config = {
    headers: { Authorization: `Bearer ${getToken()}` },
  };

  try {
    const response = await api.put("invoices/update/" + id, bodys, config);
    const data = response.data;
    let temp = data;
    let resutls = [];
    let urines = [];

    data.tests.forEach((test, index) => {
      //
      if (test.category.id === 4) {
        urines.push({ test: test, ...data.results[index] });
      } else {
        resutls.push({ test: test, ...data.results[index] });
      }
    });
    urines.sort((a, b) =>
      a.test.order_no > b.test.order_no
        ? 1
        : b.test.order_no > a.test.order_no
        ? -1
        : 0
    );
    delete temp["results"];
    temp.tests = [...resutls, ...urines];
    //
    store.dispatch({
      type: types.EDIT_INVOICES,
      payload: temp,
    });

    swal("Good job!", "invoice was updated successfully", "success");
  } catch (error) {
    await swal("Opps!", "Failed to update invoice", "error");

    if (error?.response?.status === 401) {
      logout();
    }
  }
}

export async function upDateInvoice(bodys, id, PatientBody, PatientId) {
  const config = {
    headers: { Authorization: `Bearer ${getToken()}` },
  };

  try {
    const response = await api.put("invoices/update/" + id, bodys, config);
    const data = response.data;
    let temp = data;
    let resutls = [];
    data.tests.forEach((test, index) => {
      //
      resutls.push({ test: test, ...data.results[index] });
    });
    delete temp["results"];
    temp.tests = resutls;
    //
    store.dispatch({
      type: types.EDIT_INVOICES,
      payload: temp,
    });

    updatePatinet(PatientBody, PatientId);
    swal("Good job!", "invoice was updated successfully", "success");
    return;
  } catch (error) {
    //
    await swal("Opps!", "Failed to update invoice", "error");

    if (error?.response?.status === 401) {
      logout();
    }
  }
  return;
}

export async function updatePatinet(bodys, id) {
  const config = {
    headers: { Authorization: `Bearer ${getToken()}` },
  };

  try {
    const response = await api.put("patients/update/" + id, bodys, config);
    //
  } catch (error) {
    //

    if (error?.response?.status === 401) {
      logout();
    }
  }
}

// //id , index , result , price , testId
export async function deleteInvoice(id) {
  const config = {
    headers: {
      Authorization: `Bearer ${getToken()}`,

      "Access-Control-Allow-Origin": "*",
    },
  };
  try {
    const response = await api.delete(
      "invoices/delete/" + id,

      config
    );

    const data = response.data;

    store.dispatch({ type: types.DELETE_INVOICES, payload: { id: id } });
    swal("Good job!", "invoice was deleted successfully", "success");
  } catch (error) {
    if (error?.response?.status == 401) {
      logout();
    }
    await swal("Opps!", "Failed to delete invoice", "error");
  }
}

//
